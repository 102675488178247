import React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from 'react-router-dom'
import AppConfig from '../../config/app.config';

const mapStateToProps = (state) => {
    return {
    };
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
        },
        dispatch
    )
})



export class Podcast extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            resultObject: props.resultObject,
            id: props.id,
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            // block-images
            <div className="position-relative">
                <div className="img-box" id='img-box-podcast'>
                    <Link to={`/podscast-details/${this.state.resultObject.podCastId}`}>
                        <img src={AppConfig.API.MANU_IMAGE + this.state.resultObject.podCastId + "&random=" + Date.now()} className="img-fluid podcast-img" alt="podcast" />
                        <div className='maintenance-phase'>
                            <p className='set-maintenance'>{this.state.resultObject.podCastName}</p>
                        </div>
                    </Link>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Podcast)