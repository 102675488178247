import purchaseSeriesListActionTypes from "./PurchaseSeriesList.types"

const initial_state = {
  seriesList: null,
  loading: false,
  errorMessage: null,
}

const purchaseSeriesListReducer = (state = initial_state, action) => {
  switch (action.type) {
    case purchaseSeriesListActionTypes.API_CALL_SUCCESS:
      return {
        ...state,
        seriesList: action.payload,
        loading: false,
      }
    case purchaseSeriesListActionTypes.API_CALL_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.err,
      }
    default:
      return state
  }
}

export default purchaseSeriesListReducer
