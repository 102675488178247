import { format } from 'date-fns'
//import moment from "moment";
import * as lodash from 'lodash';

/**
 * Console log errors using defined format.
 * @param {string} filename - name of file where log is happening
 * @param {string} functionName - name of function where log is happening 
 * @param {string} error - error to print
 */
export const log = (filename: string, functionName: string, error: string) => {
    console.log(`[ERROR][${filename}][${functionName}] - ${error}`);
}
/**
 * Validates a value for undefined, null and empty strings.
 * @function valCheck
 * @param {string} value - String to check and validate
 * @returns {false|value} Return false if validation fails or the original value if validation passed.
 */
export const valCheck = (value: string) => {
    if (value === undefined || value === null || value === '') {
        return false;
    } else {
        return value;
    }
}
/**
 * Formats a Javascript Date into a given format. Default is MM/dd/yyyy hh:mm:ss
 * @param {date} date - the date to Format
 * @param {string} formatType - the type of formating to return
 */
export const formatDate = (date, formatType = 'MM/dd/yyyy hh:mm:ss') => {
    return format(new Date(date), formatType);
}
/**
 * Check objech null or empty.
 * @param {object} obj - object
 */
export const checkNullOrEmptyObject = (obj) => {
    if (!obj || lodash.isEmpty(obj)) {
        return true;
    }
    return false;
}
/**
 * Calculate and return a time since string from a supplied date
 * @param {ISOString} date - the date to use
 * @returns - time ago string
 */
export const timeSince = (date) => {
    var seconds = Math.floor((new Date() - new Date(date)) / 1000);
    var interval = seconds / 31536000;
    if (interval > 1) {
        return Math.floor(interval) + ` ${interval === 1 ? 'year' : 'years'} ago`;;;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + ` ${interval === 1 ? 'month' : 'months'} ago`;;
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + ` ${interval === 1 ? 'day' : 'days'} ago`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " h";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " m";
    }
    const secondsString = `${Math.floor(seconds)} seconds ago`;
    return `${interval < 2 ? `just now` : secondsString}`;
}
/**
 * Takes seconds and converts it into minutes and seconds in the following format: (minutes:seconds)
 * @param {string|int} time - the time in seconds to convert to minutes:seconds
 */
export const secondsToMinutesSeconds = (time) => {
    const minutes = Math.floor((time % 3600) / 60);
    return `${('0' + minutes).slice(-2)}m`;
}

export const secondsToHourse = (time) => { 

    const sec = parseInt(time, 10); // convert value to number if it's string
    let hours   = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    // add 0 if value < 10; Example: 2 => 02
    if (minutes < 10) {minutes = "0"+minutes;}
    if(hours === 0) {
        return minutes+'m' // Return is HH : MM : SS
    } else {
        return hours+'h '+minutes+'m' // Return is HH : MM : SS
    } 
}


export const updateSongs = (stream, songsToUpdate, callback) => {
    for (let i = 0; i < songsToUpdate.length; i++) {
        const song = songsToUpdate[i];
        const streamSongIndex = this.state.stream.findIndex(x => x.song.songId === song.song.songId);
        if (streamSongIndex !== -1) {
            stream[streamSongIndex] = song;
        }
    }
    callback(null, stream);
}

export async function asyncForEach<T>(array: Array<T>, callback: (item: T, index: number) => void) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index);
    }
}