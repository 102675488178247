import { takeLeading, put, all, call } from "redux-saga/effects";
import HomeActionTypes from "./home.types";
import {
  homeSyncFinish,
  tabListSyncFinish,
  newRealeseSyncFinish,
  classicSyncFinish,
  independentSyncFinish,
  originalSeriesSyncFinish,
  influencerSyncFinish,
  comedySyncFinish,
  musicSyncFinish,
  brandsSyncFinish,
  brandsPartnersSyncFinish,
  exclusiveEventSyncFinish,
  featuredChannelsSyncFinish,
  tvChannelsSyncFinish,
  podcastSyncFinish,
  trendingLivesSyncFinish,
  athletesSyncFinish,
  fineArtistsSyncFinish,
  channelCategorySyncFinish,
  featuredSyncFinish,
} from "./home.actions";
import AppConfig from "../../config/app.config";
import UserService from "../../services/user/user.service";
import { log } from "../../helpers/core.helper";

// export function* getBannerData({ payload }) {
//     try {

//         let initialSynced = false;
//         let syncinghome = false;
//         let bannerList = null;
//         let message = null;
//         const bannerData = yield UserService.getBannerData(payload);
//         if (bannerData.error != null) {
//             initialSynced = false;
//             syncinghome = false;
//             bannerList = null;
//             message = bannerData.error.response.data.message;
//         } else {
//             if (bannerData['status']) {
//                 initialSynced = true;
//                 syncinghome = true;
//                 bannerList = bannerData['data'];
//                 message = bannerData['message'];
//             } else {
//                 initialSynced = false;
//                 syncinghome = false;
//                 bannerList = null;
//                 message = bannerData['message'];
//             }
//         }

//         yield put(homeSyncFinish({ initialSynced, syncinghome, bannerList, message }));

//     } catch (err) {
//         log('home.sagas', 'getBannerData', err);
//         yield put(homeSyncFinish({ initialSynced: false, syncinghome: false, bannerList: null, message: err }));
//     }
// }

export function* getBannerData({ payload }) {
  try {
    let initialSynced = false;
    let syncinghome = false;
    let bannerList = null;
    let message = null;
    const res = yield UserService.getSerriesBannerData(payload);
    if (res.error != null) {
      initialSynced = false;
      syncinghome = false;
      bannerList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        initialSynced = true;
        syncinghome = true;
        bannerList = res["data"];
        message = res["message"];
      } else {
        initialSynced = false;
        syncinghome = false;
        bannerList = null;
        message = res["message"];
      }
    }
    yield put(
      homeSyncFinish({ initialSynced, syncinghome, bannerList, message })
    );
  } catch (err) {
    log("home.sagas", "getBannerData", err);
    yield put(
      homeSyncFinish({
        initialSynced: false,
        syncinghome: false,
        bannerList: null,
        message: err,
      })
    );
  }
}

export function* getChannelCategoryList({ payload }) {
  try {
    let initialSynced = false;
    let channelCategoryList = [];
    let message = null;

    const res = yield UserService.getChannelCategoryList(payload);
    if (res.error != null) {
      initialSynced = false;
      channelCategoryList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        const resultList = res["data"];
        if (resultList != null) {
          for (let i = 0; i < resultList.length; i++) {
            const row = resultList[i];

            let channelCategoryMap = [];
            const payloadData = {
              subscriptionId: payload.subscriptionId,
              verticalId: payload.verticalId,
              channelTypeId: row.channelCategoryId,
            };
            const responce = yield UserService.getInfluencerListByChannelType(
              payloadData
            );
            if (responce.error != null) {
              initialSynced = false;
              channelCategoryList = null;
              message = responce.error.response.data.message;
            } else {
              if (responce["status"]) {
                const result = responce["data"];
                if (result != null) {
                  for (let i = 0; i < result.length; i++) {
                    const rowData = result[i];

                    const objectData = {
                      id: rowData.influencerId,
                      name: rowData.influencerName,
                      webUrl: rowData.influencerWebsiteUrl,
                    };
                    channelCategoryMap.push(objectData);
                  }
                }
              }
            }
            if (channelCategoryMap != null && channelCategoryMap.length > 0) {
              const object = {
                channelCategoryId: row.channelCategoryId,
                channelCategoryName: row.channelCategoryName,
                channelCategoryMap: channelCategoryMap,
              };
              channelCategoryList.push(object);
            }
          }
        }
        initialSynced = true;
        // channelCategoryList = res['data'];
        message = res["message"];
      } else {
        initialSynced = false;
        channelCategoryList = null;
        message = res["message"];
      }
    }
    yield put(
      channelCategorySyncFinish({ initialSynced, channelCategoryList, message })
    );
  } catch (err) {
    log("home.sagas", "getChannelCategoryList", err);
    yield put(
      channelCategorySyncFinish({
        initialSynced: false,
        channelCategoryList: null,
        message: err,
      })
    );
  }
}

export function* getTabList({ payload }) {
  try {
    const payloadData = {
      employeeId: payload.employeeId,
      sphereUrl: AppConfig.API.SPHERE_TYPE_URL,
    };
    let initialSynced = false;
    let tabList = null;
    let message = null;

    const tabData = yield UserService.getTabListData(payloadData);
    if (tabData.error != null) {
      initialSynced = false;
      tabList = null;
      message = tabData.error.response.data.message;
    } else {
      if (tabData["status"]) {
        initialSynced = true;
        tabList = tabData["data"];
        message = tabData["message"];
      } else {
        initialSynced = false;
        tabList = null;
        message = tabData["message"];
      }
    }
    yield put(tabListSyncFinish({ initialSynced, tabList, message }));
  } catch (err) {
    log("home.sagas", "getTabList", err);
    yield put(
      tabListSyncFinish({ initialSynced: false, tabList: null, message: err })
    );
  }
}

export function* getNewRealese({ payload }) {
  try {
    let initialSynced = false;
    let newReleaseList = null;
    let message = null;
    const payloadData = {
      subscriptionId: payload.subscriptionId,
      verticalId: payload.verticalId,
      employeeId: payload.employeeId,
      subTypeId: AppConfig.API.NEW_RELEASE_SUB_TYPE,
      isPurchase: payload.isPurchase !== null ? payload.isPurchase : 0,
    };

    const res = yield UserService.getMoviesListBySubType(payloadData);
    if (res.error != null) {
      initialSynced = false;
      newReleaseList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        initialSynced = true;
        newReleaseList = res["data"];
        message = res["message"];
      } else {
        initialSynced = false;
        newReleaseList = null;
        message = res["message"];
      }
    }

    yield put(newRealeseSyncFinish({ initialSynced, newReleaseList, message }));
  } catch (err) {
    log("home.sagas", "getNewRealese", err);
    yield put(
      newRealeseSyncFinish({
        initialSynced: false,
        newReleaseList: null,
        message: err,
      })
    );
  }
}

export function* getClassic({ payload }) {
  try {
    let initialSynced = false;
    let classicList = null;
    let message = null;
    const payloadDate = {
      subscriptionId: payload.subscriptionId,
      employeeId: payload.employeeId,
      verticalId: payload.verticalId,
      subTypeId: AppConfig.API.CLASSIC_SUB_TYPE,
      isPurchase: payload.isPurchase !== null ? payload.isPurchase : 0,
    };

    const res = yield UserService.getMoviesListBySubType(payloadDate);
    if (res.error != null) {
      initialSynced = false;
      classicList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        initialSynced = true;
        classicList = res["data"];
        message = res["message"];
      } else {
        initialSynced = false;
        classicList = null;
        message = res["message"];
      }
    }

    yield put(classicSyncFinish({ initialSynced, classicList, message }));
  } catch (err) {
    log("home.sagas", "getClassic", err);
    yield put(
      classicSyncFinish({
        initialSynced: false,
        classicList: null,
        message: err,
      })
    );
  }
}

export function* getIndependent({ payload }) {
  try {
    let initialSynced = false;
    let independentList = null;
    let message = null;
    const payloadDate = {
      subscriptionId: payload.subscriptionId,
      employeeId: payload.employeeId,
      verticalId: payload.verticalId,
      subTypeId: AppConfig.API.INDEPENDENT_SUB_TYPE,
      isPurchase: payload.isPurchase !== null ? payload.isPurchase : 0,
    };

    const res = yield UserService.getMoviesListBySubType(payloadDate);
    if (res.error != null) {
      initialSynced = false;
      independentList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        initialSynced = true;
        independentList = res["data"];
        message = res["message"];
      } else {
        initialSynced = false;
        independentList = null;
        message = res["message"];
      }
    }

    yield put(
      independentSyncFinish({ initialSynced, independentList, message })
    );
  } catch (err) {
    log("home.sagas", "getIndependent", err);
    yield put(
      independentSyncFinish({
        initialSynced: false,
        independentList: null,
        message: err,
      })
    );
  }
}

export function* getOriginalSeries({ payload }) {
  try {
    let initialSynced = false;
    let originalSeriesList = null;
    let message = null;

    const res = yield UserService.getOriginalSerries(payload);
    if (res.error != null) {
      initialSynced = false;
      originalSeriesList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        initialSynced = true;
        originalSeriesList = res["data"];
        message = res["message"];
      } else {
        initialSynced = false;
        originalSeriesList = null;
        message = res["message"];
      }
    }

    yield put(
      originalSeriesSyncFinish({ initialSynced, originalSeriesList, message })
    );
  } catch (err) {
    log("home.sagas", "getOriginalSeries", err);
    yield put(
      originalSeriesSyncFinish({
        initialSynced: false,
        originalSeriesList: null,
        message: err,
      })
    );
  }
}

export function* getInfluencerList({ payload }) {
  try {
    let initialSynced = false;
    let influencerList = [];
    let message = null;
    const payloadDate = {
      subscriptionId: payload.subscriptionId,
      verticalId: payload.verticalId,
      channelTypeId: AppConfig.API.INFLUENCER_CHANNEL_TYPE,
    };

    const res = yield UserService.getInfluencerListByChannelType(payloadDate);
    if (res.error != null) {
      initialSynced = false;
      influencerList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        const resultList = res["data"];
        if (resultList != null) {
          for (let i = 0; i < resultList.length; i++) {
            const row = resultList[i];
            const object = {
              id: row.influencerId,
              name: row.influencerName,
              webUrl: row.influencerWebsiteUrl,
            };
            influencerList.push(object);
          }
        }

        initialSynced = true;
        // influencerList = res['data'];
        message = res["message"];
      } else {
        initialSynced = false;
        influencerList = null;
        message = res["message"];
      }
    }

    yield put(influencerSyncFinish({ initialSynced, influencerList, message }));
  } catch (err) {
    log("home.sagas", "getInfluencerList", err);
    yield put(
      influencerSyncFinish({
        initialSynced: false,
        influencerList: null,
        message: err,
      })
    );
  }
}

export function* getComedyList({ payload }) {
  try {
    let initialSynced = false;
    let comedyList = [];
    let message = null;
    const payloadDate = {
      subscriptionId: payload.subscriptionId,
      verticalId: payload.verticalId,
      channelTypeId: AppConfig.API.COMEDY_CHANNEL_TYPE,
    };

    const res = yield UserService.getInfluencerListByChannelType(payloadDate);
    if (res.error != null) {
      initialSynced = false;
      comedyList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        const resultList = res["data"];
        if (resultList != null) {
          for (let i = 0; i < resultList.length; i++) {
            const row = resultList[i];
            const object = {
              id: row.influencerId,
              name: row.influencerName,
              webUrl: row.influencerWebsiteUrl,
            };
            comedyList.push(object);
          }
        }

        initialSynced = true;
        // comedyList = res['data'];
        message = res["message"];
      } else {
        initialSynced = false;
        comedyList = null;
        message = res["message"];
      }
    }

    yield put(comedySyncFinish({ initialSynced, comedyList, message }));
  } catch (err) {
    log("home.sagas", "getComedyList", err);
    yield put(
      comedySyncFinish({ initialSynced: false, comedyList: null, message: err })
    );
  }
}

export function* getMusicList({ payload }) {
  try {
    let initialSynced = false;
    let musicList = [];
    let message = null;
    const payloadDate = {
      subscriptionId: payload.subscriptionId,
      verticalId: payload.verticalId,
      channelTypeId: AppConfig.API.MUSIC_CHANNEL_TYPE,
    };

    const res = yield UserService.getInfluencerListByChannelType(payloadDate);
    if (res.error != null) {
      initialSynced = false;
      musicList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        const resultList = res["data"];
        if (resultList != null) {
          for (let i = 0; i < resultList.length; i++) {
            const row = resultList[i];
            const object = {
              id: row.influencerId,
              name: row.influencerName,
              webUrl: row.influencerWebsiteUrl,
            };
            musicList.push(object);
          }
        }

        initialSynced = true;
        // musicList = res['data'];
        message = res["message"];
      } else {
        initialSynced = false;
        musicList = null;
        message = res["message"];
      }
    }

    yield put(musicSyncFinish({ initialSynced, musicList, message }));
  } catch (err) {
    log("home.sagas", "getMusicList", err);
    yield put(
      musicSyncFinish({ initialSynced: false, musicList: null, message: err })
    );
  }
}

export function* getBrandsList({ payload }) {
  try {
    let initialSynced = false;
    let brandsList = [];
    let message = null;

    const res = yield UserService.getBrandsList(payload);
    if (res.error != null) {
      initialSynced = false;
      brandsList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        const resultList = res["data"];
        if (resultList != null) {
          for (let i = 0; i < resultList.length; i++) {
            const row = resultList[i];
            const object = {
              id: row.brandId,
              name: row.brandName,
              webUrl: row.brandWebsiteUrl,
            };
            brandsList.push(object);
          }
        }
        initialSynced = true;
        //  brandsList = res['data'];
        message = res["message"];
      } else {
        initialSynced = false;
        brandsList = null;
        message = res["message"];
      }
    }

    yield put(brandsSyncFinish({ initialSynced, brandsList, message }));
  } catch (err) {
    log("home.sagas", "getBrandsList", err);
    yield put(
      brandsSyncFinish({ initialSynced: false, brandsList: null, message: err })
    );
  }
}

export function* getBrandsPartnersList({ payload }) {
  try {
    let initialSynced = false;
    let brandsPartnersList = [];
    let message = null;

    const res = yield UserService.getBrandsPartnersList(payload);
    if (res.error != null) {
      initialSynced = false;
      brandsPartnersList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        initialSynced = true;
        brandsPartnersList = res["data"];
        message = res["message"];
      } else {
        initialSynced = false;
        brandsPartnersList = null;
        message = res["message"];
      }
    }

    yield put(
      brandsPartnersSyncFinish({ initialSynced, brandsPartnersList, message })
    );
  } catch (err) {
    log("home.sagas", "getBrandsPartnersList", err);
    yield put(
      brandsPartnersSyncFinish({
        initialSynced: false,
        brandsPartnersList: null,
        message: err,
      })
    );
  }
}

export function* getExclusiveEventList({ payload }) {
  try {
    let initialSynced = false;
    let exclusiveeventList = [];
    let message = null;

    const res = yield UserService.getExclusiveEventList(payload);
    if (res.error != null) {
      initialSynced = false;
      exclusiveeventList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        initialSynced = true;
        exclusiveeventList = res["data"];
        message = res["message"];
      } else {
        initialSynced = false;
        exclusiveeventList = null;
        message = res["message"];
      }
    }
    yield put(
      exclusiveEventSyncFinish({ initialSynced, exclusiveeventList, message })
    );
  } catch (err) {
    log("home.sagas", "getExclusiveEventList", err);
    yield put(
      exclusiveEventSyncFinish({
        initialSynced: false,
        exclusiveeventList: null,
        message: err,
      })
    );
  }
}

export function* getFeaturedChannelsList({ payload }) {
  try {
    let initialSynced = false;
    let featuredChannelsList = [];
    let message = null;

    const res = yield UserService.getFeaturedChannelsList(payload);
    if (res.error != null) {
      initialSynced = false;
      featuredChannelsList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        initialSynced = true;
        featuredChannelsList = res["data"];
        message = res["message"];
      } else {
        initialSynced = false;
        featuredChannelsList = null;
        message = res["message"];
      }
    }
    yield put(
      featuredChannelsSyncFinish({
        initialSynced,
        featuredChannelsList,
        message,
      })
    );
  } catch (err) {
    log("home.sagas", "getFeaturedChannelsList", err);
    yield put(
      featuredChannelsSyncFinish({
        initialSynced: false,
        featuredChannelsList: null,
        message: err,
      })
    );
  }
}

export function* getTvChannelsList({ payload }) {
  try {
    let initialSynced = false;
    let tvChannelsList = [];
    let message = null;

    const res = yield UserService.getTvChannelsList(payload);
    if (res.error != null) {
      initialSynced = false;
      tvChannelsList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        const resultList = res["data"];
        if (resultList != null) {
          for (let i = 0; i < resultList.length; i++) {
            const row = resultList[i];
            const object = {
              id: row.tvChannelId,
              name: row.tvChannelName,
              webUrl: row.url,
            };
            tvChannelsList.push(object);
          }
        }
        initialSynced = true;
        //tvChannelsList = res['data'];
        message = res["message"];
      } else {
        initialSynced = false;
        tvChannelsList = null;
        message = res["message"];
      }
    }
    yield put(tvChannelsSyncFinish({ initialSynced, tvChannelsList, message }));
  } catch (err) {
    log("home.sagas", "getTvChannelsList", err);
    yield put(
      tvChannelsSyncFinish({
        initialSynced: false,
        tvChannelsList: null,
        message: err,
      })
    );
  }
}

export function* getPodcastList({ payload }) {
  try {
    let initialSynced = false;
    let podcastList = [];
    let message = null;

    const res = yield UserService.getPodcastList(payload);
    if (res.error != null) {
      initialSynced = false;
      podcastList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        initialSynced = true;
        podcastList = res["data"];
        message = res["message"];
      } else {
        initialSynced = false;
        podcastList = null;
        message = res["message"];
      }
    }
    yield put(podcastSyncFinish({ initialSynced, podcastList, message }));
  } catch (err) {
    log("home.sagas", "getPodcastList", err);
    yield put(
      podcastSyncFinish({
        initialSynced: false,
        podcastList: null,
        message: err,
      })
    );
  }
}

export function* getTrendingLivesist({ payload }) {
  try {
    let initialSynced = false;
    let trendinglivesList = [];
    let message = null;

    const res = yield UserService.getTrendingLivesist(payload);
    if (res.error != null) {
      initialSynced = false;
      trendinglivesList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        const resultList = res["data"];
        if (resultList != null) {
          for (let i = 0; i < resultList.length; i++) {
            const row = resultList[i];
            const object = {
              id: row.influencerId,
              name: row.influencerName,
              webUrl: row.influencerWebsiteUrl,
            };
            trendinglivesList.push(object);
          }
        }

        initialSynced = true;
        message = res["message"];
      } else {
        initialSynced = false;
        trendinglivesList = null;
        message = res["message"];
      }
    }
    yield put(
      trendingLivesSyncFinish({ initialSynced, trendinglivesList, message })
    );
  } catch (err) {
    log("home.sagas", "getTrendingLivesist", err);
    yield put(
      trendingLivesSyncFinish({
        initialSynced: false,
        trendinglivesList: null,
        message: err,
      })
    );
  }
}

export function* getAthletesList({ payload }) {
  try {
    let initialSynced = false;
    let athletesList = [];
    let message = null;
    const payloadDate = {
      subscriptionId: payload.subscriptionId,
      verticalId: payload.verticalId,
      channelTypeId: AppConfig.API.ATHLETES_CHANNEL_TYPE,
    };
    const res = yield UserService.getInfluencerListByChannelType(payloadDate);
    if (res.error != null) {
      initialSynced = false;
      athletesList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        const resultList = res["data"];
        if (resultList != null) {
          for (let i = 0; i < resultList.length; i++) {
            const row = resultList[i];
            const object = {
              id: row.influencerId,
              name: row.influencerName,
              webUrl: row.influencerWebsiteUrl,
            };
            athletesList.push(object);
          }
        }
        initialSynced = true;
        // influencerList = res['data'];
        message = res["message"];
      } else {
        initialSynced = false;
        athletesList = null;
        message = res["message"];
      }
    }
    yield put(athletesSyncFinish({ initialSynced, athletesList, message }));
  } catch (err) {
    log("home.sagas", "getAthletesList", err);
    yield put(
      athletesSyncFinish({
        initialSynced: false,
        athletesList: null,
        message: err,
      })
    );
  }
}

export function* getFineArtitsList({ payload }) {
  try {
    let initialSynced = false;
    let fineArtistsList = [];
    let message = null;
    const payloadDate = {
      subscriptionId: payload.subscriptionId,
      verticalId: payload.verticalId,
      channelTypeId: AppConfig.API.FINE_ARTISTS_CHANNEL_TYPE,
    };
    const res = yield UserService.getInfluencerListByChannelType(payloadDate);
    if (res.error != null) {
      initialSynced = false;
      fineArtistsList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        const resultList = res["data"];
        if (resultList != null) {
          for (let i = 0; i < resultList.length; i++) {
            const row = resultList[i];
            const object = {
              id: row.influencerId,
              name: row.influencerName,
              webUrl: row.influencerWebsiteUrl,
            };
            fineArtistsList.push(object);
          }
        }
        initialSynced = true;
        // fineArtistsList = res['data'];
        message = res["message"];
      } else {
        initialSynced = false;
        fineArtistsList = null;
        message = res["message"];
      }
    }
    yield put(
      fineArtistsSyncFinish({ initialSynced, fineArtistsList, message })
    );
  } catch (err) {
    log("home.sagas", "getFineArtitsList", err);
    yield put(
      fineArtistsSyncFinish({
        initialSynced: false,
        fineArtistsList: null,
        message: err,
      })
    );
  }
}

export function* getFeatured({ payload }) {
  try {
    let initialSynced = false;
    let featuredList = [];
    let message = null;
    const payloadDate = {
      subscriptionId: payload.subscriptionId,
      verticalId: payload.verticalId,
      isFeatured: 1,
    };
    const res = yield UserService.getInfluencerListByChannelType(payloadDate);
    if (res.error != null) {
      initialSynced = false;
      featuredList = null;
      message = res.error.response.data.message;
    } else {
      if (res["status"]) {
        const resultList = res["data"];
        if (resultList != null) {
          for (let i = 0; i < resultList.length; i++) {
            const row = resultList[i];
            const object = {
              id: row.influencerId,
              name: row.influencerName,
              webUrl: row.influencerWebsiteUrl,
            };
            featuredList.push(object);
          }
        }
        initialSynced = true;
        // influencerList = res['data'];
        message = res["message"];
      } else {
        initialSynced = false;
        featuredList = null;
        message = res["message"];
      }
    }
    yield put(featuredSyncFinish({ initialSynced, featuredList, message }));
  } catch (err) {
    log("home.sagas", "getFeatured", err);
    yield put(
      featuredSyncFinish({
        initialSynced: false,
        featuredList: null,
        message: err,
      })
    );
  }
}

export function* homeSyncStart() {
  yield takeLeading(HomeActionTypes.HOME_SYNC_START, getBannerData);
}

export function* tabListSyncStart() {
  yield takeLeading(HomeActionTypes.TAB_LIST_SYNC_START, getTabList);
}

export function* newRealeseSyncStart() {
  yield takeLeading(HomeActionTypes.NEW_RELEASE_SYNC_START, getNewRealese);
}

export function* classicSyncStart() {
  yield takeLeading(HomeActionTypes.CLASSIC_SYNC_START, getClassic);
}

export function* independentSyncStart() {
  yield takeLeading(HomeActionTypes.INDEPENDENT_SYNC_START, getIndependent);
}

export function* originalSeriesSyncStart() {
  yield takeLeading(
    HomeActionTypes.ORIGINAL_SERIES_SYNC_START,
    getOriginalSeries
  );
}

export function* influencerSyncStart() {
  yield takeLeading(HomeActionTypes.INFLUENCER_SYNC_START, getInfluencerList);
}

export function* comedySyncStart() {
  yield takeLeading(HomeActionTypes.COMEDY_SYNC_START, getComedyList);
}

export function* musicSyncStart() {
  yield takeLeading(HomeActionTypes.MUSIC_SYNC_START, getMusicList);
}

export function* brandsSyncStart() {
  yield takeLeading(HomeActionTypes.BRANDS_SYNC_START, getBrandsList);
}

export function* brandsPartnersSyncStart() {
  yield takeLeading(
    HomeActionTypes.BRANDS_PARTNERS_SYNC_START,
    getBrandsPartnersList
  );
}

export function* exclusiveEventSyncStart() {
  yield takeLeading(
    HomeActionTypes.EXCLUSIVE_EVENT_SYNC_START,
    getExclusiveEventList
  );
}

export function* featuredChannelsSyncStart() {
  yield takeLeading(
    HomeActionTypes.FEATURED_CHANNELS_SYNC_START,
    getFeaturedChannelsList
  );
}

export function* tvChannelsSyncStart() {
  yield takeLeading(HomeActionTypes.TV_CHANNELS_SYNC_START, getTvChannelsList);
}

export function* podcastSyncStart() {
  yield takeLeading(HomeActionTypes.PODCAST_SYNC_START, getPodcastList);
}

export function* trendingLivesSyncStart() {
  yield takeLeading(
    HomeActionTypes.TRENDING_LIVES_SYNC_START,
    getTrendingLivesist
  );
}

export function* athletesSyncStart() {
  yield takeLeading(HomeActionTypes.ATHLETES_SYNC_START, getAthletesList);
}

export function* fineArtistsSyncStart() {
  yield takeLeading(HomeActionTypes.FINE_ARTISTS_SYNC_START, getFineArtitsList);
}

export function* channelCategorySyncStart() {
  yield takeLeading(
    HomeActionTypes.CHANNEL_CATEGORY_SYNC_START,
    getChannelCategoryList
  );
}

export function* featuredSyncStart() {
  yield takeLeading(HomeActionTypes.FEATURED_SYNC_START, getFeatured);
}

/**
 * Initialise all local sagas
 */
export function* homeSagas() {
  yield all([
    call(homeSyncStart),
    call(tabListSyncStart),
    call(newRealeseSyncStart),
    call(classicSyncStart),
    call(independentSyncStart),
    call(originalSeriesSyncStart),
    call(influencerSyncStart),
    call(comedySyncStart),
    call(musicSyncStart),
    call(brandsSyncStart),
    call(brandsPartnersSyncStart),
    call(exclusiveEventSyncStart),
    call(featuredChannelsSyncStart),
    call(tvChannelsSyncStart),
    call(podcastSyncStart),
    call(trendingLivesSyncStart),
    call(athletesSyncStart),
    call(fineArtistsSyncStart),
    call(channelCategorySyncStart),
    call(featuredSyncStart),
  ]);
}
