import SignInActionTypes from "./signIn.types";
// Set the reducers initial state.
const INITIAL_STATE = {
  loading: false,
  loginSuccess: null,
  errorMessage: null,
  employeeData: null,
};

// Define scenarios for reducers.
const signInReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SignInActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loginSuccess: action.payload.loginSuccess,
        loading: action.payload.loading,
        employeeData: action.payload.employeeData,
      };
    case SignInActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        loginSuccess: action.payload.loginSuccess,
        loading: action.payload.loading,
        errorMessage: action.payload.err,
        employeeData: action.payload.employeeData,
      };
    default:
      return state;
  }
};

export default signInReducer;
