const URL = "https://www.myciright.com/Ciright";

const AppConfig = {
  API: {
    ENDPOINT: URL + "/api",
    CA_ENDPOINT: "https://ca-server.ciright.com/api",
    USER_PROFILE:
      URL +
      "/ajaxCall-photo.htm?flag=employeePhoto&compress=0&colorCode=veuit&id=",
    MANU_IMAGE:
      URL + "/ajaxCall-photo.htm?flag=veuitNetworkManufactLogo&compress=0&id=",
    PRODUCT_IMAGE:
      URL + "/ajaxCall-photo.htm?flag=veuitNetworkProductImage&compress=0&id=",
    EPISODE_IMAGE:
      URL + "/ajaxCall-photo.htm?flag=veuitNetworkProductImage&compress=0&id=",
      BASE_PATH: null,
    DOMAIN_NAME: ".veuit.com",
    DOMAIN_URL: "https://veuit.com",
    LOGIN_DOMAIN_URL: "https://login.veuit.com",
    SUBSCRIPTION_ID: 8263180,
    VERTICAL_ID: 2453,
    APP_ID: 2459,
    LOGIN_APP_ID: 2809,
    CHANNEL_APP_ID: 2601,
    SPHERE_TYPE_URL: "veuit-network-main.htm",
    CHANNEL_SPHERE_TYPE_URL: "veuit-influencer-channel.htm",
    SPHERE_TYPE_ID: 2,
    MOTION_PICTURE_TYPE: 3,
    SERIES_TYPE: 5,
    NEW_RELEASE_SUB_TYPE: 5,
    CLASSIC_SUB_TYPE: 4,
    INDEPENDENT_SUB_TYPE: 20,
    ORIGINAL_CONTENT_SERIES_SUB_TYPE: 11,
    ORIGINAL_CONTENT_MOTION_PICTURE_SUB_TYPE: 6,
    COMEDY_CHANNEL_TYPE: 10935,
    MUSIC_CHANNEL_TYPE: 10965,
    INFLUENCER_CHANNEL_TYPE: 10958,
    ATHLETES_CHANNEL_TYPE: 11912,
    FINE_ARTISTS_CHANNEL_TYPE: 11913,
    CA_APP_ID: 2459,
    MANUFACTURE_ID: 19926,
    ABOUT_DECK_URL: "veuit-deck",
    CIRIGHT_WORLD_VERTICAL_ID: 18,
    CIRIGHT_WORLD_SUBSCRIPTION_ID: 9329,
    IMAGES: {
      VISA: "../../assets/images/visa-img.png",
      MASTERCARD: "../../assets/images/Mastercard.png",
      JCB: "../../assets/images/JCB.png",
      DISCOVER: "../../assets/images/Discover.png",
      AMEX: "../../assets/images/Amex.png",
      DINERSCLUB: "../../assets/images/DinersClub.png",
    },
    CHANNEL_ADMIN_DOMAIN_URL:
      "https://channel.veuit.com/admin/u/0/veuit-channel-discover",
    VEUIT_SETTING_DOMAIN_URL:
      "https://settings.veuit.com/admin/u/0/veuit-channel-settings-account",
  },
};

export default AppConfig;
