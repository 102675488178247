import { createSelector } from "reselect"

const selectPurchase = (state) => state.purchase

export const selectPurchaseSeriesListStatus = createSelector(
  [selectPurchase],
  (purchase) => {
    return {
      seriesList: purchase.seriesList,
      loading: purchase.loading,
      error: purchase.errorMessage,
    }
  }
)
