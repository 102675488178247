import { takeLeading, put, all, call } from "redux-saga/effects"
import SignInActionTypes from "./signIn.types"
import { userLoginSuccess, userLoginFailure } from "./signIn.actions"
import AppConfig from "../../config/app.config"
import UserService from "../../services/user/user.service"
import { log } from "../../helpers/core.helper"
import Cookies from "js-cookie"

/** performs user login */
export function* loginUser({ payload }) {
  try {
    const signInData = {
      username: payload.username ?? "",
      password: payload.password ?? "",
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.APP_ID,
      sphereTypeUrl: AppConfig.API.SPHERE_TYPE_URL,
      channelAppId: AppConfig.API.CHANNEL_APP_ID,
      channelSphereTypeUrl: AppConfig.API.CHANNEL_SPHERE_TYPE_URL,
      isAutoLogin: payload.isAutoLogin,
      userToken: payload.userToken,
    }
    const res = yield UserService.signIn(signInData)
    if (res.error != null) {
      yield put(
        userLoginFailure({
          loginSuccess: false,
          loading: false,
          err: res.error.response.data.message,
        })
      )
    } else {
      if (res["status"]) {
        if (res.data.newUserToken !== Cookies.get("userToken")) {
          window.localStorage.clear()
          let cookies = document.cookie.split(";")
          for (var i = 0; i < cookies.length; i++) {
            let cookie = cookies[i]
            let eqPos = cookie.indexOf("=")
            let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
            document.cookie =
              name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/"
          }
          return
        }
        var employeeData = null
        let isLogin = false
        if (payload.isChannelLogin === 1) {
          const networkRoles = res["data"].networkRoles
          if (networkRoles != null) {
            for (let i = 0; i < networkRoles.length; i++) {
              const row = networkRoles[i]
              if (row.sphereTypeId === 2) {
                employeeData = row
                employeeData.token = res["data"].token
                employeeData.userToken = res["data"].userToken
                employeeData.mfa = res["data"].mfa
                employeeData.userIds = res["data"].userIds
                isLogin = true
              }
            }
          }
        } else {
          const networkRoles = res["data"].networkRoles
          if (networkRoles != null) {
            for (let i = 0; i < networkRoles.length; i++) {
              const row = networkRoles[i]
              if (row.sphereTypeId === 2) {
                localStorage.setItem("employeeId", row.employeeId)
                localStorage.setItem("employeeEmail", row.email)
                localStorage.setItem("employeeData", JSON.stringify(row))
                localStorage.setItem("isContract", row.isContract)
                localStorage.setItem("employeeName", row.name)
                isLogin = true
              }
            }
          }
          const affiliateRoles = res["data"].affiliateRoles
          if (affiliateRoles != null) {
            for (let i = 0; i < affiliateRoles.length; i++) {
              const row = affiliateRoles[i]
              if (row.sphereTypeId === 9) {
                localStorage.setItem("affiliateUserId", row.employeeId)
                localStorage.setItem("affiliateUserData", JSON.stringify(row))
              }
            }
          }

          localStorage.setItem(
            "isChannelList",
            res["data"].subscriptions != null &&
              res["data"].subscriptions.length > 0
              ? 1
              : 0
          )

          const subscriptions = res["data"].subscriptions
          localStorage.setItem("isSubscriptionsNull", subscriptions != null)
          localStorage.setItem("newUserToken", res["data"].newUserToken)
          localStorage.setItem("userToken", res["data"].userToken)
        }
        if (isLogin) {
          window.localStorage.setItem("isLogin", "1")
          yield put(
            userLoginSuccess({
              loginSuccess: true,
              loading: true,
              employeeData: employeeData,
            })
          )
        } else {
          yield put(
            userLoginFailure({
              loginSuccess: false,
              loading: false,
              err: "Invalid Credential.",
            })
          )
        }
      } else {
        yield put(
          userLoginFailure({
            loginSuccess: false,
            loading: false,
            err: res["message"],
          })
        )
      }
    }
  } catch (err) {
    log("signIn.sagas", "loginUser", err)
    yield put(
      userLoginFailure({ loginSuccess: false, loading: false, err: err })
    )
  }
}

/** Saga for user login */
export function* userLogin() {
  yield takeLeading(SignInActionTypes.LOGIN_START, loginUser)
}

/**
 * Initialise all local sagas
 */
export function* signInSagas() {
  yield all([call(userLogin)])
}
