import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Col, Tab, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactJWPlayer from "react-jw-player";
// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import { selectEmployeeData } from "../../store/home/home.selectors";
SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const mapStateToProps = (state) => {
  return { employeeData: selectEmployeeData(state) };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class SeriesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seriesMap: props.seriesMap,
      tagName: props.tagName,
      id: props.id,
      viewAllUrl: props.viewAllUrl,
      playNowUrl: props.playNowUrl,
      isLogin: false,
      isShowModel: false,
      trailerUrl: "",
    };
  }

  componentDidMount() {
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId !== null
    ) {
      this.setState({ isLogin: true });
    } else {
      this.setState({ isLogin: false });
    }
  }

  handleClose = () => {
    this.setState({ isShowModel: false, trailerUrl: "" });
  };

  handleShow = (trailerUrl) => {
    this.setState({ isShowModel: true, trailerUrl: trailerUrl });
  };

  render() {
    return (
      <section className="s-margin" id="space-series">
        <Modal
          show={this.state.isShowModel}
          centered
          dialogClassName="modal-fullscreen"
          id="app-modal"
        >
          <div id="modal-content-wrapper">
            <Modal.Header
              closeButton
              onHide={this.handleClose}
              backdrop="static"
            ></Modal.Header>
            <Modal.Body>
              <ReactJWPlayer
                playerId="movie"
                playerScript="https://cdn.jwplayer.com/libraries/zFFedrqG.js"
                file={this.state.trailerUrl}
                isAutoPlay={true}
                //aspectRatio="20:9"
                customProps={{
                  playbackRateControls: [1, 1.25, 1.5],
                  cast: {},
                }}
              />
            </Modal.Body>
          </div>
        </Modal>
        <Row className="set-brand-con" id="set-brand-con">
          <Col sm="12" className="overflow-hidden p-0">
            <div
              className="d-flex align-items-center justify-content-between"
              id="d-series-network"
            >
              <h3 className="main-title text-uppercase d-brand-network">
                VEUIT ORIGINAL SERIES
              </h3>
            </div>
            <div>
              <Swiper
                loop={false}
                slidesPerView={1}
                watchSlidesProgress={true}
                id="trending-slider"
                className="list-inline p-0 m-0  d-flex align-items-center iq-rtl-direction"
              >
                {this.state.seriesMap.map((item, key) => {
                  return (
                    <SwiperSlide as="li" key={key}>
                      {item.bannerType === "Video" ? (
                        <div className="tranding-block position-relative">
                          <div className="img-box exclusive-event-series">
                            <hr className="set-gradient-border"></hr>
                            {item.bannerUrl != null &&
                              item.bannerUrl !== "" && (
                                <video playsInline
                                  autoPlay
                                  loop
                                  muted
                                  className="event-video-series"
                                  id="series-bg-img"
                                >
                                  <source
                                    src={item.bannerUrl}
                                    type="video/mp4"
                                  />
                                </video>
                              )}
                            <hr className="set-gradient-border"></hr>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              h: 100,
                            }}
                          >
                            <Tab.Container
                              defaultActiveKey="trending-data1 h-100"
                              className="trending-custom-tab"
                            >
                              <Tab.Content className="trending-content h-100">
                                <Row className="set-brand-con align-items-center justify-content-center h-100 parallaxt-details">
                                  <div className="r-mb-23 col">
                                    <div className="text-center d-banner-space">
                                      {item.seriesId != null &&
                                      item.seriesId === 20140 ? (
                                        <img
                                          src={require("../../assets/images/the count.png")}
                                          alt="img"
                                          className="count-series-img"
                                        ></img>
                                      ) : (
                                        <h1 className="series-listname">
                                          {item.seriesName}
                                        </h1>
                                      )}
                                      <p
                                        className="slider-text d-slide-txt"
                                        id="veuit-mob-text"
                                      >
                                        A VEUIT Original Channel
                                      </p>
                                      <div className="parallax-buttons">
                                        {this.state.isLogin ? (
                                          <>
                                            {item.isEpisodeData != null &&
                                            item.isEpisodeData !== null &&
                                            item.isEpisodeData === 1 ? (
                                              <Link
                                                to={`/series-details/${item.seriesId}`}
                                                className="btn d-btn"
                                              >
                                                VIEW NOW
                                              </Link>
                                            ) : (
                                              <Link
                                                to="#"
                                                className="btn d-btn"
                                              >
                                                Comming Soon..
                                              </Link>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            {item.trailerUrl !== null &&
                                            item.trailerUrl !== "" ? (
                                              <Link
                                                className="btn d-btn"
                                                to="#"
                                                onClick={this.handleShow.bind(
                                                  this,
                                                  item.trailerUrl
                                                )}
                                              >
                                                VIEW TRAILER
                                              </Link>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                              </Tab.Content>
                            </Tab.Container>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <hr className="set-gradient-border"></hr>
                          <div
                            className="tranding-block position-relative"
                            style={{
                              backgroundImage: `url(${item.bannerUrl})`,
                              height: "",
                            }}
                            id="series-bg-img"
                          >
                            <Tab.Container
                              defaultActiveKey="trending-data1 h-100"
                              className="trending-custom-tab"
                            >
                              <Tab.Content className="trending-content h-100">
                                <Row className="set-brand-con align-items-center justify-content-center h-100 parallaxt-details">
                                  <div className="r-mb-23 col">
                                    <div className="text-center d-banner-space">
                                      {item.seriesId != null &&
                                      item.seriesId === 20140 ? (
                                        <img
                                          src={require("../../assets/images/the count.png")}
                                          alt="img"
                                          className="count-series-img"
                                        ></img>
                                      ) : (
                                        <h1 className="series-listname">
                                          {item.seriesName}
                                        </h1>
                                      )}
                                      <p
                                        className="slider-text d-slide-txt"
                                        id="veuit-mob-text"
                                      >
                                        A VEUIT Original Series
                                      </p>
                                      <div className="parallax-buttons">
                                        {this.state.isLogin ? (
                                          <>
                                            {item.isEpisodeData != null &&
                                            item.isEpisodeData !== null &&
                                            item.isEpisodeData === 1 ? (
                                              <Link
                                                className="btn d-btn"
                                                to={`/series-details/${item.seriesId}`}
                                              >
                                                VIEW NOW
                                              </Link>
                                            ) : (
                                              <Link
                                                to="#"
                                                className="btn d-btn"
                                              >
                                                Comming Soon..
                                              </Link>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            {item.trailerUrl !== null &&
                                            item.trailerUrl !== "" ? (
                                              <Link
                                                className="btn d-btn"
                                                to="#"
                                                onClick={this.handleShow.bind(
                                                  this,
                                                  item.trailerUrl
                                                )}
                                              >
                                                VIEW TRAILER
                                              </Link>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                              </Tab.Content>
                            </Tab.Container>
                          </div>
                          <hr className="set-gradient-border"></hr>
                        </div>
                      )}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </Col>
        </Row>
      </section>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SeriesList);
