import React from 'react'
import AuthenticationRoute from '../router/authentication-route'

export class AuthenticationLayout extends React.Component {
    render() {
        return (

            <div className="">
                <AuthenticationRoute />
            </div>

        );
    }
}

export default AuthenticationLayout;