import React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from 'react-router-dom'
import { Row, Col, Tab, } from 'react-bootstrap'
import AppConfig from '../../config/app.config';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);




const mapStateToProps = (state) => {
    return {
    };
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
        },
        dispatch
    )
})



export class FeaturedChannelList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataMap: props.dataMap,
            tagName: props.tagName,
            viewAllUrl: props.viewAllUrl,
            playNowUrl: props.playNowUrl,
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <section id="iq-trending" className="s-margin">

                <Row>
                    <Col sm="12" className="overflow-hidden">
                        <div id="favorites-contens">
                            <div id="prevFeaturedChannel" className="swiper-button swiper-button-prev d-channel-prev"><i className="fa fa-chevron-left d-icon"></i></div>
                            <div id="nextFeaturedChannel" className="swiper-button swiper-button-next d-channel-next"><i className="fa fa-chevron-right d-icon"></i></div>
                            <Swiper
                                navigation={{
                                    prevEl: '#prevFeaturedChannel',
                                    nextEl: '#nextFeaturedChannel'
                                }}
                                loop={false}
                                slidesPerView={1}
                                watchSlidesProgress={true}
                                id="trending-slider"
                                className="list-inline p-0 m-0  d-flex align-items-center iq-rtl-direction d-own-new-img">

                                {this.state.dataMap.map((item, key) => {
                                    return (
                                        <SwiperSlide as="li" key={key}>
                                            {item.contentType === 'Video' ?
                                                <div className="tranding-block2 position-relative" id="feature-bgimg"
                                                    style={{ height: '', }}>
                                                    <div className="img-box exclusive-event">
                                                        <video playsInline autoPlay loop muted className="featured-channel-video">
                                                            <source src={item.contentUrl} type='video/mp4' />
                                                        </video>
                                                    </div>
                                                    {/* <div className="block-description d-block-description h-100" style={{ justifyContent: 'center', width: '100%', left: 0 }}> */}
                                                    <div className="block-description-featured-channel-video" style={{ width: '100%' }}>
                                                        <Tab.Container defaultActiveKey="trending-data1" className="trending-custom-tab">
                                                            <Tab.Content className="trending-content">
                                                                <div className=" align-items-center w-100 animated fadeInUp iq-ltr-direction d-set-feature-content">
                                                                    <h1 className="d-title-feature d-title-feature-2 text-uppercase">FEATURED CHANNELS</h1>
                                                                    <br />
                                                                    <div className="d-set-featured-video d-set-featured-center">
                                                                        <img src={AppConfig.API.MANU_IMAGE + item.featuredChannelId + "&random=" + Date.now()} alt={item.featuredChannelName} className="d-image-1" />
                                                                        <div className="d-flex align-items-center justify-content-center p-0 d-featured-btn">
                                                                            <Link target="_blank" to={{ pathname: item.featuredChannelWebsiteUrl }} className="btn d-btn mr-2" tabIndex="0">VIEW NOW</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tab.Content>
                                                        </Tab.Container>
                                                    </div>
                                                </div>
                                                :
                                                <div className="d-own-new-img2 tranding-block2 position-relative" id=""
                                                    style={{ backgroundImage: `url(${item.contentUrl})`, height: '', }}>
                                                    <Tab.Container defaultActiveKey="trending-data1" className="trending-custom-tab">
                                                        <Tab.Content className="trending-content">
                                                            <div className=" align-items-center w-100 animated fadeInUp iq-ltr-direction d-set-feature-content">
                                                                <h1 className="d-title-feature text-uppercase">FEATURED CHANNELS</h1>
                                                                <br />
                                                                <div className="d-set-featured-channel-photo">
                                                                    <img src={AppConfig.API.MANU_IMAGE + item.featuredChannelId + "&random=" + Date.now()} alt={item.featuredChannelName} className="d-image-2" />
                                                                    <div className="d-flex align-items-center justify-content-center p-0 d-featured-btn">
                                                                        <Link target="_blank" to={{ pathname: item.featuredChannelWebsiteUrl }} className="btn d-btn mr-2" tabIndex="0">VIEW NOW</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tab.Content>
                                                    </Tab.Container>
                                                </div>
                                            }
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </Col>
                </Row>

            </section>

        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedChannelList)