import React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DynamicStar } from 'react-dynamic-star';


// swiper
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const mapStateToProps = (state) => {
    return {
    };
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
        },
        dispatch
    )
})


export class StarRating extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ratingCount: props.ratingCount / 2,
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="slider-ratting d-flex align-items-center mr-4 mt-2 mt-md-3" data-iq-gsap="onStart" data-iq-position-x="-200" data-iq-delay="-0.5" id="movie-detail-ratting">
                <div id="movie-rating">
                    <DynamicStar rating={this.state.ratingCount.toFixed(1)} fullStarColor='#FFE600' emptyStarColor='#ffffff' width='16' height='16' /></div>
                {/* <div id="series-rating-mob" >
                    <DynamicStar rating={this.state.ratingCount.toFixed(1)} fullStarColor=' #2DD0FF'
                        emptyStarColor='#ffffff' width='10' height='10' /></div> */}
                <span className="text-white ml-2 review-avg-rating">{this.state.ratingCount} Avg Reviews</span>

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StarRating)