import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import validator from "validator";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import "react-datepicker/dist/react-datepicker.css";
import Backdrop from "@material-ui/core/Backdrop";
import Card from "../../components/Card";
import UserService from "../../services/user/user.service";
import AppConfig from "../../config/app.config";
import { Link } from "react-router-dom";
import { setSigninRedirectPath } from "../../store/home/home.actions";
import { selectEmployeeData } from "../../store/home/home.selectors";

//var XMLParser = require('react-xml-parser');
const removeExtraSpace = (s) => s.replace(/\s/g, "");

const mapStateToProps = (state) => {
  return {
    employeeData: selectEmployeeData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setSigninRedirectPath,
    },
    dispatch
  ),
});

export class ChannelRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 1,
      showDomain: "Active",
      showPersonal: "",
      showChannel: "",
      showPlan: "",
      showFinish: "",
      isDomain: "0",
      domainName: "",
      name: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      channelName: "",
      channelTypeId: "-1",
      channelSubTypeId: "-1",
      channelTypeName: "",
      channelSubTypeName: "",
      channelTypeMap: [],
      channelSubTypeMap: [],
      subscriptionType: "Monthly",
      productListMap: [],
      finalProductList: [],
      passwordType: "password",
      confirmPasswordType: "password",
      dynamicProductList: [],
    };
  }

  componentDidMount() {
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId != null
    ) {
      window.scrollTo(0, 0);

      this.setState({
        firstName: this.props.employeeData?.firstName,
        lastName: this.props.employeeData?.lastName,
        name: this.props.employeeData?.name,
        phone: this.props.employeeData?.phone,
        email: this.props.employeeData?.email,
      });

      this.getDynamicProductList();
      this.getChannelTypeList();
      this.getProductList();
    } else {
      this.props.setSigninRedirectPath({
        signinRedirectPath: window.location.pathname,
      });
      const reqUrl = window.btoa(AppConfig.API.DOMAIN_URL + window.location.pathname);
      const loginDomainUrl = AppConfig.API.LOGIN_DOMAIN_URL;
      window.location.href = `${loginDomainUrl}/sign-in?reqUrl=${reqUrl}`;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId != null
    ) {
    } else {
      this.props.setSigninRedirectPath({
        signinRedirectPath: window.location.pathname,
      });
      const reqUrl = window.btoa(AppConfig.API.DOMAIN_URL + window.location.pathname);
      const loginDomainUrl = AppConfig.API.LOGIN_DOMAIN_URL;
      window.location.href = `${loginDomainUrl}/sign-in?reqUrl=${reqUrl}`;
    }
  }

  getChannelTypeList = () => {
    const payload = {
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
    };
    UserService.getDisciplineList(payload)
      .then((res) => {
        if (res.error != null) {
          NotificationManager.warning(
            res.error.response.data.message,
            "",
            2000
          );
        } else {
          if (res["status"]) {
            this.setState({ channelTypeMap: res["data"] });
          } else {
            NotificationManager.error(res["message"], "", 2000);
          }
        }
      })
      .catch((err) => {
        NotificationManager.error(err, "", 2000);
      });
  };

  getProductList = () => {
    const payload = {
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
    };
    UserService.getProductList(payload)
      .then((res) => {
        if (res.error != null) {
          NotificationManager.warning(
            res.error.response.data.message,
            "",
            2000
          );
        } else {
          if (res["status"]) {
            this.setState({ productListMap: res["data"] });
          } else {
            NotificationManager.error(res["message"], "", 2000);
          }
        }
      })
      .catch((err) => {
        NotificationManager.error(err, "", 2000);
      });
  };

  getDynamicProductList = () => {
    const payload = {
      networkId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.VERTICAL_ID,
      loginEmployeeId: this.props.employeeData?.employeeId,
    };
    UserService.getDynamicProductList(payload)
      .then((res) => {
        if (res.error != null) {
          this.setState({ isLoading: 0 });
          NotificationManager.warning(
            res.error.response.data.message,
            "",
            2000
          );
        } else {
          if (res["status"]) {
            const data = res["data"];
            this.setState({
              isLoading: 0,
              dynamicProductList: data.products,
              firstName: data.kyb.firstName,
              lastName: data.kyb.lastName,
              phone: data.kyb.phone,
              email: data.kyb.email,
              channelName: data.kyb.channelName,
            });
          } else {
            this.setState({ isLoading: 0 });
            NotificationManager.error("This Domain is Unavailable", "", 2000);
          }
        }
      })
      .catch((err) => {
        this.setState({ isLoading: 0 });
        NotificationManager.error(err, "", 2000);
      });
  };

  validateDomain = () => {
    const payload = {
      isDomain: this.state.isDomain,
      domain: this.state.domainName,
    };
    UserService.validateDomain(payload)
      .then((res) => {
        if (res.error != null) {
          this.setState({ isLoading: 0 });
          NotificationManager.warning(
            res.error.response.data.message,
            "",
            2000
          );
        } else {
          if (res["status"]) {
            if (res["data"].isValidate) {
              this.setState({
                showDomain: "done",
                showPersonal: "Active",
                showChannel: "",
                showPlan: "",
                showFinish: "",
                isLoading: 0,
              });
            } else {
              this.setState({ isLoading: 0 });
              NotificationManager.error("This Domain is Unavailable", "", 2000);
            }
          } else {
            this.setState({ isLoading: 0 });
            NotificationManager.error("This Domain is Unavailable", "", 2000);
          }
        }
      })
      .catch((err) => {
        this.setState({ isLoading: 0 });
        NotificationManager.error(err, "", 2000);
      });
  };

  updateShow = (value) => {
    this.setState({ isLoading: 1 });
    if (value === "Domain") {
      this.setState({
        showDomain: "Active",
        showPersonal: "",
        showChannel: "",
        showPlan: "",
        showFinish: "",
        isLoading: 0,
      });
    } else if (value === "Personal") {
      const rule = new RegExp(/^[a-zA-Z0-9]{3,40}$/);
      if (this.state.domainName === "") {
        this.setState({ isLoading: 0 });
        NotificationManager.warning("Please Enter Domain Name.", "", 2000);
      } else if (!rule.test(this.state.domainName)) {
        this.setState({ isLoading: 0 });
        NotificationManager.warning(
          "Domain name must contains atleast three letter with characters and numaric value.",
          "",
          2000
        );
      } else {
        this.validateDomain();
      }
    } else if (value === "Channel") {
      var pattern = new RegExp(/^\+?[0-9]*$/);
      const rule = new RegExp(/^[a-zA-Z ]{2,40}$/);
      const channelRule = new RegExp(/^[a-zA-Z0-9 ]{3,40}$/);
      if (this.state.firstName === "") {
        this.setState({ isLoading: 0 });
        NotificationManager.warning("Please Enter Fist Name.", "", 2000);
      } else if (!rule.test(this.state.firstName)) {
        this.setState({ isLoading: 0 });
        NotificationManager.warning(
          "First Name must contains at list two letter with only characters, not allow numaric value.",
          "",
          2000
        );
      } else if (this.state.lastName === "") {
        this.setState({ isLoading: 0 });
        NotificationManager.warning("Please Enter Last Name.", "", 2000);
      } else if (!rule.test(this.state.lastName)) {
        this.setState({ isLoading: 0 });
        NotificationManager.warning(
          "Last Name must contains at list two letter with only characters, not allow numaric value.",
          "",
          2000
        );
      } else if (this.state.email === "") {
        this.setState({ isLoading: 0 });
        NotificationManager.warning("Please Enter Email.", "", 2000);
      } else if (!validator.isEmail(this.state.email)) {
        this.setState({ isLoading: 0 });
        NotificationManager.warning("Please Enter Valid Email.", "", 2000);
      } else if (this.state.phone === "") {
        this.setState({ isLoading: 0 });
        NotificationManager.warning("Please Enter Phone.", "", 2000);
      } else if (!pattern.test(this.state.phone)) {
        this.setState({ isLoading: 0 });
        NotificationManager.warning("Please Enter Valid Phone.", "", 2000);
      } else if (this.state.phone.length < 10 && this.state.phone.length > 13) {
        this.setState({ isLoading: 0 });
        NotificationManager.warning("Please Enter Valid Phone.", "", 2000);
      } else if (this.state.channelName === "") {
        this.setState({ isLoading: 0 });
        NotificationManager.warning("Please Enter Channel Name.", "", 2000);
      } else if (!channelRule.test(this.state.channelName)) {
        this.setState({ isLoading: 0 });
        NotificationManager.warning(
          "Channel name must contains atleast three letter with characters and numaric value.",
          "",
          2000
        );
      } else {
        this.setState({
          showDomain: "done",
          showPersonal: "done",
          showChannel: "Active",
          showPlan: "",
          showFinish: "",
          isLoading: 0,
        });
      }

      // else if (this.state.password === '') {
      //     this.setState({ isLoading: 0 });
      //     NotificationManager.warning("Please Enter Password.", '', 2000);
      // } else if (!validator.isStrongPassword(this.state.password)) {
      //     this.setState({ isLoading: 0 });
      //     NotificationManager.warning("Password must contains at least one lowercase letter, one uppercase letter, one numeric digit, and one special character and length between 8 to 20 characters.", '', 2000);
      // } else if (this.state.confirmPassword === '') {
      //     this.setState({ isLoading: 0 });
      //     NotificationManager.warning("Please Enter Confirm Password.", '', 2000);
      // } else if (this.state.confirmPassword !== this.state.password) {
      //     this.setState({ isLoading: 0 });
      //     NotificationManager.warning("Password's do not match.", '', 2000);
      // }
    } else if (value === "Plan") {
      if (this.state.channelTypeId === "-1") {
        this.setState({ isLoading: 0 });
        NotificationManager.warning("Please select channel type", "", 2000);
      } else {
        this.setState({
          showDomain: "done",
          showPersonal: "done",
          showChannel: "done",
          showPlan: "Active",
          showFinish: "",
          isLoading: 0,
        });
      }
    } else if (value === "Finish") {
      if (this.state.channelTypeId === "-1") {
        this.setState({ isLoading: 0 });
        NotificationManager.warning("Please select channel type", "", 2000);
      } else {
        this.setState({
          showDomain: "done",
          showPersonal: "done",
          showChannel: "done",
          showPlan: "done",
          showFinish: "Active",
          isLoading: 0,
        });
      }

      // if (this.state.productListMap.length === this.state.finalProductList.length) {
      //     this.setState({ showDomain: 'done', showPersonal: 'done', showChannel: 'done', showPlan: 'done', showFinish: 'Active', isLoading: 0 });
      // } else {
      //     this.setState({ isLoading: 0 });
      //     NotificationManager.warning("Please select all dropdown value", '', 2000);
      // }
    } else if (value === "Save") {
      this.regiserVeuitChannel();
    }
  };

  regiserVeuitChannel = () => {
    //let productList = [];
    // var str = '';
    // var counter = 1;
    // var totalSalePrice = 0;
    // for (let i = 0; i < this.state.finalProductList.length; i++) {
    //     const row = this.state.finalProductList[i];
    //     const object = {
    //         productId: row.productId,
    //         qtyPricingId: row.qtyPricingId,
    //         salesPrice: this.state.subscriptionType === 'Monthly' ? row.qtyPrice : row.yearlyPrice,
    //         quantity: row.qtyCeiling
    //     }
    //     productList.push(object);
    //     var salesPrice = this.state.subscriptionType === 'Monthly' ? parseInt(row.qtyPrice) : parseInt(row.yearlyPrice);
    //     str += '&name' + counter + '=' + row.productName + '&value' + counter + '=$' + salesPrice;
    //     totalSalePrice += salesPrice;
    //     counter++;
    // }

    const payload = {
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      networkId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.CHANNEL_APP_ID,
      influencerDisciplineId: this.state.channelTypeId,
      influencerDisciplineFocusId: this.state.channelSubTypeId,
      name: this.state.firstName + " " + this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      // password: this.state.password,
      isDomain: this.state.isDomain,
      domain: this.state.domainName,
      subscriptionType: this.state.subscriptionType,
      company: this.state.channelName,
      products: this.state.dynamicProductList,
      isClaimed: 1,
      isPrivate: 1,
      // employeeId: localStorage.getItem('affiliateUserId'),
      loginEmployeeId: this.props.employeeData?.employeeId,
      // affiliateId:localStorage.getItem('affiliateUserId'),
    };
    const isSubscriptionNull = localStorage.getItem("isSubscriptionsNull");
    if (isSubscriptionNull === "true") {
      payload["employeeId"] = localStorage.getItem("affiliateUserId");
    } else {
      payload["affiliateId"] = localStorage.getItem("affiliateUserId");
    }
    UserService.regiserVeuitChannel(payload)
      .then((res) => {
        if (res.error != null) {
          this.setState({ isLoading: 0 });
          NotificationManager.warning(
            res.error.response.data.message,
            "",
            2000
          );
        } else {
          if (res["status"]) {
            const data = res["data"];
            if (
              data.paymentGatewayInfo &&
              data.paymentGatewayInfo.url !== null &&
              data.paymentGatewayInfo.url !== undefined
            ) {
              if (
                data.paymentGatewayInfo.requestParams.paymentServiceProvider !==
                "BLUESNAP"
              ) {
                NotificationManager.error(
                  "Error while accessing payment page. Please contact administrator.",
                  "",
                  3000
                );
                this.props.history.push("/");
              } else {
                var paymentUrl = data.paymentGatewayInfo.url + "?";
                const paymentParameters = [];
                const params = data.paymentGatewayInfo.requestParams;
                if (params.alipay !== null && params.alipay !== undefined) {
                  paymentParameters.push(`alipay=${params.alipay}`);
                }
                if (params.appId !== null && params.appId !== undefined) {
                  paymentParameters.push(`appId=${params.appId}`);
                }
                if (params.applepay !== null && params.applepay !== undefined) {
                  paymentParameters.push(`applepay=${params.applepay}`);
                }
                if (
                  params.banktransfer !== null &&
                  params.banktransfer !== undefined
                ) {
                  paymentParameters.push(`banktransfer=${params.banktransfer}`);
                }
                if (
                  params.boletoBancario !== null &&
                  params.boletoBancario !== undefined
                ) {
                  paymentParameters.push(
                    `boletoBancario=${params.boletoBancario}`
                  );
                }
                // if (params.callBackUrl !== null && params.callBackUrl !== undefined) {
                paymentParameters.push(
                  `thankyou.backtosellerurl=${`http://veuit.development.com.s3-website-us-east-1.amazonaws.com/channel-payment-status-success${params.merchantTransactionId != null
                    ? `/${params.merchantTransactionId}`
                    : ""
                  }`}`
                );
                // }
                if (params.cc !== null && params.cc !== undefined) {
                  paymentParameters.push(`cc=${params.cc}`);
                }
                if (params.country !== null && params.country !== undefined) {
                  paymentParameters.push(`country=${params.country}`);
                }
                if (
                  params.countrydisable !== null &&
                  params.countrydisable !== undefined
                ) {
                  paymentParameters.push(
                    `countrydisable=${params.countrydisable}`
                  );
                }
                if (params.currency !== null && params.currency !== undefined) {
                  paymentParameters.push(`currency=${params.currency}`);
                }
                if (
                  params.currencydisable !== null &&
                  params.currencydisable !== undefined
                ) {
                  paymentParameters.push(
                    `currencydisable=${params.currencydisable}`
                  );
                }
                if (params.ecp !== null && params.ecp !== undefined) {
                  paymentParameters.push(`ecp=${params.ecp}`);
                }
                if (params.enc !== null && params.enc !== undefined) {
                  paymentParameters.push(`enc=${params.enc}`);
                }
                if (
                  params.googlepay !== null &&
                  params.googlepay !== undefined
                ) {
                  paymentParameters.push(`googlepay=${params.googlepay}`);
                }
                if (
                  params.merchantTransactionId !== null &&
                  params.merchantTransactionId !== undefined
                ) {
                  paymentParameters.push(
                    `merchantTransactionId=${params.merchantTransactionId}`
                  );
                }
                if (
                  params.merchantid !== null &&
                  params.merchantid !== undefined
                ) {
                  paymentParameters.push(`merchantid=${params.merchantid}`);
                }
                if (
                  params.moneybookers !== null &&
                  params.moneybookers !== undefined
                ) {
                  paymentParameters.push(`moneybookers=${params.moneybookers}`);
                }
                if (
                  params.paymentServiceProvider !== null &&
                  params.paymentServiceProvider !== undefined
                ) {
                  paymentParameters.push(
                    `paymentServiceProvider=${params.paymentServiceProvider}`
                  );
                }
                if (params.paypal !== null && params.paypal !== undefined) {
                  paymentParameters.push(`paypal=${params.paypal}`);
                }
                if (
                  params.paysafecard !== null &&
                  params.paysafecard !== undefined
                ) {
                  paymentParameters.push(`paysafecard=${params.paysafecard}`);
                }
                if (
                  params.realtimebank1 !== null &&
                  params.realtimebank1 !== undefined
                ) {
                  paymentParameters.push(
                    `realtimebank1=${params.realtimebank1}`
                  );
                }
                if (params.sepadd !== null && params.sepadd !== undefined) {
                  paymentParameters.push(`sepadd=${params.sepadd}`);
                }
                if (params.skrill !== null && params.skrill !== undefined) {
                  paymentParameters.push(`skrill=${params.skrill}`);
                }
                if (
                  params.subscriptionId !== null &&
                  params.subscriptionId !== undefined
                ) {
                  paymentParameters.push(
                    `subscriptionId=${params.subscriptionId}`
                  );
                }
                if (params.webmoney !== null && params.webmoney !== undefined) {
                  paymentParameters.push(`webmoney=${params.webmoney}`);
                }
                if (params.wire !== null && params.wire !== undefined) {
                  paymentParameters.push(`wire=${params.wire}`);
                }
                if (params.products !== null && params.products !== undefined) {
                  var index = 1;
                  params.products.forEach((product) => {
                    paymentParameters.push(`name${index}=${product.product}`);
                    paymentParameters.push(
                      `value${index}=${product.salesPrice}`
                    );
                    index += 1;
                  });
                }
                paymentUrl = paymentUrl + paymentParameters.join("&");
                window.location.href = paymentUrl;
              }
            } else {
              this.setState({ isLoading: 0 });
              this.props.history.push("/channel-payment-status-success");
            }

            // this.setState({ isLoading: 0 });
            // this.props.history.push('/authentication/success/' + res['data'].subscriptions[0].subscriptionId);
            // if (totalSalePrice > 0) {
            //     UserService.genrateToken(totalSalePrice).then(result => {
            //         if (result.error != null) {
            //             this.setState({ isLoading: 0 });
            //             NotificationManager.warning(result.error.response.data.message, '', 2000);
            //         } else {
            //             if (result['status']) {
            //                 var xml = new XMLParser().parseFromString(result['data']);
            //                 var token = xml.getElementsByTagName('encrypted-token')[0].value;
            //                 window.location.href = 'https://sandbox.bluesnap.com/buynow/checkout?enc=' + token + '&merchantid=1002693&currency=USD&country=us&countrydisable=Y&currencydisable=Y' + str + '&merchantTransactionId=' + res['data'].subscriptionId + '&subscriptionId=' + res['data'].subscriptionId + '&alipay=N&applepay=N&banktransfer=N&boletoBancario=N&cc=Y&ecp=N&googlepay=N&moneybookers=N&paypal=N&paysafecard=N&realtimebank1=N&sepadd=N&skrill=N&webmoney=N&wire=N&thankyou.backtosellerurl=https://veuit.ciright.com/authentication/success/' + res['data'].subscriptionId;
            //             } else {
            //                 this.setState({ isLoading: 0 });
            //                 NotificationManager.error("Error", '', 2000);
            //             }
            //         }
            //     }).catch(err => {
            //         this.setState({ isLoading: 0 });
            //         NotificationManager.error(err, '', 2000);
            //     })
            // } else {
            //     this.setState({ isLoading: 0 });
            //     this.props.history.push('/authentication/success/' + res['data'].subscriptions.subscriptionId);
            // }
          } else {
            this.setState({ isLoading: 0 });
            NotificationManager.error(res["message"], "", 2000);
          }
        }
      })
      .catch((err) => {
        this.setState({ isLoading: 0 });
        NotificationManager.error(err, "", 2000);
      });
  };

  updateDomain = (value) => {
    if (value === "domian") {
      this.setState({ isDomain: "1" });
    } else if (value === "subDomian") {
      this.setState({ isDomain: "0" });
    }
  };

  handleDomainName(event) {
    this.setState({ domainName: removeExtraSpace(event.target.value) });
  }

  handleName(event) {
    this.setState({ name: event.target.value });
  }

  handleFirstName(event) {
    this.setState({ firstName: event.target.value });
  }
  handleLastName(event) {
    this.setState({ lastName: event.target.value });
  }

  handleEmail(event) {
    this.setState({ email: removeExtraSpace(event.target.value) });
  }

  handlePhone(event) {
    const result = new RegExp(/^\+?[0-9]*$/);
    if (event.target.value === "" || result.test(event.target.value)) {
      this.setState({ phone: removeExtraSpace(event.target.value) });
    }
  }

  handlePassword(event) {
    this.setState({ password: event.target.value });
  }

  handleConfirmPassword(event) {
    this.setState({ confirmPassword: event.target.value });
  }

  handleChannelName(event) {
    this.setState({ channelName: event.target.value });
  }

  handleSelectChaneelType(event) {
    var channelTypeId = parseInt(event.target.value);
    var channelTypeName = "";
    if (channelTypeId !== "-1") {
      const index = this.state.channelTypeMap.findIndex(
        (x) => x.key === channelTypeId
      );
      if (index !== -1) {
        channelTypeName = this.state.channelTypeMap[index].value;
      }
    }
    this.setState({
      channelTypeId: event.target.value,
      channelTypeName: channelTypeName,
    });
    this.getChannelSubTypeList(event.target.value);
  }

  getChannelSubTypeList = (disciplineId) => {
    if (disciplineId === "-1") {
      this.setState({
        channelSubTypeMap: [],
        channelSubTypeId: "-1",
        channelSubTypeName: "",
      });
    } else {
      UserService.getFocusListByDiscipline(disciplineId)
        .then((res) => {
          if (res.error != null) {
            NotificationManager.warning(
              res.error.response.data.message,
              "",
              2000
            );
          } else {
            if (res["status"]) {
              this.setState({
                channelSubTypeMap: res["data"],
                channelSubTypeId: "-1",
                channelSubTypeName: "",
              });
            } else {
              NotificationManager.error(res["message"], "", 2000);
            }
          }
        })
        .catch((err) => {
          NotificationManager.error(err, "", 2000);
        });
    }
  };

  handleSelectChaneelSubType(event) {
    var channelSubTypeId = parseInt(event.target.value);
    var channelSubTypeName = "";
    if (channelSubTypeId !== "-1") {
      const index = this.state.channelSubTypeMap.findIndex(
        (x) => x.key === channelSubTypeId
      );
      if (index !== -1) {
        channelSubTypeName = this.state.channelSubTypeMap[index].value;
      }
    }
    this.setState({
      channelSubTypeId: event.target.value,
      channelSubTypeName: channelSubTypeName,
    });
  }

  updateSubscriptionType = (value) => {
    this.setState({ subscriptionType: value });
  };

  handleProduct = (productId, productName, qtyPricing, event) => {
    var qtyPricingId = parseInt(event.target.value);
    const index = qtyPricing.findIndex((x) => x.qtyPricingId === qtyPricingId);
    var productList = this.state.finalProductList;
    if (index !== -1) {
      const object = {
        qtyPricingId: event.target.value,
        productId: productId,
        productName: productName,
        qtyPrice: qtyPricing[index].qtyPrice,
        yearlyPrice: qtyPricing[index].yearlyPrice,
        qtyCeiling: qtyPricing[index].qtyCeiling,
      };
      const indexId = productList.findIndex((x) => x.productId === productId);
      if (indexId !== -1) {
        productList[indexId] = object;
      } else {
        productList.push(object);
      }
      this.setState({ finalProductList: productList });
    } else {
      const items = this.state.finalProductList.filter(
        (finalProductList) => finalProductList.productId !== productId
      );
      this.setState({ finalProductList: items });
    }
  };

  showPassword = () => {
    if (this.state.passwordType === "password") {
      this.setState({ passwordType: "text" });
    } else {
      this.setState({ passwordType: "password" });
    }
  };

  showConfirmPassword = () => {
    if (this.state.confirmPasswordType === "password") {
      this.setState({ confirmPasswordType: "text" });
    } else {
      this.setState({ confirmPasswordType: "password" });
    }
  };

  render() {
    return (
      <section
        className="m-profile setting-wrapper set-channel-img"
        style={{ minHeight: window.innerHeight - 10 }}
      >
        {this.state.isLoading === 1 ? (
          <Backdrop style={{ zIndex: 100, color: "#003699" }} open>
            <img
              src={require("../../assets/images/Veuit-Spinner.gif")}
              style={{
                textAlign: "center",
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
              className="c-logo"
              alt="streamit"
            />
          </Backdrop>
        ) : (
          ""
        )}
        {/* <Container fluid> */}
        <Row className="m-0">
          <Col sm="12" lg="12">
            <div className="channel-registration">
              <div className="channel-set-center">
                <div className="channel-logos">
                  <img
                    src={require("../../assets/images/VEUIT Logo (White) 3 (1).png")}
                    alt="Logo"
                    className="text-center"
                  ></img>
                  <h3 className="channel-card-title">
                    Create Your Own Channel
                  </h3>
                  <p className="channel-upgrade">Upgrade to Web 3</p>
                </div>
                <Card>
                  {/* <Card.Header className="d-flex justify-content-between">
                                            <Card.Header.Title>
                                                <br />
                                               
                                            </Card.Header.Title>
                                        </Card.Header> */}
                  <Card.Body>
                    <Form id="form-wizard1" className="text-center">
                      <div className="network-timeline">
                        <div className="row set-timeline m-0">
                          <div
                            className={`timeline-circle ${this.state.showDomain === "Active" ||
                                this.state.showDomain === "done"
                                ? "d-bgcolor-round"
                                : ""
                              }`}
                          >
                            1{" "}
                            <p
                              className={`timeline-text ${this.state.showDomain === "done"
                                  ? "d-text-color-round"
                                  : ""
                                }`}
                            >
                              Domain
                            </p>
                          </div>
                          <div
                            className={`timeline-circle ${this.state.showPersonal === "Active" ||
                                this.state.showPersonal === "done"
                                ? "d-bgcolor-round"
                                : ""
                              }`}
                          >
                            2{" "}
                            <p
                              className={`timeline-text ${this.state.showPersonal === "done"
                                  ? "d-text-color-round"
                                  : ""
                                }`}
                            >
                              KYB/Personal
                            </p>
                          </div>
                          <div
                            className={`timeline-circle ${this.state.showChannel === "Active" ||
                                this.state.showChannel === "done"
                                ? "d-bgcolor-round"
                                : ""
                              }`}
                          >
                            3{" "}
                            <p
                              className={`timeline-text ${this.state.showChannel === "done"
                                  ? "d-text-color-round"
                                  : ""
                                }`}
                            >
                              Channel
                            </p>
                          </div>
                          {/* <div className={`timeline-circle ${this.state.showPlan === 'Active' || this.state.showPlan === 'done' ? 'd-bgcolor-round' : ''}`}>
                                                        4 <p className={`timeline-text ${this.state.showPlan === 'done' ? 'd-text-color-round' : ''}`}>Plan</p></div> */}
                          <div
                            className={`timeline-circle ${this.state.showFinish === "Active" ||
                                this.state.showFinish === "done"
                                ? "d-bgcolor-round"
                                : ""
                              }`}
                          >
                            4{" "}
                            <p
                              className={`timeline-text ${this.state.showFinish === "done"
                                  ? "d-text-color-round"
                                  : ""
                                }`}
                            >
                              Review
                            </p>
                          </div>
                          <hr
                            className="timeline-circle-line"
                            style={{
                              background: `linear-gradient( to right,#2DD0FF 0 ${this.state.showFinish === "Active" ||
                                  this.state.showFinish === "done"
                                  ? "100%"
                                  : this.state.showChannel === "Active" ||
                                    this.state.showChannel === "done"
                                    ? "66.66%"
                                    : this.state.showPersonal === "Active" ||
                                      this.state.showPersonal === "done"
                                      ? "33.33%"
                                      : "0%"
                                },#D9D9D9 ${this.state.showFinish === "Active" ||
                                  this.state.showFinish === "done"
                                  ? "100%"
                                  : this.state.showChannel === "Active" ||
                                    this.state.showChannel === "done"
                                    ? "66.66%"
                                    : this.state.showPersonal === "Active" ||
                                      this.state.showPersonal === "done"
                                      ? "33.33%"
                                      : "0%"
                                } 100%)`,
                            }}
                          ></hr>
                        </div>
                      </div>

                      <fieldset
                        className={`${this.state.showDomain === "Active"
                            ? "d-block"
                            : "d-none"
                          }`}
                      >
                        <div
                          className="form-card text-left"
                          style={{ paddingBottom: "20px" }}
                        >
                          {/* <Row>
                                                    <div className="col-7">
                                                        <h4 className="mb-4">Choose Your Domain </h4>
                                                    </div>
                                                    <div className="col-5">
                                                        <h2 className="steps">Step 1 - 5</h2>
                                                    </div>
                                                </Row> */}
                          <div className="choose-domain">
                            Choose Your Domain
                          </div>
                          <Row className="set-domain">
                            <Col xl="4" lg="4" xs="5" md="6">
                              <div className="custom-control custom-radio custom-radio-color-checked custom-control-inline">
                                {
                                  this.state.isDomain === "1" ? (
                                    <Form.Check.Input
                                      reverse="true"
                                      type="radio"
                                      id="customRadio-1"
                                      name="customRadio-10"
                                      className="custom-control-input bg-primary"
                                      defaultChecked
                                      disabled
                                    /> //onChange={this.updateDomain.bind(this, "domian")}
                                  ) : (
                                    <Form.Check.Input
                                      reverse="true"
                                      type="radio"
                                      id="customRadio-1"
                                      name="customRadio-10"
                                      className="custom-control-input bg-primary"
                                      disabled
                                    />
                                  ) // onChange={this.updateDomain.bind(this, "domian")}
                                }
                                <Form.Check.Label
                                  className="custom-control-label channel-label"
                                  htmlFor="customRadio-1"
                                >
                                  {" "}
                                  Personalized Domain{" "}
                                </Form.Check.Label>
                              </div>
                            </Col>
                            <Col xl="6" lg="6" xs="7" md="6">
                              <div className="custom-control custom-radio custom-radio-color-checked custom-control-inline">
                                {
                                  this.state.isDomain === "0" ? (
                                    <Form.Check.Input
                                      type="radio"
                                      id="customRadio-2"
                                      name="customRadio-10"
                                      className="custom-control-input bg-success"
                                      defaultChecked
                                    /> //onChange={this.updateDomain.bind(this, "subDomian")}
                                  ) : (
                                    <Form.Check.Input
                                      type="radio"
                                      id="customRadio-2"
                                      name="customRadio-10"
                                      className="custom-control-input bg-success"
                                    />
                                  ) //onChange={this.updateDomain.bind(this, "subDomian")}
                                }
                                <Form.Check.Label
                                  className="custom-control-label channel-label"
                                  htmlFor="customRadio-2"
                                >
                                  {" "}
                                  FREE Sub Domain{" "}
                                </Form.Check.Label>
                              </div>
                            </Col>
                          </Row>
                          <Row className="set-domain-name m-0">
                            <Col
                              col="12"
                              xl="12"
                              lg="12"
                              sm="12"
                              md="12"
                              className="p-0"
                            >
                              <div className="input-group">
                                <Form.Control
                                  type="text"
                                  name="pwd"
                                  className="set-domain-name1"
                                  value={this.state.domainName}
                                  onChange={this.handleDomainName.bind(this)}
                                  placeholder={
                                    this.state.isDomain === "1"
                                      ? "Domain Name"
                                      : "Sub Domain Name"
                                  }
                                />
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text set-domain-name2"
                                    id="inputGroupPrepend2"
                                    style={{
                                      backgroundColor: "#141414",
                                      border: "1px solid #404043",
                                      color: "#fff",
                                    }}
                                  >
                                    {this.state.isDomain === "1"
                                      ? ".tv"
                                      : ".tv"}
                                  </span>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <Button
                          type="button"
                          name="next"
                          variant="primary next action-button float-right"
                          value="Next"
                          onClick={() => this.updateShow("Personal")}
                        >
                          Next
                        </Button>
                      </fieldset>
                      <fieldset
                        className={`${this.state.showPersonal === "Active"
                            ? "d-block"
                            : "d-none"
                          }`}
                      >
                        <div className="form-card text-left">
                          {/* <Row>
                                                            <div className="col-7">
                                                                <h4 className="mb-4">Basic Information</h4>
                                                            </div>
                                                            <div className="col-5">
                                                                <h2 className="steps">Step 2 - 5</h2>
                                                            </div>
                                                        </Row> */}

                          <div className="channel-basic">Basic Information</div>
                          <Row>
                            <Col md="3">
                              <Form.Group className="form-group">
                                {/* <label>Name </label> */}
                                <Form.Control
                                  type="text"
                                  maxLength="40"
                                  name="name"
                                  className="basic-channel-field"
                                  value={this.state.firstName}
                                  onChange={this.handleFirstName.bind(this)}
                                  placeholder="First Name"
                                />
                              </Form.Group>
                            </Col>
                            <Col md="3">
                              <Form.Group className="form-group">
                                {/* <label>Name </label> */}
                                <Form.Control
                                  type="text"
                                  maxLength="40"
                                  name="name"
                                  className="basic-channel-field"
                                  value={this.state.lastName}
                                  onChange={this.handleLastName.bind(this)}
                                  placeholder="Last Name"
                                />
                              </Form.Group>
                            </Col>
                            <Col md="6">
                              <Form.Group className="form-group">
                                {/* <label>Phone </label> */}
                                <Form.Control
                                  type="text"
                                  name="phone"
                                  maxLength="13"
                                  className="basic-channel-field1"
                                  value={this.state.phone}
                                  onChange={this.handlePhone.bind(this)}
                                  placeholder="Phone Number"
                                />
                              </Form.Group>
                            </Col>

                            <Col md="6">
                              <Form.Group className="form-group">
                                {/* <label>Email </label> */}
                                <Form.Control
                                  type="email"
                                  maxLength="40"
                                  name="email"
                                  className="basic-channel-field1"
                                  value={this.state.email}
                                  onChange={this.handleEmail.bind(this)}
                                  placeholder="Email "
                                  readOnly={true}
                                />
                              </Form.Group>
                            </Col>
                            <Col md="6">
                              <Form.Group className="form-group">
                                {/* <label>Channel Name </label> */}
                                <Form.Control
                                  type="text"
                                  name="channelName"
                                  maxLength="40"
                                  className="basic-channel-field1"
                                  value={this.state.channelName}
                                  onChange={this.handleChannelName.bind(this)}
                                  placeholder="Channel Name"
                                />
                              </Form.Group>
                            </Col>
                            {/* <Col md="6">
                                                            <Form.Group className="form-group">
                                                                <div className="input-group basic-channel-field1">
                                                                    <Form.Control type={this.state.passwordType} className='set-domain-name1' maxLength="20" name="pwd" value={this.state.password} onChange={this.handlePassword.bind(this)} placeholder="********" />
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text set-domain-name2" id="inputGroupPrepend3" onClick={this.showPassword} style={{ backgroundColor: '#141414', border: '1px solid #404043', color: '#fff' }}>
                                                                            <i className={this.state.passwordType === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                        </Col> */}
                            {/* <Col md="6">
                                                            <Form.Group className="form-group">
                                                                <div className="input-group basic-channel-field1">
                                                                    <Form.Control type={this.state.confirmPasswordType} className='set-domain-name1' maxLength="20" name="confirmPwd" value={this.state.confirmPassword} onChange={this.handleConfirmPassword.bind(this)} placeholder="Repeat Password" />
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text set-domain-name2" id="inputGroupPrepend3" onClick={this.showConfirmPassword} style={{ backgroundColor: '#141414', border: '1px solid #404043', color: '#fff' }}>
                                                                            <i className={this.state.confirmPasswordType === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Form.Group>
                                                        </Col> */}
                          </Row>
                        </div>
                        <Button
                          type="button"
                          name="next"
                          variant="primary next action-button float-right"
                          value="Next"
                          onClick={() => this.updateShow("Channel")}
                        >
                          Next
                        </Button>
                        <Button
                          type="button"
                          name="previous"
                          variant="dark previous action-button-previous float-right mr-3"
                          value="Previous"
                          onClick={() => this.updateShow("Domain")}
                        >
                          Previous
                        </Button>
                      </fieldset>
                      <fieldset
                        className={`${this.state.showChannel === "Active"
                            ? "d-block"
                            : "d-none"
                          }`}
                      >
                        <div className="form-card text-left">
                          {/* <Row>
                                                            <div className="col-7">
                                                                <h4 className="mb-4">Select Your Channel Type</h4>
                                                            </div>
                                                            <div className="col-5">
                                                                <h2 className="steps">Step 3 - 5</h2>
                                                            </div>
                                                        </Row> */}
                          <div className="select-channel">
                            Select Your Channel Type
                          </div>
                          <Row>
                            <Col md="6">
                              <Form.Group>
                                {/* <Form.Label htmlFor="exampleFormControlSelect1">Channel Type</Form.Label> */}
                                <select
                                  className="form-control select-channel-type"
                                  value={this.state.channelTypeId}
                                  id="exampleFormControlSelect3"
                                  onChange={this.handleSelectChaneelType.bind(
                                    this
                                  )}
                                  style={{
                                    backgroundColor: "#141414",
                                    color: "#fff",
                                    border: "1px solid #404043",
                                    fontSize: "16px",
                                  }}
                                >
                                  <option value="-1">
                                    - Select Channel Type -
                                  </option>
                                  {this.state.channelTypeMap.map(
                                    (item, key) => {
                                      return (
                                        <option
                                          key={key}
                                          value={item.key}
                                          name={item.value}
                                        >
                                          {item.value}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </Form.Group>
                            </Col>
                            {/* <Col md="1"></Col> */}
                            <Col md="6">
                              <Form.Group>
                                {/* <Form.Label htmlFor="exampleFormControlSelect2">Channel Sub Type</Form.Label> */}
                                <select
                                  className="form-control select-channel-subtype"
                                  id="exampleFormControlSelectSubType"
                                  value={this.state.channelSubTypeId}
                                  onChange={this.handleSelectChaneelSubType.bind(
                                    this
                                  )}
                                  style={{
                                    backgroundColor: "#141414",
                                    color: "#fff",
                                    border: "1px solid #404043",
                                    fontSize: "16px",
                                  }}
                                >
                                  <option value="-1">
                                    - Select Channel Sub Type -
                                  </option>
                                  {this.state.channelSubTypeMap.map(
                                    (item, key) => {
                                      return (
                                        <option
                                          key={key}
                                          value={item.key}
                                          name={item.value}
                                        >
                                          {item.value}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                        <Button
                          type="button"
                          name="next"
                          variant="btn btn-primary next action-button float-right"
                          value="Next"
                          onClick={() => this.updateShow("Finish")}
                        >
                          Next
                        </Button>
                        <Button
                          type="button"
                          name="previous"
                          variant="btn btn-dark previous action-button-previous float-right mr-3"
                          value="Previous"
                          onClick={() => this.updateShow("Personal")}
                        >
                          Previous
                        </Button>
                      </fieldset>
                      <fieldset
                        className={`${this.state.showPlan === "Active"
                            ? "d-block"
                            : "d-none"
                          }`}
                      >
                        <div className="form-card text-left">
                          {/* <Row>
                                                        <div className="col-7">
                                                            <h4 className="mb-4 text-left">Choose Your Plan</h4>
                                                        </div>
                                                        <div className="col-5">
                                                            <h2 className="steps">Step 4 - 5</h2>
                                                        </div>
                                                    </Row> */}

                          <div className="choose-domain">
                            <p>Choose Your Plan</p>
                          </div>
                          <Row className="choose-plan">
                            <Col xl="6" lg="6" xs="7" md="6">
                              <div className="custom-control custom-radio custom-radio-color-checked custom-control-inline">
                                {this.state.subscriptionType === "Monthly" ? (
                                  <Form.Check.Input
                                    type="radio"
                                    id="customRadio-3"
                                    name="customRadio-9"
                                    className="custom-control-input bg-primary"
                                    onChange={this.updateSubscriptionType.bind(
                                      this,
                                      "Monthly"
                                    )}
                                    defaultChecked
                                  />
                                ) : (
                                  <Form.Check.Input
                                    type="radio"
                                    id="customRadio-3"
                                    name="customRadio-9"
                                    className="custom-control-input bg-primary"
                                    onChange={this.updateSubscriptionType.bind(
                                      this,
                                      "Monthly"
                                    )}
                                  />
                                )}
                                <Form.Check.Label
                                  className="custom-control-label channel-label"
                                  htmlFor="customRadio-3"
                                >
                                  {" "}
                                  Monthly{" "}
                                </Form.Check.Label>
                              </div>
                            </Col>
                            <Col xl="6" lg="6" xs="5" md="6">
                              <div className="custom-control custom-radio custom-radio-color-checked custom-control-inline">
                                {this.state.subscriptionType === "Yearly" ? (
                                  <Form.Check.Input
                                    type="radio"
                                    id="customRadio-4"
                                    name="customRadio-9"
                                    className="custom-control-input bg-success"
                                    defaultChecked
                                    onChange={this.updateSubscriptionType.bind(
                                      this,
                                      "Yearly"
                                    )}
                                  />
                                ) : (
                                  <Form.Check.Input
                                    type="radio"
                                    id="customRadio-4"
                                    name="customRadio-9"
                                    className="custom-control-input bg-success"
                                    onChange={this.updateSubscriptionType.bind(
                                      this,
                                      "Yearly"
                                    )}
                                  />
                                )}
                                <Form.Check.Label
                                  className="custom-control-label channel-label"
                                  htmlFor="customRadio-4"
                                >
                                  {" "}
                                  Yearly{" "}
                                </Form.Check.Label>
                              </div>
                            </Col>
                          </Row>
                          <Row style={{ paddingTop: "20px" }}>
                            {this.state.productListMap.map((item2, key2) => {
                              return (
                                <Col key={key2} lg="6">
                                  <Form.Group>
                                    {/* <Form.Label htmlFor="exampleFormControlSelect5">{item2.product}</Form.Label> */}
                                    <select
                                      className="form-control select-channel-type"
                                      defaultValue=""
                                      onChange={this.handleProduct.bind(
                                        this,
                                        item2.productId,
                                        item2.product,
                                        item2.qtyPricing
                                      )}
                                      style={{
                                        backgroundColor: "#141414",
                                        color: "#fff",
                                        border: "1px solid #404043",
                                        fontSize: "16px",
                                      }}
                                    >
                                      <option value="-1">
                                        - Select {item2.product} -
                                      </option>
                                      {item2.qtyPricing.map((item, key) => {
                                        return (
                                          <option
                                            key={key}
                                            value={item.qtyPricingId}
                                          >
                                            $
                                            {this.state.subscriptionType ===
                                              "Yearly"
                                              ? item.yearlyPrice
                                              : item.qtyPrice}{" "}
                                            - {item.qtyCeiling}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </Form.Group>
                                </Col>
                              );
                            })}
                          </Row>
                        </div>
                        <Button
                          type="button"
                          name="next"
                          variant="btn btn-primary next action-button float-right"
                          value="Submit"
                          onClick={() => this.updateShow("Finish")}
                        >
                          Next
                        </Button>
                        <Button
                          type="button"
                          name="previous"
                          variant="btn btn-dark previous action-button-previous float-right mr-3"
                          value="Channel"
                          onClick={() => this.updateShow("Channel")}
                        >
                          Previous
                        </Button>
                      </fieldset>
                      <fieldset
                        className={`${this.state.showFinish === "Active"
                            ? "d-block"
                            : "d-none"
                          }`}
                      >
                        <div
                          className="form-card text-left"
                          style={{ paddingBottom: "10px" }}
                        >
                          {/* <Row>
                                                        <div className="col-7">
                                                            <h4 className="mb-4 text-left">Review and Submit</h4>
                                                        </div>
                                                        <div className="col-5">
                                                            <h2 className="steps">Step 5 - 5</h2>
                                                        </div>
                                                    </Row> */}
                          <div className="choose-domain">
                            <p>Review</p>
                          </div>
                          <div
                            className="sign-user_card"
                            style={{ padding: "10px" }}
                          >
                            <h5 className="mb-3 pb-3 a-border">
                              Step 1 - Domain
                            </h5>
                            <Row className="row align-items-center justify-content-between mb-3">
                              <Col xl="12" lg="12" sm="12" md="12">
                                <span className="text-light font-size-16">
                                  {this.state.isDomain === "1"
                                    ? "Domain"
                                    : "Sub Domain"}
                                </span>
                                <p className="mb-0">
                                  {this.state.domainName}
                                  {this.state.isDomain === "1" ? ".tv" : ".tv"}
                                </p>
                              </Col>
                            </Row>
                            <h5 className="mb-3 pb-3 a-border">
                              Step 2 - Personal
                            </h5>
                            <Row className="row align-items-center justify-content-between mb-3">
                              <Col xl="12" lg="12" sm="12" md="12">
                                <span className="text-light font-size-16">
                                  Name
                                </span>
                                <p className="mb-0">
                                  {this.state.firstName} {this.state.lastName}
                                </p>
                              </Col>
                            </Row>
                            <Row className="row align-items-center justify-content-between mb-3">
                              <Col xl="12" lg="12" sm="12" md="12">
                                <span className="text-light font-size-16">
                                  Email
                                </span>
                                <p className="mb-0">{this.state.email}</p>
                              </Col>
                            </Row>
                            {/* <Row className="row align-items-center justify-content-between mb-3">
                                                            <Col xl="12" lg="12" sm="12" md="12">
                                                                <span className="text-light font-size-16">Password</span>
                                                                <p className="mb-0">**********</p>
                                                            </Col>
                                                        </Row> */}
                            <Row className="row align-items-center justify-content-between mb-3">
                              <Col xl="12" lg="12" sm="12" md="12">
                                <span className="text-light font-size-16">
                                  Phone
                                </span>
                                <p className="mb-0">{this.state.phone}</p>
                              </Col>
                            </Row>
                            {this.state.channelName !== "" ? (
                              <Row className="row align-items-center justify-content-between mb-3">
                                <Col xl="12" lg="12" sm="12" md="12">
                                  <span className="text-light font-size-16">
                                    Channel Name
                                  </span>
                                  <p className="mb-0">
                                    {this.state.channelName}
                                  </p>
                                </Col>
                              </Row>
                            ) : (
                              ""
                            )}
                            <h5 className="mb-3 pb-3 a-border">
                              Step 3 - Channel
                            </h5>
                            <Row className="row align-items-center justify-content-between mb-3">
                              <Col xl="12" lg="12" sm="12" md="12">
                                <span className="text-light font-size-16">
                                  Channel Type
                                </span>
                                <p className="mb-0">
                                  {this.state.channelTypeName}
                                </p>
                              </Col>
                            </Row>
                            {this.state.channelSubTypeId !== "-1" ? (
                              <Row className="row align-items-center justify-content-between mb-3">
                                <Col xl="12" lg="12" sm="12" md="12">
                                  <span className="text-light font-size-16">
                                    Channel Sub Type
                                  </span>
                                  <p className="mb-0">
                                    {this.state.channelSubTypeName}
                                  </p>
                                </Col>
                              </Row>
                            ) : (
                              ""
                            )}
                            {/* <h5 className="mb-3 pb-3 a-border">Step 4 - Plan</h5>
                                                        <Row className="row align-items-center justify-content-between mb-3">
                                                            <Col xl="12" lg="12" sm="12" md="12">
                                                                <span className="text-light font-size-16">Subscription Type</span>
                                                                <p className="mb-0">{this.state.subscriptionType}</p>
                                                            </Col>
                                                        </Row>
                                                        {this.state.finalProductList.map((item, key) => {
                                                            return (
                                                                <Row key={key} className="row align-items-center justify-content-between mb-3">
                                                                    <Col xl="12" lg="12" sm="12" md="12" key={key}>
                                                                        <span className="text-light font-size-16">{item.productName}</span>
                                                                        <p className="mb-0">${this.state.subscriptionType === 'Monthly' ? item.qtyPrice : item.yearlyPrice} - {item.qtyCeiling}</p>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })} */}
                          </div>
                        </div>
                        <Button
                          type="button"
                          name="next"
                          variant="btn btn-primary next action-button float-right"
                          value="Submit"
                          onClick={() => this.updateShow("Save")}
                        >
                          Submit Your Application
                        </Button>
                        <Button
                          type="button"
                          name="previous"
                          variant="btn btn-dark previous action-button-previous float-right mr-3"
                          value="Channel"
                          onClick={() => this.updateShow("Channel")}
                        >
                          Previous
                        </Button>
                      </fieldset>

                      <div className="footer-channel-text">
                        <p>
                          By creating your channel you agree to our{" "}
                          <Link target="_blank" to="/terms-of-use">
                            <span>Terms of Service</span>
                          </Link>{" "}
                          and{" "}
                          <Link target="_blank" to="/privacy-policy">
                            <span>Privacy Policy</span>
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
        {/* </Container> */}
      </section>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelRegistration);
