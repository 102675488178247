import React from "react"
import Form from "react-bootstrap/Form"
import { connect } from "react-redux"
import { ReactComponent as Purchasesearch } from "../../assets/images/purchase-search.svg"
import { ReactComponent as Purchaseright } from "../../assets/images/purchase-right.svg"
import { ReactComponent as Purchasefilter } from "../../assets/images/purchase-filter.svg"
import { selectPurchaseSeriesListStatus } from "../../store/PurchaseSeriesList/PurchaseSeriesList.selectors"
import { purchaseSeriesListStart } from "../../store/PurchaseSeriesList/PurchaseSeriesList.actions"
import { bindActionCreators } from "redux"
import moment from "moment"
import { NotificationManager } from "react-notifications"
import { secondsToHourse } from "../../helpers/core.helper"
import Video from "../video/video"
import { selectEmployeeData } from "../../store/home/home.selectors"

const mapStateToProps = (state) => {
  return {
    selectPurchaseSeriesListStatus: selectPurchaseSeriesListStatus(state),
    employeeData: selectEmployeeData(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      purchaseSeriesListStart,
    },
    dispatch
  ),
})

class PurchasesDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      seriesEpisodesList: null,
      startTime: "",
      endTime: "",
      isShowVideo: false,
      episodeObject: null,
      seasonNumber: null,
      episodNumber: null,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    if (!this.props.selectPurchaseSeriesListStatus.seriesList) {
      this.props.purchaseSeriesListStart()
    } else {
      this.setState({
        seriesEpisodesList:
          this.props.selectPurchaseSeriesListStatus.seriesList.find(
            (i) => i.seriesId.toString() === this.props.match.params.seriesId
          ),
      })
    }
    if (this.props.employeeData === null) {
      this.props.history.push("/")
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.selectPurchaseSeriesListStatus.seriesList !== null &&
      this.props.selectPurchaseSeriesListStatus.seriesList !==
        prevProps.selectPurchaseSeriesListStatus.seriesList
    ) {
      this.setState({
        seriesEpisodesList:
          this.props.selectPurchaseSeriesListStatus.seriesList.find(
            (i) => i.seriesId.toString() === this.props.match.params.seriesId
          ),
      })
    }
  }

  changeEpisode = (seasonId, episodeObject, episodNumber) => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    if (episodeObject.episodeUrl !== "") {
      this.setState({
        episodeObject,
        seasonNumber:
          this.state.seriesEpisodesList.seasons.findIndex(
            (x) => x.seasonId === seasonId
          ) + 1,
        episodNumber,
        isShowVideo: true,
      })
    } else {
      NotificationManager.error("There is no Episode link.", "", 2000)
    }
  }

  handleClose = (startTime, endTime, projectLogId) => {
    const newSeriesObject = this.state.seriesEpisodesList
    newSeriesObject.seasons[this.state.seasonNumber - 1].episodes[
      this.state.episodNumber - 1
    ].startTime = startTime.toString()
    newSeriesObject.seasons[this.state.seasonNumber - 1].episodes[
      this.state.episodNumber - 1
    ].endTime = endTime.toString()
    newSeriesObject.seasons[this.state.seasonNumber - 1].episodes[
      this.state.episodNumber - 1
    ].videoLogId = projectLogId.toString()
    this.setState({
      isShowVideo: false,
      episodeObject:
        newSeriesObject.seasons[this.state.seasonNumber - 1].episodes[
          this.state.episodNumber - 1
        ],
    })
  }

  render() {
    return this.state.isShowVideo ? (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          height: "100vh",
          width: "100vw",
          zIndex: 999999,
        }}
      >
        <Video
          videoObject={this.state.episodeObject}
          videoTitle={this.state.seriesEpisodesList.seriesName}
          videoSubtitle={`S${this.state.seasonNumber}: E${this.state.episodNumber}`}
          isMovie={0}
          goBack={this.handleClose}
        />
      </div>
    ) : (
      <div className="purchases-page">
        <div className="purchase-container">
          <div className="searchbar-products">
            <div className="purchase-text">
              <span
                onClick={() => {
                  this.props.history.goBack()
                }}
              >
                <Purchaseright className="arrow-right" />
              </span>
              <h4>Purchases</h4>
            </div>
            <div className="shows-search">
              <div className="shows-filter">
                <Purchasesearch className="search-icon" />
                <Form.Group
                  className="mb-0"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="Search"
                    className="search-field"
                    placeholder="Search"
                  />
                </Form.Group>
              </div>
              <button
                type="button"
                className="filter-btn"
              >
                <Purchasefilter className="filter-icon" />
                <span>Filter</span>
              </button>
            </div>
          </div>

          <div className="purchase-detalilspage">
            <div className="purchase-episode-list">
              <h6>Episodes</h6>
            </div>
            {this.state.seriesEpisodesList &&
              this.state.seriesEpisodesList.seasons.map((i, n) =>
                i.episodes.map((item, num) => (
                  <div
                    className="mobile-season"
                    key={`EPISODES-${n + 1}-${num}`}
                  >
                    <div className="movie-extra">
                      <div
                        className="show-extra-tab"
                        onClick={this.changeEpisode.bind(
                          this,
                          i.seasonId,
                          item,
                          item.episodeNumber
                        )}
                      >
                        <img
                          src={item.episodeImageUrl}
                          className="show-extratab-img"
                          alt=""
                        />
                      </div>
                      <div className="show-movie-tab">
                        <h4>
                          {i.seasonName} - {item.episodeName}
                        </h4>
                        <span className="show-time-details-mobile">
                          <span className="show-minute-mobile">
                            {secondsToHourse(item.episodeLength)}
                          </span>
                          <span className="show-date-mobile">
                            {/* {moment(item.publishDate, "MM/DD/YYYY").format(
                              "MMMM Do, YYYY"
                            )} */}
                            {item.publishDate}{" "}
                          </span>
                          <span className="show-subtitle-mobile">
                            Subtitles
                          </span>
                        </span>
                        <p className="show-movie-description">
                          {item.description}
                        </p>
                      </div>
                      <div className="show-subtitle">
                        <p>
                          {/* {moment(item.publishDate, "MM/DD/YYYY").format(
                            "MMMM Do, YYYY"
                          )} */}
                          {item.publishDate}{" "}
                        </p>
                        <p>{secondsToHourse(item.episodeLength)}</p>
                        <h4>Subtitles</h4>
                        <h4>Audio Languages</h4>
                      </div>
                    </div>

                    <p className="show-season-description">
                      {item.description}
                    </p>
                  </div>
                ))
              )}
            {/* <div className="mobile-season">
              <div className="movie-extra">
                <div className="show-extra-tab">
                  <img
                    src={require("../../assets/images/The-Count.png")}
                    className="show-extratab-img"
                    alt=""
                  />
                </div>
                <div className="show-movie-tab">
                  <h4>Season 1 Trailer: Title For Episode</h4>
                  <span className="show-time-details-mobile">
                    <span className="show-minute-mobile">63min</span>
                    <span className="show-date-mobile">June 10th, 2022</span>
                    <span className="show-subtitle-mobile">Subtitles</span>
                  </span>
                  <p className="show-movie-description">
                    “The Count” is a classic retelling of “The Count of Monte
                    Cristo” set against the backdrop of Las Vegas, Macau, South
                    America, and a global gaming empire.
                  </p>
                </div>
                <div className="show-subtitle">
                  <p>June 10th, 2022</p>
                  <p>63min</p>
                  <h4>Subtitles</h4>
                  <h4>Audio Languages</h4>
                </div>
              </div>

              <p className="show-season-description">
                “The Count” is a classic retelling of “The Count of Monte
                Cristo” set against the backdrop of Las Vegas, Macau, South
                America, and a global gaming empire.
              </p>
            </div>
            <div className="mobile-season">
              <div className="movie-extra">
                <div className="show-extra-tab">
                  <img
                    src={require("../../assets/images/The-Count.png")}
                    className="show-extratab-img"
                    alt=""
                  />
                </div>
                <div className="show-movie-tab">
                  <h4>Season 1 Trailer: Title For Episode</h4>
                  <span className="show-time-details-mobile">
                    <span className="show-minute-mobile">63min</span>
                    <span className="show-date-mobile">June 10th, 2022</span>
                    <span className="show-subtitle-mobile">Subtitles</span>
                  </span>
                  <p className="show-movie-description">
                    “The Count” is a classic retelling of “The Count of Monte
                    Cristo” set against the backdrop of Las Vegas, Macau, South
                    America, and a global gaming empire.
                  </p>
                </div>
                <div className="show-subtitle">
                  <p>June 10th, 2022</p>
                  <p>63min</p>
                  <h4>Subtitles</h4>
                  <h4>Audio Languages</h4>
                </div>
              </div>

              <p className="show-season-description">
                “The Count” is a classic retelling of “The Count of Monte
                Cristo” set against the backdrop of Las Vegas, Macau, South
                America, and a global gaming empire.
              </p>
            </div>
            <div className="mobile-season">
              <div className="movie-extra">
                <div className="show-extra-tab">
                  <img
                    src={require("../../assets/images/The-Count.png")}
                    className="show-extratab-img"
                    alt=""
                  />
                </div>
                <div className="show-movie-tab">
                  <h4>Season 1 Trailer: Title For Episode</h4>
                  <span className="show-time-details-mobile">
                    <span className="show-minute-mobile">63min</span>
                    <span className="show-date-mobile">June 10th, 2022</span>
                    <span className="show-subtitle-mobile">Subtitles</span>
                  </span>
                  <p className="show-movie-description">
                    “The Count” is a classic retelling of “The Count of Monte
                    Cristo” set against the backdrop of Las Vegas, Macau, South
                    America, and a global gaming empire.
                  </p>
                </div>
                <div className="show-subtitle">
                  <p>June 10th, 2022</p>
                  <p>63min</p>
                  <h4>Subtitles</h4>
                  <h4>Audio Languages</h4>
                </div>
              </div>

              <p className="show-season-description">
                “The Count” is a classic retelling of “The Count of Monte
                Cristo” set against the backdrop of Las Vegas, Macau, South
                America, and a global gaming empire.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchasesDetails)
