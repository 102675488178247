import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserService from "../../services/user/user.service";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import Backdrop from "@material-ui/core/Backdrop";
import AppConfig from "../../config/app.config";
import { Link } from "react-router-dom";
import { secondsToHourse } from "../../helpers/core.helper";
import {
  selectEmployeeData,
  selectFeatured,
  selectBrands,
  selectTvChannels,
  selectTrendingLives,
  selectPodcast,
  selectChannelCategory,
  selectNewRelease,
  selectClassic,
  selectIndependent,
} from "../../store/home/home.selectors";
import {
  brandsSyncStart,
  featuredSyncStart,
  tvChannelsSyncStart,
  trendingLivesSyncStart,
  podcastSyncStart,
  channelCategorySyncStart,
  newRealeseSyncStart,
  classicSyncStart,
  independentSyncStart,
  setSigninRedirectPath,
} from "../../store/home/home.actions";
import Error from "../Error/Error";

const mapStateToProps = (state) => {
  return {
    employeeData: selectEmployeeData(state),
    selectBrands: selectBrands(state),
    selectTvChannels: selectTvChannels(state),
    selectTrendingLives: selectTrendingLives(state),
    selectPodcast: selectPodcast(state),
    selectChannelCategory: selectChannelCategory(state),
    selectNewRelease: selectNewRelease(state),
    selectClassic: selectClassic(state),
    selectIndependent: selectIndependent(state),
    selectFeatured: selectFeatured(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      brandsSyncStart,
      tvChannelsSyncStart,
      trendingLivesSyncStart,
      podcastSyncStart,
      channelCategorySyncStart,
      newRealeseSyncStart,
      classicSyncStart,
      independentSyncStart,
      setSigninRedirectPath,
      featuredSyncStart,
    },
    dispatch
  ),
});

export class ViewAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 2,
      dataType: "",
      dataTypeName: "",
      dataTypeId: "",
      dataMap: [],
      checkingUpdate: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      brandsSyncStart,
      selectBrands,
      selectTvChannels,
      tvChannelsSyncStart,
      selectPodcast,
      podcastSyncStart,
      selectTrendingLives,
      trendingLivesSyncStart,
      selectChannelCategory,
      channelCategorySyncStart,
      newRealeseSyncStart,
      classicSyncStart,
      independentSyncStart,
      selectFeatured,
      featuredSyncStart,
    } = this.props;

    const payload = {
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      employeeId: this.props.employeeData?.employeeId,
      loginEmployeeId: this.props.employeeData?.employeeId,
      appId: AppConfig.API.APP_ID,
    };

    const pathArray = window.location.pathname.split("/");
    if (pathArray[2] !== null && pathArray[2] !== "") {
      this.setState({ dataType: pathArray[2] });
      if (pathArray[2] === "brands") {
        this.setState({ dataTypeName: "Brands" });
        if (selectBrands.initialSynced && selectBrands.brandsList !== null) {
          this.setState({ dataMap: selectBrands.brandsList });
        } else {
          brandsSyncStart(payload);
        }
      } else if (pathArray[2] === "tv-channel") {
        this.setState({ dataTypeName: "Tv Channel" });
        if (
          selectTvChannels.initialSynced &&
          selectTvChannels.tvChannelsList !== null
        ) {
          this.setState({ dataMap: selectTvChannels.tvChannelsList });
        } else {
          tvChannelsSyncStart(payload);
        }
      } else if (pathArray[2] === "podcast") {
        this.setState({ dataTypeName: "Podcast" });
        if (selectPodcast.initialSynced && selectPodcast.podcastList !== null) {
          this.setState({ dataMap: selectPodcast.podcastList });
        } else {
          podcastSyncStart(payload);
        }
      } else if (pathArray[2] === "featured-lives") {
        this.setState({ dataTypeName: "FEATURED LIVES" });
        if (
          selectTrendingLives.initialSynced &&
          selectTrendingLives.trendinglivesList !== null
        ) {
          this.setState({ dataMap: selectTrendingLives.trendinglivesList });
        } else {
          trendingLivesSyncStart(payload);
        }
      } else if (pathArray[2] === "new-releases") {
        this.setState({ dataTypeName: "NEW RELEASES" });
        newRealeseSyncStart(payload);
      } else if (pathArray[2] === "classic") {
        this.setState({ dataTypeName: "CLASSICS" });
        classicSyncStart(payload);
      } else if (pathArray[2] === "independent") {
        this.setState({ dataTypeName: "INDEPENDENT" });
        independentSyncStart(payload);
      } else if (pathArray[2] === "purchase-newreleases") {
        this.setState({ dataTypeName: "PURCHASES NEW RELEASES" });
        payload.isPurchase = 1;
        newRealeseSyncStart(payload);
      } else if (pathArray[2] === "purchase-classic") {
        this.setState({ dataTypeName: "PURCHASES CLASSICS" });
        payload.isPurchase = 1;
        classicSyncStart(payload);
      } else if (pathArray[2] === "purchase-independent") {
        this.setState({ dataTypeName: "PURCHASES INDEPENDENT" });
        payload.isPurchase = 1;
        independentSyncStart(payload);
      } else if (pathArray[2] === "featured") {
        this.setState({ dataTypeName: "FEATURED" });
        if (
          selectFeatured.initialSynced &&
          selectFeatured.featuredList !== null
        ) {
          this.setState({ dataMap: selectFeatured.featuredList });
        } else {
          featuredSyncStart(payload);
        }
      } else {
        if (
          selectChannelCategory.initialSynced &&
          selectChannelCategory.channelCategoryList !== null
        ) {
          const resultList = selectChannelCategory.channelCategoryList;
          if (resultList != null && resultList.length > 0) {
            for (let i = 0; i < resultList.length; i++) {
              const row = resultList[i];
              const var1 = row.channelCategoryId;
              const var2 = pathArray[2];
              if (var1.toString() === var2.toString()) {
                this.setState({
                  dataTypeId: row.channelCategoryId,
                  dataTypeName: row.channelCategoryName,
                  dataMap: row.channelCategoryMap,
                });
              }
            }
          } else {
            this.props.history.goBack();
          }
        } else {
          this.setState({ dataTypeId: pathArray[2] });
          channelCategorySyncStart(payload);
        }
      }

      this.interval = setInterval(() => this.setState({ isLoading: 0 }), 5000);
    } else {
      this.props.history.goBack();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      selectBrands,
      selectTvChannels,
      selectPodcast,
      selectTrendingLives,
      selectChannelCategory,
      selectNewRelease,
      selectClassic,
      selectIndependent,
      selectFeatured,
    } = this.props;

    if (this.state.dataType === "brands") {
      if (selectBrands.initialSynced) {
        if (prevProps.selectBrands.brandsList !== selectBrands.brandsList) {
          this.setState({ dataMap: selectBrands.brandsList });
        }
      }
    } else if (this.state.dataType === "tv-channel") {
      if (selectTvChannels.initialSynced) {
        if (
          prevProps.selectTvChannels.tvChannelsList !==
          selectTvChannels.tvChannelsList
        ) {
          this.setState({ dataMap: selectTvChannels.tvChannelsList });
        }
      }
    } else if (this.state.dataType === "podcast") {
      if (selectPodcast.initialSynced) {
        if (prevProps.selectPodcast.podcastList !== selectPodcast.podcastList) {
          this.setState({ dataMap: selectPodcast.podcastList });
        }
      }
    } else if (this.state.dataType === "featured-lives") {
      if (selectTrendingLives.initialSynced) {
        if (
          prevProps.selectTrendingLives.trendinglivesList !==
          selectTrendingLives.trendinglivesList
        ) {
          this.setState({ dataMap: selectTrendingLives.trendinglivesList });
        }
      }
    } else if (this.state.dataType === "new-releases") {
      if (selectNewRelease.initialSynced) {
        if (
          prevProps.selectNewRelease.newReleaseList !==
          selectNewRelease.newReleaseList
        ) {
          this.setState({ dataMap: selectNewRelease.newReleaseList });
        }
      }
    } else if (this.state.dataType === "classic") {
      if (selectClassic.classicList) {
        if (prevProps.selectClassic.classicList !== selectClassic.classicList) {
          this.setState({ dataMap: selectClassic.classicList });
        }
      }
    } else if (this.state.dataType === "independent") {
      if (selectIndependent.initialSynced) {
        if (
          prevProps.selectIndependent.independentList !==
          selectIndependent.independentList
        ) {
          this.setState({ dataMap: selectIndependent.independentList });
        }
      }
    } else if (this.state.dataType === "purchase-newreleases") {
      if (selectNewRelease.initialSynced) {
        if (
          prevProps.selectNewRelease.newReleaseList !==
          selectNewRelease.newReleaseList
        ) {
          this.setState({ dataMap: selectNewRelease.newReleaseList });
        }
      }
    } else if (this.state.dataType === "purchase-classic") {
      if (selectClassic.classicList) {
        if (prevProps.selectClassic.classicList !== selectClassic.classicList) {
          this.setState({ dataMap: selectClassic.classicList });
        }
      }
    } else if (this.state.dataType === "purchase-independent") {
      if (selectIndependent.initialSynced) {
        if (
          prevProps.selectIndependent.independentList !==
          selectIndependent.independentList
        ) {
          this.setState({ dataMap: selectIndependent.independentList });
        }
      }
    } else if (this.state.dataType === "featured") {
      if (selectFeatured.initialSynced) {
        if (
          prevProps.selectFeatured.featuredList !== selectFeatured.featuredList
        ) {
          this.setState({ dataMap: selectFeatured.featuredList });
        }
      }
    } else {
      if (selectChannelCategory.initialSynced) {
        if (
          prevProps.selectChannelCategory.channelCategoryList !==
          selectChannelCategory.channelCategoryList
        ) {
          const resultList = selectChannelCategory.channelCategoryList;
          if (resultList != null && resultList.length > 0) {
            for (let i = 0; i < resultList.length; i++) {
              const row = resultList[i];
              const var1 = row.channelCategoryId;
              const var2 = this.state.dataTypeId;
              if (var1.toString() === var2.toString()) {
                this.setState({
                  dataTypeName: row.channelCategoryName,
                  dataMap: row.channelCategoryMap,
                });
              }
            }
          }
        }
      }
    }
  }

  addToWatchList = (movieId, movieWatchListId, tagName) => {
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId != null
    ) {
      this.setState({ isLoading: 1 });
      const payload = {
        movieId: movieId,
        employeeId: this.props.employeeData?.employeeId,
        movieWatchListId: movieWatchListId,
      };

      UserService.addToWatchList(payload)
        .then((res) => {
          if (res.error != null) {
            this.setState({ isLoading: 0 });
            NotificationManager.warning(
              res.error.response.data.message,
              "",
              2000
            );
          } else {
            if (res["status"]) {
              const payloadData = {
                subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
                verticalId: AppConfig.API.VERTICAL_ID,
                employeeId: this.props.employeeData?.employeeId,
                loginEmployeeId: this.props.employeeData?.employeeId,
              };
              if (this.state.dataType === "new-releases") {
                this.props.newRealeseSyncStart(payloadData);
              } else if (this.state.dataType === "classic") {
                this.props.classicSyncStart(payloadData);
              } else if (this.state.dataType === "independent") {
                this.props.independentSyncStart(payloadData);
              } else if (this.state.dataType === "purchase-newreleases") {
                payloadData.isPurchase = 1;
                this.props.newRealeseSyncStart(payloadData);
              } else if (this.state.dataType === "purchase-classic") {
                payloadData.isPurchase = 1;
                this.props.classicSyncStart(payloadData);
              } else if (this.state.dataType === "purchase-independent") {
                payloadData.isPurchase = 1;
                this.props.independentSyncStart(payloadData);
              }

              if (movieWatchListId !== -1) {
                NotificationManager.success(
                  "Movie Removed from My Watchlist.",
                  "",
                  2000
                );
              } else {
                NotificationManager.success(
                  "Movie Added to My Watchlist.",
                  "",
                  2000
                );
              }
              this.setState({ isLoading: 0 });
            } else {
              this.setState({ isLoading: 0 });
              NotificationManager.error(res["message"], "", 2000);
            }
          }
        })
        .catch((err) => {
          this.setState({ isLoading: 0 });
          NotificationManager.error(err, "", 2000);
        });
    } else {
      this.props.setSigninRedirectPath({
        signinRedirectPath: window.location.pathname,
      });
      const reqUrl = window.btoa(AppConfig.API.DOMAIN_URL + window.location.pathname);
      const loginDomainUrl = AppConfig.API.LOGIN_DOMAIN_URL;
      window.location.href = `${loginDomainUrl}/sign-in?reqUrl=${reqUrl}`;
    }
  };

  render() {
    return (
      <section
        className="iq-main-slider p-0 iq-rtl-direction"
        style={{ position: "unset" }}
        id="iq-main-slider"
      >
        {this.state.isLoading === 1 ? (
          <Backdrop style={{ zIndex: 100, color: "#003699" }} open>
            <img
              src={require("../../assets/images/Veuit-Spinner.gif")}
              style={{
                textAlign: "center",
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
              className="c-logo"
              alt="streamit"
            />
          </Backdrop>
        ) : (
          ""
        )}
        {this.state.isLoading === 2 ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: window.innerHeight - 100,
                width: "100%",
              }}
            >
              <img
                src={require("../../assets/images/Veuit-Spinner.gif")}
                style={{
                  textAlign: "center",
                  display: "block",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
                className="c-logo"
                alt="streamit"
              />
            </div>
          </>
        ) : this.state.dataMap == null || this.state.dataMap.length === 0 ? (
          <Error />
        ) : (
          <>
            {/* <div className="iq-breadcrumb-one  iq-bg-over iq-over-dark-50" style={{ backgroundImage: `url(${bannerImage})`, height: '50vh' }}>
                        </div> */}
            <div className="main-content" id="set-header-line">
              <section id="iq-favorites">
                <Container fluid>
                  <h3
                    className="text-uppercase view-all-text-movie"
                    style={{ textAlign: "center" }}
                  >
                    {this.state.dataTypeName}
                  </h3>
                  <Row
                    sm="12"
                    className="overflow-hidden"
                    style={{ paddingLeft: "60px", paddingRight: "60px" }}
                    id="viewall-row"
                  >
                    {this.state.dataMap != null &&
                      this.state.dataMap.map((item, key) => {
                        return (
                          <Col
                            lg="3"
                            sm="4"
                            md="3"
                            xl="2"
                            key={key}
                            style={{
                              paddingTop: "20px",
                              paddingBottom: "20px",
                            }}
                            id="view-all-movie"
                          >
                            <div className="swiper-slide">
                              <div className="block-images position-relative">
                                {this.state.dataType === "new-releases" ||
                                  this.state.dataType === "classic" ||
                                  this.state.dataType === "independent" ||
                                  this.state.dataType ===
                                  "purchase-newreleases" ||
                                  this.state.dataType === "purchase-classic" ||
                                  this.state.dataType ===
                                  "purchase-independent" ? (
                                  <>
                                    <div className="img-box">
                                      <img
                                        src={
                                          AppConfig.API.MANU_IMAGE +
                                          item.movieId +
                                          "&random=" +
                                          Date.now()
                                        }
                                        className="img-fluid"
                                        id="viewall-images"
                                        alt=""
                                      />
                                    </div>
                                    <div className="block-description block-social-info">
                                      <h5 className="iq-title">
                                        {item.movieName}{" "}
                                      </h5>
                                      <div className="movie-time d-flex align-items-center my-2 iq-ltr-direction">
                                        {item.rating != null &&
                                          item.rating !== "" ? (
                                          <div className="badge badge-secondary p-1 mr-2">
                                            {item.rating}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <span className="text-white">
                                          {" "}
                                          {secondsToHourse(
                                            item.movieLength
                                          )}{" "}
                                        </span>
                                      </div>
                                      <div className="hover-buttons">
                                        <Link
                                          to={`/movie-details/${item.movieId}`}
                                          role="button"
                                          className="btn iq-button2"
                                          id="d-play-btn"
                                        >
                                          <i
                                            className="fa fa-play mr-1"
                                            aria-hidden="true"
                                          ></i>
                                          Play Now
                                        </Link>
                                      </div>
                                    </div>
                                    <div
                                      className="block-social-info"
                                      style={{ height: "50px" }}
                                    >
                                      <ul className="list-inline p-0 m-0 music-play-lists">
                                        <li
                                          onClick={this.addToWatchList.bind(
                                            this,
                                            item.movieId,
                                            item.movieWatchListId
                                          )}
                                        >
                                          <span
                                            style={{
                                              background:
                                                item.movieWatchListId !== -1
                                                  ? "#40bbfe"
                                                  : "#ffffff",
                                              color:
                                                item.movieWatchListId !== -1
                                                  ? "#ffffff"
                                                  : "#40bbfe",
                                            }}
                                          >
                                            <i className="ri-add-line"></i>
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className=" block-images position-relative">
                                      <div className="img-box">
                                        <img
                                          src={
                                            this.state.dataType === "tv-channel"
                                              ? AppConfig.API.PRODUCT_IMAGE +
                                              item.id +
                                              "&random=" +
                                              Date.now()
                                              : AppConfig.API.MANU_IMAGE +
                                              item.id +
                                              "&random=" +
                                              Date.now()
                                          }
                                          className="img-fluid"
                                          id="viewall-images"
                                          alt=""
                                        />
                                      </div>
                                      <Link
                                        target="_blank"
                                        to={{ pathname: item.webUrl }}
                                      >
                                        <div className="block-description block-social-info">
                                          <h6 className="iq-title">
                                            {item.name}{" "}
                                          </h6>
                                        </div>
                                      </Link>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                  </Row>
                </Container>
              </section>
            </div>
          </>
        )}
      </section>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAll);
