import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserService from "../../services/user/user.service";
import AppConfig from "../../config/app.config";
import { NotificationManager } from "react-notifications";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Modal } from "react-bootstrap";
import { selectEmployeeData } from "../../store/home/home.selectors";

const mapStateToProps = (state) => {
  return {
    employeeData: selectEmployeeData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class AboutModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 1,
      employeeData: null,

      swiper: null,
      images: null,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.employeeData?.employeeId != null) {
      await this.getImages();
      this.setState({ isLoading: 0 });
      document.addEventListener("keydown", this.keyPressListener);
    }
  }

  keyPressListener = (ev) => {
    if (this.state.swiper != null) {
      if (ev.key === "ArrowRight") {
        this.state.swiper.slideNext();
      } else if (ev.key === "ArrowLeft") {
        this.state.swiper.slidePrev();
      }
    }
  };

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyPressListener);
  }

  getImages = async () => {
    try {
      const payload = {
        subscriptionId: AppConfig.API.CIRIGHT_WORLD_SUBSCRIPTION_ID,
        verticalId: AppConfig.API.CIRIGHT_WORLD_VERTICAL_ID,
        url: AppConfig.API.ABOUT_DECK_URL,
      };
      const res = await UserService.getDeckImages(payload);
      if (res.error != null) {
        if (res.error?.response?.data?.message != null) {
          NotificationManager.error("", res.error.response.data.message, 3000);
        } else if (res.error.message != null) {
          NotificationManager.error("", "Could not get Sponsor Images.", 3000);
        }
      } else {
        if (res["status"]) {
          const data = res["data"]?.deckSlides;
          if (data == null) {
            NotificationManager.error(
              "",
              "Could not get Sponsor Images.",
              3000
            );
          } else {
            this.setState({ images: data });
          }
        } else {
          NotificationManager.error("", "Could not get Sponsor Images.", 3000);
        }
      }
    } catch (err) {
      NotificationManager.error("", "Could not get Sponsor Images.", 3000);
    }
  };

  render() {
    return (
      <Modal
        show={this.props.visible}
        centered
        dialogClassName="modal-fullscreen"
        id="app-modal"
      >
        <div id="modal-content-wrapper">
          <Modal.Header closeButton onHide={this.props.close} backdrop="static">
            <p style={{ color: "#fff", margin: 0 }}>ABOUT</p>
          </Modal.Header>
          <Modal.Body id="main-screen-modal">
            {this.state.images != null && (
              <>
                <div
                  id="prevHome"
                  className="swiper-button swiper-button-prev"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    if (this.state.swiper != null) {
                      this.state.swiper.slidePrev();
                    }
                  }}
                >
                  <i className="fa fa-chevron-left d-icon"></i>
                </div>
                <div
                  id="nextHome"
                  className="swiper-button swiper-button-next"
                  onClick={() => {
                    if (this.state.swiper != null) {
                      this.state.swiper.slideNext();
                    }
                  }}
                >
                  <i className="fa fa-chevron-right d-icon"></i>
                </div>

                <Swiper
                  onSwiper={(swiper) => this.setState({ swiper: swiper })}
                  loop={this.state.images.length > 1 ? true : false}
                  className="slider m-0 p-0"
                >
                  {this.state.images.map((item, key) => {
                    return (
                      <SwiperSlide
                        className="slide slick-bg s-bg-1"
                        as="li"
                        key={key}
                      >
                        <img
                          src={item.slideUrl}
                          alt="Sponsor Product"
                          className="about-sliderimg"
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </>
            )}
            {this.state.isLoading === 1 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: window.innerHeight,
                  width: "100%",
                }}
              >
                <img
                  src={require("../../assets/images/Veuit-Spinner.gif")}
                  style={{
                    textAlign: "center",
                    display: "block",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                  }}
                  className="c-logo"
                  alt="streamit"
                />
              </div>
            ) : (
              ""
            )}
          </Modal.Body>
        </div>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutModal);
