import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import "react-notifications/lib/notifications.css";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as Billingvec } from "../../assets/images/billing-vec.svg";
//import { ReactComponent as Placeorder } from "../../assets/images/place-order.svg";
import { selectEmployeeData } from "../../store/home/home.selectors";
import { ReactComponent as Vlinkqr1 } from "../../assets/images/sampleqr.svg";
import { ReactComponent as Scanqr } from "../../assets/images/scanqr-icon.svg";

const mapStateToProps = (state) => {
  return { employeeData: selectEmployeeData(state) };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

class VlinkQrmodal extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   isAddAddressModalVisible: false,
    // };
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.hideModal}
        backdrop="static"
        keyboard={false}
        centered
        id="vlink-qr-modal"
      >
        <div id="vlink-modal">
          <div className="order-confirmation">
            <Modal.Header closeButton></Modal.Header>
            <div className="vlink-userqrset">
              <div className="sample-bg">
                <img
                  src={require("../../assets/images/sample-qr.png")}
                  alt="vectorimg"
                  className="qrimg"
                />
                <h4>Sample User </h4>
                <p>{">"} sample.user</p>
              </div>
              <div className="scanqr-text">
                <Scanqr />
                <p>Scan QR Code</p>
              </div>
              <div className="shares">
                <button className="share-btn">Share</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VlinkQrmodal);
