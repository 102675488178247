import React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from 'react-router-dom'
import AppConfig from '../../config/app.config';

const mapStateToProps = (state) => {
    return {
    };
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
        },
        dispatch
    )
})



export class TvChanne extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            resultObject: props.resultObject,
            id: props.id,
            image: AppConfig.API.PRODUCT_IMAGE + props.id + "&random=" + Date.now(),
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className=" block-images position-relative">
                <div className="img-box">
                    <img src={this.state.image} className="img-fluid d-tvchannel-img" alt="" />
                </div>
                <Link target="_blank" to={{ pathname: this.state.resultObject.webUrl }} >
                    <div className="block-description block-social-info">
                        <h6 className="iq-title">{this.state.resultObject.name} </h6>
                    </div>
                </Link>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TvChanne)