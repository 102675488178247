import React from "react";
import { Form, Modal, Button } from "react-bootstrap";
import "react-notifications/lib/notifications.css";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as VeuitLogo } from "../../assets/images/veuit-logo.svg";
import { ReactComponent as Close } from "../../assets/images/channel-close.svg";
import { ReactComponent as Plus } from "../../assets/images/plus-vec.svg";
import { ReactComponent as Billing } from "../../assets/images/billing.svg";
import { ReactComponent as Info } from "../../assets/images/info.svg";
import { ReactComponent as Addarrow } from "../../assets/images/add-arrow.svg";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import PaymentCardModal from "./payment-card.component";
import PaymentAddressModal from "./payment-address.component";
import UserService from "../../services/user/user.service";
import AppConfig from "../../config/app.config";
import Backdrop from "@material-ui/core/Backdrop";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { selectEmployeeData } from "../../store/home/home.selectors";
import { setSigninRedirectPath } from "../../store/home/home.actions";

const mapStateToProps = (state) => {
  return {
    employeeData: selectEmployeeData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ setSigninRedirectPath }, dispatch),
});

class PaymentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPaymentCardModalVisible: false,
      isPaymentAddressModalVisible: false,
      isEditAddressModalVisible: false,
      employeeId: null,
      isLoading: 1,
      billingAddressmap: [],
      defaultBillingAddress: null,
      cardList: [],
      defaultCard: null,
      cardToken: null,
      paymentInfo: {},
      buyPayload: this.props.buyPayload,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId !== null
    ) {
      this.setState({
        employeeId: this.props.employeeData?.employeeId,
        employeeEmail:
          this.props.employeeData?.email != null
            ? this.props.employeeData?.email
            : "",

        buyPayload: this.props.buyPayload,
      });
      await this.getBillingAddressList(this.props.employeeData?.employeeId);
      await this.getCardList(this.props.employeeData?.employeeId);
      //await this.getBluesnapPfToken(false);
    } else {
      this.props.history.goBack();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const isModelLoading = this.props.isModelLoading;
    if (isModelLoading !== prevProps.isModelLoading) {
      this.setState({ isLoading: this.props.isModelLoading });
    }
  }

  getBillingAddressList = (employeeId) => {
    this.setState({
      isLoading: 1,
    });
    const paload = {
      employeeId: employeeId,
      appId: AppConfig.API.APP_ID,
    };
    UserService.getBillingAddress(paload)
      .then((res) => {
        if (res.error != null) {
          this.setState({
            billingAddressmap: null,
            defaultBillingAddress: null,
            isLoading: 0,
          });
        } else {
          if (res["status"] && res["data"] != null && res["data"].length >= 0) {
            var defaultBillingAddress = null;
            for (let i = 0; i < res["data"].length; i++) {
              if (res["data"][i].isDefault === 1) {
                defaultBillingAddress = res["data"][i];
              }
            }
            this.setState({
              billingAddressmap: res["data"],
              isLoading: 0,
              defaultBillingAddress: defaultBillingAddress,
            });
          } else {
            this.setState({
              billingAddressmap: null,
              defaultBillingAddress: null,
              isLoading: 0,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({
          billingAddressmap: null,
          defaultBillingAddress: null,
          isLoading: 0,
        });
      });
  };

  getCardList = (employeeId) => {
    this.setState({
      isLoading: 1,
    });
    const paload = {
      loginEmployeeId: employeeId,
      appId: AppConfig.API.APP_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      networkId: AppConfig.API.SUBSCRIPTION_ID,
    };
    UserService.getCard(paload)
      .then((res) => {
        if (res.error != null) {
          this.setState({
            cardList: null,
            defaultCard: null,
            isLoading: 0,
          });
        } else {
          if (res["status"] && res["data"] != null && res["data"].length >= 0) {
            var defaultCard = null;
            for (let i = 0; i < res["data"].length; i++) {
              if (res["data"][i].isDefaultCard === 1) {
                defaultCard = res["data"][i];
                this.setDefaultCard(res["data"][i]);
              }
            }
            this.setState({
              cardList: res["data"],
              isLoading: 0,
              defaultCard: defaultCard,
            });
          } else {
            this.setState({
              cardList: null,
              defaultCard: null,
              isLoading: 0,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({
          cardList: null,
          defaultCard: null,
          isLoading: 0,
        });
      });
  };

  getBluesnapPfToken = (isModelVisible) => {
    this.setState({
      isLoading: 1,
    });
    UserService.getBluesnapPfToken({
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.APP_ID,
    })
      .then((res) => {
        if (res.error != null) {
          this.setState({
            pfToken: null,
            isLoading: 0,
          });
        } else {
          if (res["status"] && res["data"] != null) {
            this.setState({
              pfToken: res["data"].pfToken,
              isLoading: 0,
              isPaymentCardModalVisible: isModelVisible,
            });
          } else {
            this.setState({
              pfToken: null,
              isLoading: 0,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({
          pfToken: null,
          isLoading: 0,
        });
      });
  };

  openCardModel = () => {
    // this.getBluesnapPfToken(true);

    // const paymentInfo = {
    //   returnUrl: "https://veuit.com/",
    //   orderTotalAmount: "2.0",
    //   clientSecret:
    //     "pi_3PYRm9AefB1nwhbJ0u5cTL5U_secret_Nqz0glTL014q9DJpkaK759nbz",
    //   clientPublicKey: "pk_test_gKUsj5T8Y3iUWCps96yYk22g",
    //   paymentIntentId: "pi_3PYRm9AefB1nwhbJ0u5cTL5U",
    //   orderId: "2774983",
    // };
    // this.setState({
    //   paymentInfo: paymentInfo,
    //   isLoading: 0,
    //   isPaymentCardModalVisible: true,
    // });
    this.buyMovie();
  };

  buyMovie = () => {
    if (this.state.defaultBillingAddress === null) {
      NotificationManager.error(
        "Please select your billing address.",
        "",
        2000
      );
    } else if (this.state.defaultBillingAddress.addressId === null) {
      NotificationManager.error(
        "Please select your billing address.",
        "",
        2000
      );
    } else {
      this.setState({ isLoading: 1 });
      const payload = this.state.buyPayload;
      payload.addressId = this.state.defaultBillingAddress.addressId;

      UserService.buyMovieOrSeries(payload)
        .then((res) => {
          if (res.error != null) {
            this.setState({ isLoading: 0 });
            NotificationManager.warning(
              res.error.response.data.message,
              "",
              2000
            );
          } else {
            if (res["status"]) {
              const data = res["data"];

              if (
                data.paymentGatewayInfo &&
                data.paymentGatewayInfo.url !== null &&
                data.paymentGatewayInfo.url !== undefined
              ) {
                const paymentInfo = {
                  returnUrl: data.paymentGatewayInfo.url,
                  clientSecret: data.paymentGatewayInfo.clientSecret,
                  orderTotalAmount: data.paymentGatewayInfo.orderTotalAmount,
                  clientPublicKey: data.paymentGatewayInfo.clientPublicKey,
                  paymentIntentId: data.paymentGatewayInfo.paymentIntentId,
                  orderId: data.orderId,
                };
                this.props.setSigninRedirectPath({
                  signinRedirectPath: window.location.pathname,
                });
                this.setState({
                  paymentInfo: paymentInfo,
                  isLoading: 0,
                  isPaymentCardModalVisible: true,
                });
              }
            } else {
              this.setState({ isLoading: 0 });
              NotificationManager.error(res["message"], "", 2000);
            }
          }
        })
        .catch((err) => {
          this.setState({ isLoading: 0 });
          NotificationManager.error(err, "", 2000);
        });
    }
  };

  updateBillingAddress = () => {
    this.getBillingAddressList(this.state.employeeId);
  };

  openBillingAddress = () => {
    this.setState({
      isPaymentAddressModalVisible: true,
    });
  };

  placeYourOrder = () => {
    if (
      (this.state.defaultCard === null ||
        this.state.defaultCard.projectId === null) &&
      this.state.cardToken === null
    ) {
      NotificationManager.error("Please select your card.", "", 2000);
    } else if (this.state.defaultBillingAddress === null) {
      NotificationManager.error(
        "Please select your billing address.",
        "",
        2000
      );
    } else if (this.state.defaultBillingAddress.addressId === null) {
      NotificationManager.error(
        "Please select your billing address.",
        "",
        2000
      );
    } else {
      this.props.placeYourOrder(
        this.state.defaultCard != null &&
          this.state.defaultCard.projectId != null
          ? this.state.defaultCard.projectId
          : null
      );
    }
  };

  hideCardModel = () => {
    this.setState({
      isPaymentCardModalVisible: false,
    });
    this.props.setSigninRedirectPath({
      signinRedirectPath: null,
    });
    this.getCardList(this.state.employeeId);
  };

  setDefaultCard = (item) => {
    let billingAddress = null;
    const index = this.state.billingAddressmap.findIndex(
      (x) => x.addressId === parseInt(item.billingAddressId)
    );

    if (index !== -1) {
      billingAddress = this.state.billingAddressmap[index];
    }

    this.setState({
      defaultCard: item,
      defaultBillingAddress: billingAddress,
    });
  };

  dontSaveCardCallBack = (cardToken) => {
    this.setState({
      cardToken: cardToken,
      isPaymentCardModalVisible: false,
    });
  };

  render() {
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.props.hideModal}
          backdrop="static"
          keyboard={false}
          centered
          id="payment-modal-new"
        >
          {this.state.isLoading === 1 ? (
            <Backdrop style={{ zIndex: 100, color: "#003699" }} open>
              <img
                src={require("../../assets/images/Veuit-Spinner.gif")}
                style={{
                  textAlign: "center",
                  display: "block",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
                className="c-logo"
                alt="streamit"
              />
            </Backdrop>
          ) : (
            ""
          )}
          <div id="set-sign-in1">
            <div className="align-self-center" id="create-channels">
              <div className="new-channel-registration">
                <div className="channel-set-center">
                  <Card>
                    <Card.Body>
                      <div className="channel-logos" id="channel-logos">
                        <VeuitLogo className="veuit-logo" />
                        <Close
                          className="close-vector"
                          onClick={this.props.hideModal}
                        />
                      </div>

                      <Form id="form-wizard1" className="text-center">
                        <fieldset className="d-block">
                          <div className="payments-option">
                            <div className="payment-monthly">
                              <h4>Payment Information</h4>
                              <div className="payment-method">
                                <h5>Payment Method</h5>
                                <div className="pay-methodcard">
                                  <div className="payment-cards">
                                    {this.state.cardList != null &&
                                      this.state.cardList.map((item, key) => {
                                        return (
                                          <div
                                            className="payment-number"
                                            key={key}
                                            onClick={this.setDefaultCard.bind(
                                              this,
                                              item
                                            )}
                                          >
                                            <div
                                              className={
                                                this.state.defaultCard !=
                                                  null &&
                                                this.state.defaultCard.cardId !=
                                                  null &&
                                                item.cardId ===
                                                  this.state.defaultCard.cardId
                                                  ? "add-cardnumber active"
                                                  : "add-cardnumber"
                                              }
                                            >
                                              {item.paymentBrand.toLowerCase() ===
                                              "visa" ? (
                                                <img
                                                  src={require("../../assets/images/visa-img.png")}
                                                  alt="img"
                                                  className="payment-card"
                                                ></img>
                                              ) : item.paymentBrand.toLowerCase() ===
                                                "mastercard" ? (
                                                <img
                                                  src={require("../../assets/images/Mastercard.png")}
                                                  alt="img"
                                                  className="payment-card"
                                                ></img>
                                              ) : item.paymentBrand.toLowerCase() ===
                                                "jcb" ? (
                                                <img
                                                  src={require("../../assets/images/JCB.png")}
                                                  alt="img"
                                                  className="payment-card"
                                                ></img>
                                              ) : item.paymentBrand.toLowerCase() ===
                                                "discover" ? (
                                                <img
                                                  src={require("../../assets/images/Discover.png")}
                                                  alt="img"
                                                  className="payment-card"
                                                ></img>
                                              ) : item.paymentBrand.toLowerCase() ===
                                                "amex" ? (
                                                <img
                                                  src={require("../../assets/images/Amex.png")}
                                                  alt="img"
                                                  className="payment-card"
                                                ></img>
                                              ) : item.paymentBrand.toLowerCase() ===
                                                "diners" ? (
                                                <img
                                                  src={require("../../assets/images/DinersClub.png")}
                                                  alt="img"
                                                  className="payment-card"
                                                ></img>
                                              ) : (
                                                <img
                                                  src={require("../../assets/images/no-card.png")}
                                                  alt="img"
                                                  className="payment-card"
                                                ></img>
                                              )}
                                              <p>
                                                .... .... .... {item.cardNumber}
                                              </p>
                                            </div>
                                          </div>
                                        );
                                      })}

                                    <div className="new-card-add2">
                                      <div className="new-card-add1">
                                        <div
                                          className="new-card-add"
                                          onClick={this.openCardModel}
                                        >
                                          <Plus />
                                          <p>Add New Card</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="payments-monthly">
                                  <div className="billing-address">
                                    <Billing />
                                    <div className="billing-address-selected">
                                      <p>Billing Address</p>
                                      {this.state.defaultBillingAddress !=
                                      null ? (
                                        <span>
                                          {
                                            this.state.defaultBillingAddress
                                              .address1
                                          }
                                          ,{" "}
                                          {
                                            this.state.defaultBillingAddress
                                              .city
                                          }
                                          ,{" "}
                                          {
                                            this.state.defaultBillingAddress
                                              .state
                                          }
                                        </span>
                                      ) : (
                                        <span>
                                          No billing information selected
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className="test-addrow"
                                    onClick={() =>
                                      this.setState({
                                        isPaymentAddressModalVisible: true,
                                      })
                                    }
                                  >
                                    <Addarrow className="Addarrow" />
                                  </div>
                                </div>
                                <div className="payments-monthly">
                                  <div className="billing-address">
                                    <Info />
                                    <div className="billing-address-selected">
                                      <p>Method of Receipt</p>
                                      <span>{this.state.employeeEmail}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="payments-order">
                              <div className="payments-order-total">
                                <p>Order total:</p>
                                <h4>${this.props.productData.price}</h4>
                              </div>
                              <div className="payments-dataplan">
                                <p>{this.props.productData.name}:</p>
                                <h4>${this.props.productData.price}</h4>
                              </div>
                              <div className="payments-dataplan">
                                <p>Miscellaneous Charges:</p>
                                <h4>$0.00</h4>
                              </div>
                              <div className="payments-dataplan">
                                <p>Estimated tax to be collected:</p>
                                <h4>$0.00</h4>
                              </div>

                              <div className="setup-monthly">
                                {/* <div className="setup-monthly-pay">
                                  <div className="setup-monthly-autopay">
                                    <h4>
                                      Setup monthly autopay?{" "}
                                      <OverlayTrigger
                                        // show={true}
                                        placement="left"
                                        id="monthlyautopay-tooltip-web"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                          <Tooltip id="monthlyautopay-tooltip">
                                            <Tooltiparrow className="right-arrow" />
                                            Auto pay can be cancelled at any
                                            time. The amount of your monthly
                                            auto pay does not include the custom
                                            domain name charge. Custom domain
                                            name fee is charged once a year.
                                          </Tooltip>
                                        }
                                      >
                                        <span >
                                          <Ivector className="ivector" />
                                        </span>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        // show={true}
                                        placement="top"
                                        id="monthlyautopay-tooltip-mobile"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                          <Tooltip id="monthlyautopay-tooltip-mobile">
                                            <Tooltiparrowdown className="right-arrow-mobile" />
                                            Auto pay can be cancelled at any
                                            time. The amount of your monthly
                                            auto pay does not include the custom
                                            domain name charge. Custom domain
                                            name fee is charged once a year.
                                          </Tooltip>
                                        }
                                      >
                                        <span>
                                          <Ivector1 className="ivector-mobile" />
                                        </span>
                                      </OverlayTrigger>
                                    </h4>
                                    <p>Next due date: Mar 16, 2024</p>
                                  </div>
                                  <Form.Check
                                    type={"radio"}
                                    id={`check-api-${"radio"}`}
                                  >
                                    <Form.Check.Input
                                      type={"radio"}
                                      className="new-channel-radio-pay"
                                    />
                                  </Form.Check>
                                </div> 
                                <div className="setup-monthly-price">
                                  <div className="setup-monthly-autopay">
                                    <h4>Pay 6 months</h4>
                                    <p>$2,9994.00</p>
                                  </div>
                                  <Form.Check
                                    type={"radio"}
                                    id={`check-api-${"radio"}`}
                                  >
                                    <Form.Check.Input
                                      type={"radio"}
                                      className="new-channel-radio-pay"
                                    />
                                  </Form.Check>
                                </div>
                                <div className="setup-monthly-payment">
                                  <div className="setup-monthly-autopay">
                                    <h4>Pay for 12 months</h4>
                                    <p>$5,988.00</p>
                                  </div>
                                  <Form.Check
                                    type={"radio"}
                                    id={`check-api-${"radio"}`}
                                  >
                                    <Form.Check.Input
                                      type={"radio"}
                                      className="new-channel-radio-pay"
                                    />
                                  </Form.Check>
                                </div>*/}
                                <Form.Group className="form-group discout-form-group">
                                  <Form.Control
                                    type="text"
                                    maxLength="40"
                                    name="name"
                                    className="discount-code"
                                    placeholder="Enter promo or discount code"
                                  />
                                </Form.Group>
                                {((this.state.defaultCard != null &&
                                  this.state.defaultCard.projectId != null) ||
                                  this.state.cardToken) &&
                                this.state.defaultBillingAddress != null &&
                                this.state.defaultBillingAddress.addressId !=
                                  null ? (
                                  <button
                                    type="button"
                                    name="next"
                                    variant="action-button"
                                    className="place-order-btn active"
                                    onClick={this.placeYourOrder}
                                  >
                                    Place Your Order
                                  </button>
                                ) : (
                                  <></>
                                  // <button
                                  //   type="button"
                                  //   name="next"
                                  //   variant="action-button"
                                  //   className="place-order-btn "
                                  // >
                                  //   Place Your Order
                                  // </button>
                                )}

                                <p className="terms-services">
                                  By clicking the button, you agree to the{" "}
                                  <Link target="_blank" to="/terms-of-use">
                                    {" "}
                                    <span>Terms of Service</span>
                                  </Link>{" "}
                                  as well the <span>Terms of Sale.</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="new-channel-back">
                <div className="new-channel-back1">
                  <Button
                    type="button"
                    name="next"
                    variant="action-button"
                    className="new-back-btn"
                    onClick={this.props.hideModal}
                  >
                    Back
                  </Button>
                </div>
                {/* <div className="footer-channel-text">
                  <p>
                    By creating your channel you agree to our{" "}
                    <span>
                      <Link target="_blank" to="/terms-of-use">
                        {" "}
                        Terms of Service
                      </Link>
                    </span>{" "}
                    and{" "}
                    <span>
                      <Link to="/privacy-policy"> Privacy Policy </Link>
                    </span>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </Modal>

        {this.state.isPaymentCardModalVisible ? (
          <PaymentCardModal
            show={this.state.isPaymentCardModalVisible}
            hideModal={this.hideCardModel}
            billingAddressmap={this.state.billingAddressmap}
            defaultBillingAddress={this.state.defaultBillingAddress}
            openBillingAddress={this.openBillingAddress}
            pfToken={this.state.pfToken}
            dontSaveCardCallBack={this.dontSaveCardCallBack}
            employeeId={this.state.employeeId}
            paymentInfo={this.state.paymentInfo}
          />
        ) : (
          ""
        )}
        {this.state.isPaymentAddressModalVisible ? (
          <PaymentAddressModal
            show={this.state.isPaymentAddressModalVisible}
            hideModal={() =>
              this.setState({
                isPaymentAddressModalVisible: false,
              })
            }
            billingAddressmap={this.state.billingAddressmap}
            defaultBillingAddress={this.state.defaultBillingAddress}
            updateBillingAddress={this.updateBillingAddress}
            employeeId={this.state.employeeId}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal);
