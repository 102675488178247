import HomeActionTypes from "./home.types";
// Set the reducers initial state.
const INITIAL_STATE = {
  initialSynced: false,
  syncinghome: false,
  bannerList: null,
  message: null,
  tabList: null,
  newReleaseList: null,
  classicList: null,
  independentList: null,
  originalSeriesList: null,
  influencerList: null,
  comedyList: null,
  musicList: null,
  brandsList: null,
  brandsPartnersList: null,
  exclusiveeventList: null,
  featuredChannelsList: null,
  tvChannelsList: null,
  podcastList: null,
  trendinglivesList: null,
  athletesList: null,
  fineArtistsList: null,
  channelCategoryList: null,
  location: null,
  employeeData: null,
  signinRedirectPath: null,
  featuredList: null,
};

// Define scenarios for reducers.
const homeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HomeActionTypes.HOME_SYNC_START:
      return {
        ...state,
        syncinghome: true,
      };
    case HomeActionTypes.HOME_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        syncinghome: action.payload.syncinghome,
        bannerList: action.payload.bannerList,
        message: action.payload.message,
      };
    case HomeActionTypes.TAB_LIST_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.TAB_LIST_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        tabList: action.payload.tabList,
        message: action.payload.message,
      };

    case HomeActionTypes.NEW_RELEASE_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.NEW_RELEASE_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        newReleaseList: action.payload.newReleaseList,
        message: action.payload.message,
      };

    case HomeActionTypes.CLASSIC_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.CLASSIC_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        classicList: action.payload.classicList,
        message: action.payload.message,
      };

    case HomeActionTypes.INDEPENDENT_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.INDEPENDENT_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        independentList: action.payload.independentList,
        message: action.payload.message,
      };

    case HomeActionTypes.ORIGINAL_SERIES_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.ORIGINAL_SERIES_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        originalSeriesList: action.payload.originalSeriesList,
        message: action.payload.message,
      };
    case HomeActionTypes.INFLUENCER_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.INFLUENCER_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        influencerList: action.payload.influencerList,
        message: action.payload.message,
      };

    case HomeActionTypes.COMEDY_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.COMEDY_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        comedyList: action.payload.comedyList,
        message: action.payload.message,
      };
    case HomeActionTypes.MUSIC_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.MUSIC_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        musicList: action.payload.musicList,
        message: action.payload.message,
      };
    case HomeActionTypes.BRANDS_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.BRANDS_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        brandsList: action.payload.brandsList,
        message: action.payload.message,
      };
    case HomeActionTypes.BRANDS_PARTNERS_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.BRANDS_PARTNERS_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        brandsPartnersList: action.payload.brandsPartnersList,
        message: action.payload.message,
      };
    case HomeActionTypes.EXCLUSIVE_EVENT_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.EXCLUSIVE_EVENT_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        exclusiveeventList: action.payload.exclusiveeventList,
        message: action.payload.message,
      };
    case HomeActionTypes.FEATURED_CHANNELS_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.FEATURED_CHANNELS_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        featuredChannelsList: action.payload.featuredChannelsList,
        message: action.payload.message,
      };
    case HomeActionTypes.TV_CHANNELS_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.TV_CHANNELS_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        tvChannelsList: action.payload.tvChannelsList,
        message: action.payload.message,
      };
    case HomeActionTypes.PODCAST_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.PODCAST_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        podcastList: action.payload.podcastList,
        message: action.payload.message,
      };
    case HomeActionTypes.TRENDING_LIVES_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.TRENDING_LIVES_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        trendinglivesList: action.payload.trendinglivesList,
        message: action.payload.message,
      };
    case HomeActionTypes.ATHLETES_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.ATHLETES_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        athletesList: action.payload.athletesList,
        message: action.payload.message,
      };
    case HomeActionTypes.FINE_ARTISTS_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.FINE_ARTISTS_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        fineArtistsList: action.payload.fineArtistsList,
        message: action.payload.message,
      };
    case HomeActionTypes.CHANNEL_CATEGORY_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.CHANNEL_CATEGORY_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        channelCategoryList: action.payload.channelCategoryList,
        message: action.payload.message,
      };
    case HomeActionTypes.SET_LOCATION:
      return {
        ...state,
        location: action.payload.location,
      };
    case HomeActionTypes.SET_EMPLOYEE_DATA:
      return {
        ...state,
        employeeData: action.payload,
      };
    case HomeActionTypes.SET_SIGNIN_REDIRECT_PATH:
      return {
        ...state,
        signinRedirectPath: action.payload.signinRedirectPath,
      };
    case HomeActionTypes.FEATURED_SYNC_START:
      return {
        ...state,
        initialSynced: true,
      };
    case HomeActionTypes.FEATURED_SYNC_FINISH:
      return {
        ...state,
        initialSynced: action.payload.initialSynced,
        featuredList: action.payload.featuredList,
        message: action.payload.message,
      };
    default:
      return state;
  }
};

export default homeReducer;
