import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ReactComponent as VeuitLogo } from "../../assets/images/veuit-logo.svg";
import { ReactComponent as Ongoing } from "../../assets/images/Ongoing.svg";

const mapStateToProps = (state) => {
    return {
    };
}


const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
        },
        dispatch
    )
})

export class ChannelPaymentPending extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <section className="sign-in-page">
                <Container>
                    <Row className="row justify-content-center align-items-center height-self-center">
                        <Col lg="5" className="align-self-center forgot-pwd-height" id="set-sign-in">
                            <div className="sign-user_card ">
                                <div className="sign-in-page-data text-center">
                                    <div className="sign-in-from m-auto">
                                        <div className="sign-in-logo">
                                            <Link to="/"> <VeuitLogo /></Link>
                                        </div>
                                        <div className="form-bg-mobile">
                                            <Ongoing className='payment-img' />
                                            <h3 className='mt-3 mb-0' id='ongoing-color'><strong>Payment Pending</strong></h3>
                                            <p className="text-white" id='payment-msg'>
                                                {"Channel created successfully. Please contact administrator for payment."}
                                            </p>
                                            <div className="d-inline-block w-100">
                                                <Link to="/" className="btn btn-primary mt-3" id='payment-back'>Back to Home</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelPaymentPending)