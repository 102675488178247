import SignInActionTypes from './signIn.types';


export const userLogin = payload => ({
    type: SignInActionTypes.LOGIN_START,
    payload
});

export const userLoginSuccess = payload => ({
    type: SignInActionTypes.LOGIN_SUCCESS,
    payload
});

export const userLoginFailure = payload => ({
    type: SignInActionTypes.LOGIN_FAILURE,
    payload
});