import { takeLeading, put, all, call } from 'redux-saga/effects';
import SignUpActionTypes from './signUp.types';
import {
    userSignupSuccess, userSignupFailure
} from './signUp.actions';
import AppConfig from '../../config/app.config';
import UserService from '../../services/user/user.service';
import { log } from '../../helpers/core.helper';


/** performs user signup */
export function* signupUser({ payload }) {
    try {
        const signUpData = {
            name: payload.name,
            emailAddress: payload.email,
            password: payload.password,
            phone: payload.phone,
            subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
            verticalId: AppConfig.API.VERTICAL_ID,
            appId: AppConfig.API.APP_ID,
            username:payload.username
        };

        const res = yield UserService.signUp(signUpData);
        if (res.error != null) {
            yield put(userSignupFailure({ signUpSuccess: false, loading: false, err: res.error.response.data.message, employeeData:null }));
        } else {
            if (res['status']) {
                const data = res['data'];
               // localStorage.setItem('employeeId', data.employeeId);
                //localStorage.setItem('employeeData', data);
                yield put(userSignupSuccess({ signUpSuccess: true, loading: true, employeeData:data }));
            } else {
                yield put(userSignupFailure({ signUpSuccess: false, loading: false, err: res['message'], employeeData:null }));
            }
        }

    } catch (err) {
        yield put(userSignupFailure({ signUpSuccess: false, loading: false, err: err, employeeData:null }));
        log('signUp.sagas', 'signupUser', err);
    }
}


/** Saga for user signup */
export function* userSignup() {
    yield takeLeading(
        SignUpActionTypes.SIGNUP_START,
        signupUser
    )
}


/** 
 * Initialise all local sagas
 */
export function* signUpSagas() {
    yield all([
        call(userSignup),
    ])
}