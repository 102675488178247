import { Switch, Route } from "react-router-dom";

import SignUp from "../views/signUp/signup";
import SignIn from "../views/signIn/signIn";
import ForgotPassWord from "../views/forgot-password/forgot-password";
import ResetPassword from "../views/reset-password/reset-password";
import Success from "../views/success/success";
import PaymentStatus from "../views/payment/payment-status";
import Error from "../views/Error/Error";

const AuthenticationRoute = () => {
  return (
    <Switch>
      <Route path="/authentication/sign-up" component={SignUp} />
      <Route path="/authentication/sign-in" component={SignIn} />
      <Route
        path="/authentication/forgot-password"
        component={ForgotPassWord}
      />
      <Route path="/authentication/reset-password" component={ResetPassword} />
      <Route
        path="/authentication/success/:subscriptionId"
        component={Success}
      />
      <Route
        path="/authentication/payment-status/:orderId"
        component={PaymentStatus}
      />
      <Route path="*" component={Error} />
    </Switch>
  );
};

export default AuthenticationRoute;
