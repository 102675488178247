import React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {  Row, Col } from 'react-bootstrap'
import Movie from './movie.component'
import { Link } from 'react-router-dom'
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);



const mapStateToProps = (state) => {
    return {
    };
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
        },
        dispatch
    )
})



export class MovieList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            moviesMap: props.moviesMap,
            tagName: props.tagName,
            id: props.id,
            viewAllUrl: props.viewAllUrl,
            playNowUrl: props.playNowUrl,
            prevId: 'prev' + props.id,
            nextId: 'next' + props.id,
            checkingUpdate: props.checkingUpdate,
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.moviesMap !== prevProps.moviesMap) {
            this.setState({ moviesMap: this.props.moviesMap, checkingUpdate: !this.state.checkingUpdate });
        }
    }

    addToWatchList = (movieId, movieWatchListId) => {
        this.props.addToWatchList(movieId, movieWatchListId, this.state.tagName)
    }

    render() {
        return (
            <div className="d-veuitbgimg">
                <div className="main-content">
                    <section id="iq-favorites">
                    <div id="d-container" className="container-fluid">
                            <Row id="d-space-show">
                                <Col sm="12" className="overflow-hidden d-set-overflow">
                                    <div className="d-flex align-items-center justify-content-between set-view-all-text">
                                        <h3 className="main-title text-uppercase d-movie-title">{this.state.tagName}</h3>
                                        <Link className="iq-view-all d-view-text" to={this.state.viewAllUrl}>VIEW ALL</Link>
                                    </div>
                                    <div id="favorites-contens">
                                        <div id={this.state.prevId} className="swiper-button swiper-button-prev d-set-prev s-set-prev"><i className="fa fa-chevron-left d-icon"></i></div>
                                        <div id={this.state.nextId} className="swiper-button swiper-button-next d-set-next s-set-next"><i className="fa fa-chevron-right d-icon"></i></div>
                                        <Swiper
                                            navigation={{
                                                prevEl: '#' + this.state.prevId,
                                                nextEl: '#' + this.state.nextId
                                            }}
                                            breakpoints={{
                                                // 320: { slidesPerView: 2.120 },
                                                // 345: { slidesPerView: 2.280 },
                                                // 360: { slidesPerView: 2.380 },
                                                // 375: { slidesPerView: 2.475 },
                                                // 390: { slidesPerView: 2.575 },
                                                // 414: { slidesPerView: 2.726 },
                                                // 430: { slidesPerView: 2.840 },
                                                // 480: { slidesPerView: 3.160 },
                                                // 576: { slidesPerView: 3.779 },
                                                // 767: { slidesPerView: 3.160 },
                                                // 991: { slidesPerView: 4.15 },
                                                // 1280: { slidesPerView: 5.3 },
                                                // 1366: { slidesPerView: 5.8 },
                                                // 1440: { slidesPerView: 6 },
                                                // 1680: { slidesPerView: 7 },
                                                // 1920: { slidesPerView: 8 },
                                                // 2560: { slidesPerView: 9 },

                                                // 2560: {
                                                //     slidesPerView: 11, 
                                                //     spaceBetween: 20, 
                                                // },
                                                2304: {
                                                    slidesPerView: 12, 
                                                    spaceBetween: 15, 
                                                },
                                                2150: {
                                                    slidesPerView: 11, 
                                                    spaceBetween: 18, 
                                                },
                                                // 2050: {
                                                //     slidesPerView: 9, 
                                                //     spaceBetween: 18, 
                                                // },
                                                1920: {
                                                    slidesPerView: 9,
                                                    spaceBetween: 26, 
                                                },
                                                // 1800: {
                                                //     slidesPerView: 8,
                                                //     spaceBetween: 10, 
                                                // },
                                                // 1740: {
                                                //     slidesPerView: 8,
                                                //     spaceBetween: 10, 
                                                // },

                                                1680: {
                                                    slidesPerView: 9,
                                                    spaceBetween: 20, 
                                                },

                                                1536: {
                                                    slidesPerView: 8,
                                                    spaceBetween: 20, 
                                                },
                                                //  1440: {
                                                //     slidesPerView: 7,
                                                //     spaceBetween: 26, 
                                                // },
                                                1366: {
                                                    slidesPerView: 7,
                                                    spaceBetween: 26, 
                                                },
                                                1280: {
                                                    slidesPerView: 7,
                                                    spaceBetween: 10, 
                                                },
                                                1140: {
                                                    slidesPerView: 6,
                                                    spaceBetween: 10, 
                                                },
                                                1054: {
                                                    slidesPerView: 6,
                                                    spaceBetween: 8, 
                                                },
                                                840: {
                                                    slidesPerView: 5,
                                                    spaceBetween: 10,
                                                },
                                                768: {
                                                    slidesPerView: 4,
                                                    spaceBetween: 8,
                                                },
                                                767: {
                                                    slidesPerView: 5,
                                                    spaceBetween: 10, 
                                                },
                                                676: {
                                                    slidesPerView: 4.5,
                                                    spaceBetween: 10, 
                                                },
                                                577: {
                                                    slidesPerView: 3.5,
                                                    spaceBetween: 12, 
                                                },
                                                576: {
                                                    slidesPerView: 3.9,
                                                    spaceBetween: 12, 
                                                },
                                                494: {
                                                    slidesPerView: 3.9,
                                                    spaceBetween: 12, 
                                                },
                                                444: {
                                                    slidesPerView: 3.2,
                                                    spaceBetween: 12, 
                                                },
                                                430: {
                                                    slidesPerView: 2.9,
                                                    spaceBetween: 12, 
                                                },
                                                414: {
                                                    slidesPerView: 3,
                                                    spaceBetween: 12, 
                                                },
                                                376: {
                                                    slidesPerView: 2.6,
                                                    spaceBetween: 12, 
                                                },
                                                360: {
                                                    slidesPerView: 2.5,
                                                    spaceBetween: 12, 
                                                },
                                                340: {
                                                    slidesPerView: 2.4,
                                                    spaceBetween: 12, 
                                                },

                                                320: {
                                                    slidesPerView: 2.2,
                                                    spaceBetween: 12, 
                                                },
                                            }}
                                            loop={false}
                                            // slidesPerView={16}
                                            // spaceBetween={20}
                                            as="ul"
                                            className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction">

                                            {this.state.moviesMap.map((item, key) => {
                                                return (
                                                    <SwiperSlide as="li" key={key} id="d-slider">
                                                        <Movie movieObject={item} indexKey={key} addToWatchList={this.addToWatchList} checkingUpdate={this.state.checkingUpdate}
                                                            tagName={this.state.tagName} playNowUrl={this.state.playNowUrl}
                                                            id={item.movieId}
                                                        />
                                                    </SwiperSlide>
                                                )
                                            })}

                                        </Swiper>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MovieList)