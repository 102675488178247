import { Switch, Route } from "react-router-dom";
import MainLayout from "../layouts/main-layout";
import AuthenticationLayout from "../layouts/authentication-layout";
import { setLocation, setEmployeeData } from "../store/home/home.actions";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import Cookies from "js-cookie";
import { NotificationManager } from "react-notifications";
import LogoutMessage from "../components/LogoutMessage/logout-message";
import AppConfig from "../config/app.config";
import UserService from "../services/user/user.service";
import Backdrop from "@material-ui/core/Backdrop";
import { selectEmployeeData } from "../store/home/home.selectors";

const mapStateToProps = (state) => {
  return {
    employeeData: selectEmployeeData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setLocation,
      setEmployeeData,
    },
    dispatch
  ),
});

class LayoutsRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: props.location,
      isLoading: 1,
      logoutMsg: 0,
      sesstionUserToken: "",
      cookiesUserToken: "",
      isAutoLogin: 0,
    };
    this.props.setLocation({ location: this.props.location });
    this.getInterval = null;
  }

  componentDidMount() {
    this.checkForCookie();
    this.getInterval = setInterval(() => this.getSessionDetail(), 1000);

    // Cookies.set(
    //   "userToken",
    //   "426173696320636d6c75613246734c6d784159326c796157646f6443356a62323036556d6c7561324673514445794d773d3d"
    // );
    var employeeData = localStorage.getItem("employeeData");
    if (employeeData != null && employeeData !== "") {
      try {
        employeeData = JSON.parse(employeeData);
      } catch (err) {}
    }

    if (employeeData != null) {
      this.props.setEmployeeData(employeeData);
    }
  }

  componentWillUnmount() {
    clearInterval(this.getInterval);
  }

  componentDidUpdate(prevState, prevProps) {
    if (this.state.location !== this.props.location) {
      this.props.setLocation({ location: this.props.location });
      this.setState({ location: this.props.location });
      // window.location.reload();
    }
  }

  getSessionDetail = async () => {
    const cookiesUserToken = Cookies.get("userToken");
    if (cookiesUserToken !== this.state.cookiesUserToken) {
      // console.log(
      //   "cookies-----",
      //   cookiesUserToken,
      //   this.state.cookiesUserToken
      // );
      this.setState({ cookiesUserToken: cookiesUserToken });
      await this.checkForCookie();
    }
    const sesstionUserToken = localStorage.getItem("newUserToken");
    if (sesstionUserToken !== this.state.sesstionUserToken) {
      // console.log(
      //   "session---",
      //   sesstionUserToken,
      //   this.state.sesstionUserToken
      // );
      this.setState({ sesstionUserToken: sesstionUserToken });
      await this.checkForCookie();
    }

    if (
      (cookiesUserToken == null ||
        cookiesUserToken === undefined ||
        cookiesUserToken === "") &&
      (sesstionUserToken == null ||
        sesstionUserToken === undefined ||
        sesstionUserToken === "")
    ) {
      if (
        this.props.employeeData !== null &&
        this.props.employeeData?.employeeId !== null &&
        this.state.logoutMsg !== 1
      ) {
        //console.log("Employee Found but login session Not found");
        await this.logoutOnCookie();
        window.location.reload();
      }
    }
  };

  handleRedirectOnFailure = () => {
    if (
      Cookies.get("reqUrl") != null ||
      Cookies.get("reqUrl") != "" ||
      Cookies.get("reqUrl") != undefined
    ) {
      const redirectUrl = AppConfig.API.LOGIN_DOMAIN_URL;
      Cookies.remove("userToken", {
        path: "/",
        domain: AppConfig.API.DOMAIN_NAME,
      });
      this.timeoutHandle = setTimeout(() => {
        window.location.href = redirectUrl;
      }, 3000);
    } else {
      this.props.history.replace("/");
    }
  };

  checkForCookie = async () => {
    this.setState({ isLoading: 1 });
    const cookiesUserToken = Cookies.get("userToken");
    const sesstionUserToken = localStorage.getItem("newUserToken");
    if (this.state.isAutoLogin !== 1) {
      if (
        cookiesUserToken != null &&
        cookiesUserToken !== undefined &&
        cookiesUserToken !== "" &&
        sesstionUserToken != null &&
        sesstionUserToken !== undefined &&
        sesstionUserToken !== ""
      ) {
        if (cookiesUserToken !== sesstionUserToken) {
         // console.log("1---- if clear localstore and login with cokkies ");
          localStorage.clear();
          this.setState({ isAutoLogin: 1 });
          await this.autoLogin();
        } else {
          this.setState({ isLoading: 0 });
        }
      } else if (
        cookiesUserToken != null &&
        cookiesUserToken !== undefined &&
        cookiesUserToken !== "" &&
        (sesstionUserToken === null ||
          sesstionUserToken === undefined ||
          sesstionUserToken === "")
      ) {
        //console.log("2---- else if auto login with token ");
        localStorage.clear();
        this.setState({ isAutoLogin: 1 });
        await this.autoLogin();
      } else if (
        (cookiesUserToken == null ||
          cookiesUserToken === undefined ||
          cookiesUserToken === "") &&
        sesstionUserToken != null &&
        sesstionUserToken !== undefined &&
        sesstionUserToken !== ""
      ) {
      //  console.log("3---- else if log out user ");
        this.setState({ logoutMsg: 1, isLoading: 0 });
        await this.logoutOnCookie();
      } else {
        this.setState({ isLoading: 0 });
      }
    }
  };

  autoLogin = async () => {
    this.setState({ isLoading: 1 });
    const cookiesUserToken = Cookies.get("userToken");
    const payload = {
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.APP_ID,
      sphereTypeUrl: AppConfig.API.SPHERE_TYPE_URL,
      channelAppId: AppConfig.API.CHANNEL_APP_ID,
      channelSphereTypeUrl: AppConfig.API.CHANNEL_SPHERE_TYPE_URL,
      isAutoLogin: 1,
      userToken: cookiesUserToken,
    };

    try {
      const res = await UserService.signIn(payload);
      if (res.error != null) {
        NotificationManager.error(
          res?.error?.response?.data.message ?? "Something Went Wrong",
          "",
          2000
        );
        this.timeoutHandle = setTimeout(() => {
          this.setState({ isLoading: 0, isAutoLogin: 0 });
          this.handleRedirectOnFailure();
        }, 3000);
        // this.handleLogin();
      } else {
        if (res["status"]) {
          // NotificationManager.success("Login Successfull", "", 2500)
          let isLogin = false;
          let employeeData = {};
          const networkRoles = res["data"].networkRoles;
          if (networkRoles != null) {
            for (let i = 0; i < networkRoles.length; i++) {
              const row = networkRoles[i];
              if (row.sphereTypeId === 2) {
                localStorage.setItem("employeeId", row.employeeId);
                localStorage.setItem("employeeEmail", row.email);
                localStorage.setItem("employeeData", JSON.stringify(row));
                localStorage.setItem("isContract", row.isContract);
                localStorage.setItem("employeeName", row.name);
                employeeData = row;
                isLogin = true;
              }
            }
          }
          const affiliateRoles = res["data"].affiliateRoles;
          if (affiliateRoles != null) {
            for (let i = 0; i < affiliateRoles.length; i++) {
              const row = affiliateRoles[i];
              if (row.sphereTypeId === 9) {
                localStorage.setItem("affiliateUserId", row.employeeId);
                localStorage.setItem("affiliateUserData", JSON.stringify(row));
              }
            }
          }

          localStorage.setItem(
            "isChannelList",
            res["data"].subscriptions != null &&
              res["data"].subscriptions.length > 0
              ? 1
              : 0
          );
          const subscriptions = res["data"].subscriptions;
          localStorage.setItem("isSubscriptionsNull", subscriptions != null);
          localStorage.setItem("newUserToken", res["data"].newUserToken);
          localStorage.setItem("userToken", res["data"].userToken);

          if (isLogin) {
            // NotificationManager.success("User Auto Login Successfully.", "", 2000)
            this.props.setEmployeeData(employeeData);
            this.setState({ isLoading: 0, isAutoLogin: 0 });
          } else {
            NotificationManager.error("Invalid Credential.", "", 2000);
            this.timeoutHandle = setTimeout(() => {
              this.setState({ isLoading: 0, isAutoLogin: 0 });
              this.handleRedirectOnFailure();
            }, 3000);
            // this.handleLogin();
          }
        } else {
          NotificationManager.error(res["message"], "", 2000);
          this.timeoutHandle = setTimeout(() => {
            this.setState({ isLoading: 0, isAutoLogin: 0 });
            this.handleRedirectOnFailure();
          }, 3000);
          // this.handleLogin();
        }
      }
    } catch (err) {
      NotificationManager.error(err, "", 2000);
      this.timeoutHandle = setTimeout(() => {
        this.setState({ isLoading: 0, isAutoLogin: 0 });
        this.handleRedirectOnFailure();
      }, 3000);
      // this.handleLogin();
    }
  };

  // this function checks for cookie on every mount after login
  logoutOnCookie = () => {
    localStorage.clear();
    Cookies.remove("userToken", {
      path: "/",
      domain: AppConfig.API.DOMAIN_NAME,
    });
  };

  handleLogin = () => {
    // const reqUrl = window.btoa(AppConfig.API.DOMAIN_URL);
    // const loginDomainUrl = AppConfig.API.LOGIN_DOMAIN_URL;
    // window.location.href = `${loginDomainUrl}/sign-in?reqUrl=${reqUrl}`;
  };

  render() {
    return this.state.isLoading === 1 ? (
      <Backdrop style={{ zIndex: 100, color: "#003699" }} open>
        <img
          src={require("../assets/images/Veuit-Spinner.gif")}
          style={{
            textAlign: "center",
            display: "block",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
          }}
          className="c-logo"
          alt="streamit"
        />
      </Backdrop>
    ) : (
      <>
        {this.state.logoutMsg === 1 ? <LogoutMessage /> : ""}
        <Switch location={this.props.location}>
          <Route path="/authentication" component={AuthenticationLayout} />
          <Route path="/" component={MainLayout} />
        </Switch>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LayoutsRoute));
