import SignUpActionTypes from './signUp.types';


export const userSignup = payload => ({
    type: SignUpActionTypes.SIGNUP_START,
    payload
});

export const userSignupSuccess = payload => ({
    type: SignUpActionTypes.SIGNUP_SUCCESS,
    payload
});

export const userSignupFailure = payload => ({
    type: SignUpActionTypes.SIGNUP_FAILURE,
    payload
});
