import HomeActionTypes from "./home.types";

export const homeSyncStart = (payload) => ({
  type: HomeActionTypes.HOME_SYNC_START,
  payload,
});

export const homeSyncFinish = (payload) => ({
  type: HomeActionTypes.HOME_SYNC_FINISH,
  payload,
});

export const tabListSyncStart = (payload) => ({
  type: HomeActionTypes.TAB_LIST_SYNC_START,
  payload,
});

export const tabListSyncFinish = (payload) => ({
  type: HomeActionTypes.TAB_LIST_SYNC_FINISH,
  payload,
});

export const newRealeseSyncStart = (payload) => ({
  type: HomeActionTypes.NEW_RELEASE_SYNC_START,
  payload,
});

export const newRealeseSyncFinish = (payload) => ({
  type: HomeActionTypes.NEW_RELEASE_SYNC_FINISH,
  payload,
});

export const classicSyncStart = (payload) => ({
  type: HomeActionTypes.CLASSIC_SYNC_START,
  payload,
});

export const classicSyncFinish = (payload) => ({
  type: HomeActionTypes.CLASSIC_SYNC_FINISH,
  payload,
});

export const independentSyncStart = (payload) => ({
  type: HomeActionTypes.INDEPENDENT_SYNC_START,
  payload,
});

export const independentSyncFinish = (payload) => ({
  type: HomeActionTypes.INDEPENDENT_SYNC_FINISH,
  payload,
});

export const originalSeriesSyncStart = (payload) => ({
  type: HomeActionTypes.ORIGINAL_SERIES_SYNC_START,
  payload,
});

export const originalSeriesSyncFinish = (payload) => ({
  type: HomeActionTypes.ORIGINAL_SERIES_SYNC_FINISH,
  payload,
});

export const influencerSyncStart = (payload) => ({
  type: HomeActionTypes.INFLUENCER_SYNC_START,
  payload,
});

export const influencerSyncFinish = (payload) => ({
  type: HomeActionTypes.INFLUENCER_SYNC_FINISH,
  payload,
});

export const comedySyncStart = (payload) => ({
  type: HomeActionTypes.COMEDY_SYNC_START,
  payload,
});

export const comedySyncFinish = (payload) => ({
  type: HomeActionTypes.COMEDY_SYNC_FINISH,
  payload,
});

export const musicSyncStart = (payload) => ({
  type: HomeActionTypes.MUSIC_SYNC_START,
  payload,
});

export const musicSyncFinish = (payload) => ({
  type: HomeActionTypes.MUSIC_SYNC_FINISH,
  payload,
});

export const brandsSyncStart = (payload) => ({
  type: HomeActionTypes.BRANDS_SYNC_START,
  payload,
});

export const brandsSyncFinish = (payload) => ({
  type: HomeActionTypes.BRANDS_SYNC_FINISH,
  payload,
});

export const brandsPartnersSyncStart = (payload) => ({
  type: HomeActionTypes.BRANDS_PARTNERS_SYNC_START,
  payload,
});

export const brandsPartnersSyncFinish = (payload) => ({
  type: HomeActionTypes.BRANDS_PARTNERS_SYNC_FINISH,
  payload,
});

export const exclusiveEventSyncStart = (payload) => ({
  type: HomeActionTypes.EXCLUSIVE_EVENT_SYNC_START,
  payload,
});

export const exclusiveEventSyncFinish = (payload) => ({
  type: HomeActionTypes.EXCLUSIVE_EVENT_SYNC_FINISH,
  payload,
});

export const featuredChannelsSyncStart = (payload) => ({
  type: HomeActionTypes.FEATURED_CHANNELS_SYNC_START,
  payload,
});

export const featuredChannelsSyncFinish = (payload) => ({
  type: HomeActionTypes.FEATURED_CHANNELS_SYNC_FINISH,
  payload,
});

export const tvChannelsSyncStart = (payload) => ({
  type: HomeActionTypes.TV_CHANNELS_SYNC_START,
  payload,
});

export const tvChannelsSyncFinish = (payload) => ({
  type: HomeActionTypes.TV_CHANNELS_SYNC_FINISH,
  payload,
});

export const podcastSyncStart = (payload) => ({
  type: HomeActionTypes.PODCAST_SYNC_START,
  payload,
});

export const podcastSyncFinish = (payload) => ({
  type: HomeActionTypes.PODCAST_SYNC_FINISH,
  payload,
});

export const trendingLivesSyncStart = (payload) => ({
  type: HomeActionTypes.TRENDING_LIVES_SYNC_START,
  payload,
});

export const trendingLivesSyncFinish = (payload) => ({
  type: HomeActionTypes.TRENDING_LIVES_SYNC_FINISH,
  payload,
});

export const athletesSyncStart = (payload) => ({
  type: HomeActionTypes.ATHLETES_SYNC_START,
  payload,
});

export const athletesSyncFinish = (payload) => ({
  type: HomeActionTypes.ATHLETES_SYNC_FINISH,
  payload,
});

export const fineArtistsSyncStart = (payload) => ({
  type: HomeActionTypes.FINE_ARTISTS_SYNC_START,
  payload,
});

export const fineArtistsSyncFinish = (payload) => ({
  type: HomeActionTypes.FINE_ARTISTS_SYNC_FINISH,
  payload,
});

export const channelCategorySyncStart = (payload) => ({
  type: HomeActionTypes.CHANNEL_CATEGORY_SYNC_START,
  payload,
});

export const channelCategorySyncFinish = (payload) => ({
  type: HomeActionTypes.CHANNEL_CATEGORY_SYNC_FINISH,
  payload,
});

export const setSigninRedirectPath = (payload) => ({
  type: HomeActionTypes.SET_SIGNIN_REDIRECT_PATH,
  payload,
});

export const setLocation = (payload) => ({
  type: HomeActionTypes.SET_LOCATION,
  payload,
});

export const setEmployeeData = (payload) => ({
  type: HomeActionTypes.SET_EMPLOYEE_DATA,
  payload,
});

export const featuredSyncStart = (payload) => ({
  type: HomeActionTypes.FEATURED_SYNC_START,
  payload,
});

export const featuredSyncFinish = (payload) => ({
  type: HomeActionTypes.FEATURED_SYNC_FINISH,
  payload,
});
