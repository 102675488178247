import React from "react";
import { Form, Modal } from "react-bootstrap";
import { ReactComponent as Cardwhite } from "../../assets/images/card-white.svg";
import { ReactComponent as Category } from "../../assets/images/category.svg";
//import { ReactComponent as CardImage } from "../../assets/images/card.svg";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import UserService from "../../services/user/user.service";
import AppConfig from "../../config/app.config";
import Backdrop from "@material-ui/core/Backdrop";
import StripeInfo from "../../components/stripe-payment-new/stripe-payment";
const bluesnapScript = window.bluesnap;

class PaymentCardModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 1,
      employeeId: this.props.employeeId,
      isAddAddressModalVisible: false,
      billingAddressmap: this.props.billingAddressmap,
      defaultBillingAddress: this.props.defaultBillingAddress,
      isdontSaveCard: 0,
      pfToken: this.props.pfToken,
      expiryMonth: null,
      cardHolderName: null,
      paymentInfo: {},
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.employeeId != null) {
      this.setState({
        employeeId: this.props.employeeId,
        billingAddressmap: this.props.billingAddressmap,
        defaultBillingAddress: this.props.defaultBillingAddress,
        pfToken: this.props.pfToken,
        paymentInfo: this.props.paymentInfo,
        isLoading: 0,
      });
      // this.setBluesnapPfToken(this.props.pfToken);
    } else {
      this.props.history.goBack();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const billingAddressmap = this.props.billingAddressmap;
    if (billingAddressmap !== prevProps.billingAddressmap) {
      this.setState({
        defaultBillingAddress: this.props.defaultBillingAddress,
        pfToken: this.props.pfToken,
      });
    }
  }

  setBluesnapPfToken = (pfToken) => {
    var bsObj = {
      //insert your Hosted Payment Fields token
      token: pfToken,
      onFieldEventHandler: {
        onFocus: (tagId) => {
          // Handle focus
          console.log("onFocus", tagId);
          this.changeImpactedElement(tagId, "", "hosted-field-focus");
        },
        onBlur: function (tagId) {
          console.log("onBlur", tagId);
          // Handle blur
          this.changeImpactedElement(tagId, "hosted-field-focus");
        },
        onError: (tagId, errorCode, errorDescription, eventOrigin) => {
          console.log(
            "onError",
            tagId,
            errorCode,
            errorDescription,
            eventOrigin
          );
          this.checkErrors(parseInt(errorCode), tagId);
        },
        onType: (tagId, cardType, cardData) => {
          console.log("onType", tagId, cardType, cardData);
          // get card type from cardType and display card image
          // $("#card-logo > img").attr("src", this.cardUrl[cardType]);
          if (null != cardData) {
            // $("#" + tagId + "-help")
            //   .addClass("helper-text-green")
            //   .text(JSON.stringify(cardData));
          }
        },
        onValid: (tagId) => {
          console.log("onValid", tagId);
        },
      },
      style: {
        // Styling all inputs
        input: {
          "font-size": "14px",
          "font-family":
            "RobotoDraft,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif",
          "line-height": "1.42857143",
          color: "#fff",
        },
        // Styling input state
        ":focus": {
          color: "#939393",
        },
      },
      ccnPlaceHolder: "1234 5678 9012 3456",
      cvvPlaceHolder: "123",
      expPlaceHolder: "MM / YY",
    };
    bluesnapScript.hostedPaymentFieldsCreate(bsObj);

    this.setState({ isLoading: 0 });
  };

  checkErrors = (err, tagId) => {
    console.log(err, tagId);
    this.setState({ isLoading: 0 });
    if (err === 10) {
      switch (tagId) {
        case "ccn":
          NotificationManager.error("Card number is invalid.", "", 2000);
          break;

        case "cvv":
          NotificationManager.error("CVV is invalid.", "", 2000);
          break;

        case "exp":
          NotificationManager.error("Expiry date is invalid.", "", 2000);
          break;
      }
    }

    if (err === 14002) {
      NotificationManager.error("Invalid credit card number.", "", 2000);
    }

    if (err === 14040) {
      NotificationManager.error("Your token is expired.", "", 2000);
    }

    if (err === 14041) {
      NotificationManager.error("Could not find the token.", "", 2000);
    }

    if (err === 14042) {
      NotificationManager.error(
        "Token is not associated with a payment method, please verify your client integration or contact BlueSnap support.",
        "",
        2000
      );
    }

    if (err === 22013) {
      NotificationManager.error(
        "CC type is not supported by the merchant.",
        "",
        2000
      );
    }

    if (err === 400) {
      NotificationManager.error(
        "Your session is expired, Please refresh the page to continue.",
        "",
        2000
      );
    }

    if (err === 403 || err === 404 || err === 500) {
      NotificationManager.error(
        "Internal server error please try again later.",
        "",
        2000
      );
    }
  };

  do_when_clicking_submit_button = () => {
    if (
      this.state.defaultBillingAddress === null ||
      this.state.defaultBillingAddress.addressId === null
    ) {
      NotificationManager.warning("Please select billing address.", "", 2000);
    } else {
      this.setState({ isLoading: 1 });

      bluesnapScript.hostedPaymentFieldsSubmitData((res) => {
        if (null != res.error) {
          let errorArray = res.error;
          this.setState({ isLoading: 0 });
          for (let i = 0; i < errorArray.length; i++) {
            this.checkErrors(
              parseInt(errorArray[i].errorCode),
              errorArray[i].tagId
            );
          }
        } else {
          let cardData = res.cardData;

          if (cardData) {
            this.loading = true;
            let expMonth = cardData.exp.split("/")[0];
            let expYear = cardData.exp.split("/")[1];

            // call save card api

            const payload = {
              verticalId: AppConfig.API.VERTICAL_ID,
              appId: AppConfig.API.APP_ID,
              networkId: AppConfig.API.SUBSCRIPTION_ID,
              loginEmployeeId: this.state.employeeId,
              billingAddressId: this.state.defaultBillingAddress.addressId,
              isDefaultCard: 1,
              isSave: true,
              cardNumber: cardData.last4Digits,
              expiryYear: expYear,
              expiryMonth: expMonth,
              cardSubType: cardData.cardSubType,
              paymentBrand: cardData.ccType,
              cardToken: this.state.pfToken,
              // connectionTypeId: 509,
            };
            if (this.state.isdontSaveCard === 0) {
              this.saveCard(payload);
            } else {
              this.setState({ isLoading: 0 });
              this.props.dontSaveCardCallBack(this.state.pfToken);
            }
          } else {
            NotificationManager.Error("Please enter valid fields.", "", 2000);
            this.setState({ isLoading: 0 });
          }
        }
      });
    }
  };

  saveCard = (payload) => {
    UserService.addCard(payload)
      .then((res) => {
        if (res.error != null) {
          if (res.error?.response?.data?.message != null) {
            NotificationManager.error(
              res.error.response.data.message,
              "",
              3000
            );
            this.setState({
              isLoading: 0,
              isdontSaveCard: 0,
              expiryMonth: null,
              cardHolderName: null,
            });
          } else {
            NotificationManager.error("Could not add card.", "", 3000);
            this.setState({
              isLoading: 0,
              isdontSaveCard: 0,
              expiryMonth: null,
              cardHolderName: null,
            });
          }
        } else {
          if (res["status"]) {
            NotificationManager.success("Card added successfully.", "", 3000);
            this.setState({
              isLoading: 0,
              isdontSaveCard: 0,
              expiryMonth: null,
              cardHolderName: null,
            });
            this.props.hideModal();
          } else {
            NotificationManager.error("Could not add card.", "", 3000);
            this.setState({
              isLoading: 0,
              isdontSaveCard: 0,
              expiryMonth: null,
              cardHolderName: null,
            });
          }
        }
      })
      .catch((err) => {
        NotificationManager.error(err, "", 3000);
        this.setState({
          isLoading: 0,
          isdontSaveCard: 0,
          expiryMonth: null,
          cardHolderName: null,
        });
      });
  };

  render() {
    return (
      <>
        <Modal
          show={this.props.show}
          onHide={this.props.hideModal}
          backdrop="static"
          keyboard={false}
          centered
          id="billing-address-modal"
        >
          {this.state.isLoading === 1 ? (
            <Backdrop style={{ zIndex: 100, color: "#003699" }} open>
              <img
                src={require("../../assets/images/Veuit-Spinner.gif")}
                style={{
                  textAlign: "center",
                  display: "block",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
                className="c-logo"
                alt="streamit"
              />
            </Backdrop>
          ) : (
            ""
          )}

          <div id="address-modal">
            <div className="order-confirmation">
              <Modal.Header closeButton>
                <div>
                  <h4>Add New Card</h4>
                </div>
              </Modal.Header>
            </div>
            {/* <div className="card-information">
            <CardImage className="card-image" />
            <p className="cardholder-name">Cardholder Name</p>
          </div> */}
            <div className="card-details" id="channel-registerpay">
              <StripeInfo
                clientSecret={this.props.paymentInfo.clientSecret}
                clientPublicKey={this.props.paymentInfo.clientPublicKey}
                orderId={this.props.paymentInfo.orderId}
                returnUrl={this.props.paymentInfo.returnUrl}
                orderTotalAmount={this.props.paymentInfo.orderTotalAmount}
                paymentIntentId={this.props.paymentInfo.paymentIntentId}
              />
              {/* <Form.Group className="form-group" id="set-card-img">
                <Form.Label
                  htmlFor="exampleFormControlSelect2"
                  className="payment-form-label"
                >
                  Card Number{" "}
                </Form.Label>
                <div
                  data-bluesnap="ccn"
                  style={{ paddingLeft: "10px" }}
                  className="payment-form-field"
                ></div>
                <Cardwhite className="card-image-vec" />
              </Form.Group> 
              <Form.Group className="form-group">
                <Form.Label
                  htmlFor="exampleFormControlSelect2"
                  className="payment-form-label"
                >
                  Cardholder Name
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="40"
                  name="name"
                  className="payment-form-field"
                  placeholder="Enter cardholder name"
                />
              </Form.Group>
              {/* <div className="add-expirydate">
                <Form.Group className="form-group1">
                  <Form.Label
                    htmlFor="exampleFormControlSelect2"
                    className="payment-form-label"
                  >
                    Expiration Date
                  </Form.Label>
                  <div
                    data-bluesnap="exp"
                    style={{ paddingLeft: "10px" }}
                    className="payment-zipcode"
                    id="mobile-paymentset"
                  ></div>
                </Form.Group>
                <Form.Group className="form-group1">
                  <Form.Label
                    htmlFor="exampleFormControlSelect2"
                    className="payment-form-label"
                  >
                    Security Code
                  </Form.Label>
                  <div
                    data-bluesnap="cvv"
                    style={{ paddingLeft: "10px" }}
                    className="payment-zipcode"
                  ></div>
                </Form.Group>
              </div> */}
              {/* <Form.Group id="billing-bottom">
                <Form.Label
                  htmlFor="exampleFormControlSelect2"
                  className="payment-form-label"
                >
                  Billing Address
                </Form.Label>
                {/* <select
                  className="form-control new-payment-select"
                  id="exampleFormControlSelectPay"
                  onClick={this.props.openBillingAddress}
                >
                  {this.state.defaultBillingAddress != null ? (
                    <option value={this.state.defaultBillingAddress.addressId}>
                      {" "}
                      {this.state.defaultBillingAddress.address1},{" "}
                      {this.state.defaultBillingAddress.city},{" "}
                      {this.state.defaultBillingAddress.state}{" "}
                    </option>
                  ) : (
                    <option value="-1">Select your billing address</option>
                  )}
                </select> */}
              {/* <div
                  className="new-payment-select1"
                  onClick={this.props.openBillingAddress}
                >
                  {this.state.defaultBillingAddress != null ? (
                    <p value={this.state.defaultBillingAddress.addressId}>
                      {" "}
                      {this.state.defaultBillingAddress.address1},{" "}
                      {this.state.defaultBillingAddress.city},{" "}
                      {this.state.defaultBillingAddress.state}{" "}
                    </p>
                  ) : (
                    <p>Select your billing address</p>
                  )}
                  <Category />
                </div>
              </Form.Group>
              <div
                className="setup-default-add-card"
                id="setup-default-add-card"
              >
                <h4>Don’t save my card info</h4>
                <Form.Check type={"radio"} id={`check-api-${"radio"}`}>
                  <Form.Check.Input
                    type={"radio"}
                    id="address-3"
                    name="address-3"
                    className="new-payment-radio-pay"
                    checked={this.state.isdontSaveCard === 1 ? true : false}
                    onClick={(e) => {
                      this.setState({
                        isdontSaveCard: this.state.isdontSaveCard === 1 ? 0 : 1,
                      });
                    }}
                  />
                </Form.Check>
              </div>
              <div className="add-new-address-btn">
                <button
                  className="add-address-btn"
                  onClick={this.do_when_clicking_submit_button}
                >
                  Use card
                </button>
              </div> */}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default PaymentCardModal;
