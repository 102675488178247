import React from "react";
import UserService from "../../services/user/user.service";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import AppConfig from "../../config/app.config";
import { formatDate } from "../../helpers/core.helper";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { ReactComponent as Calender } from "../../assets/images/Calendar.svg";
import { ReactComponent as Play } from "../../assets/images/play.svg";
import { ReactComponent as Pause } from "../../assets/images/pause.svg";
import { setSigninRedirectPath } from "../../store/home/home.actions";
import { selectEmployeeData } from "../../store/home/home.selectors";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

// https://github.com/lhz516/react-h5-audio-player
// const AudioPlayer = window.ReactH5AudioPlayer.default

// class PlayerApp extends React.Component {
//    render() {
//       return (
//          <div className="container">
//             <h1>Hello, audio player!</h1>
//             <AudioPlayer
//                src="https://hanzluo.s3-us-west-1.amazonaws.com/music/wuyuwuqing.mp3"
//                volume={0.5}
//             // Try other props!
//             />
//             <p>
//                <a href="https://github.com/lhz516/react-h5-audio-player" target="_blank">Read Docs on Github</a>
//             </p>
//          </div>
//       );
//    }
// }

const mapStateToProps = (state) => {
  return { employeeData: selectEmployeeData(state) };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setSigninRedirectPath,
    },
    dispatch
  ),
});

export class PodcastDetails extends React.Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.podcastPlayer = React.createRef();
    this.state = {
      isLoading: 1,
      podcastMap: {},
      podcastId: "",
      episodeData: {},
      isPlay: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (
      this.props.employeeData != null &&
      this.props.employeeData?.employeeId != null
    ) {
      const pathArray = window.location.pathname.split("/");
      if (pathArray[2] !== null && pathArray[2] !== "") {
        this.setState({
          podcastId: pathArray[2],
        });
        this.getPodcastDetailById(pathArray[2]);
      } else {
        this.props.history.goBack();
      }
    } else {
      this.props.setSigninRedirectPath({
        signinRedirectPath: window.location.pathname,
      });
      //this.props.history.push("/authentication/sign-in");
      const reqUrl = window.btoa(AppConfig.API.DOMAIN_URL + window.location.pathname);
      const loginDomainUrl = AppConfig.API.LOGIN_DOMAIN_URL;
      window.location.href = `${loginDomainUrl}/sign-in?reqUrl=${reqUrl}`;
    }
  }

  async componentDidUpdate(prevProps, prevState) {}

  getPodcastDetailById = (podCastId) => {
    const payload = {
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      employeeId: this.props.employeeData?.employeeId,
      loginEmployeeId: this.props.employeeData?.employeeId,
      appId: AppConfig.API.APP_ID,
      podCastId: podCastId,
    };

    UserService.getPodcastDetailById(payload)
      .then((res) => {
        if (res.error != null) {
          this.setState({ podcastMap: {}, isLoading: 1 });
          NotificationManager.warning(
            res.error.response.data.message,
            "",
            2000
          );
          this.interval = setInterval(() => this.props.history.goBack(), 2100);
        } else {
          if (res["status"]) {
            if (res["data"] != null && res["data"].podCastContents.length > 0) {
              if (
                (res["data"].podCastContents[0].contentUrl.includes(".mp3") ||
                  res["data"].podCastContents[0].contentUrl.includes(
                    "https://open.spotify.com"
                  )) &&
                res["data"].podCastContents[0].contentType === "Audio File"
              ) {
                this.setState({
                  podcastMap: res["data"],
                  isLoading: 0,
                  episodeData: res["data"].podCastContents[0],
                });
              } else {
                this.setState({
                  podcastMap: res["data"],
                  isLoading: 0,
                });
              }
            } else {
              this.setState({ podcastMap: {}, isLoading: 1 });
              NotificationManager.warning("No Data Found.", "", 2000);
              this.interval = setInterval(
                () => this.props.history.goBack(),
                2100
              );
            }
          } else {
            this.setState({ podcastMap: {}, isLoading: 1 });
            NotificationManager.warning(res["message"], "", 2000);
            this.interval = setInterval(
              () => this.props.history.goBack(),
              2100
            );
          }
        }
      })
      .catch((err) => {
        this.setState({ podcastMap: {}, isLoading: 1 });
        NotificationManager.error(err, "", 2000);
        this.interval = setInterval(() => this.props.history.goBack(), 2100);
      });
  };

  onPlay = () => {
    this.setState({ isPlay: true });
  };

  onPause = () => {
    this.setState({ isPlay: false });
  };

  playPausePodcast = () => {
    if (
      Object.keys(this.state.episodeData).length > 0 &&
      (this.state.episodeData.contentUrl.includes(".mp3") ||
        this.state.episodeData.contentUrl.includes(
          "https://open.spotify.com"
        )) &&
      this.state.episodeData.contentType === "Audio File"
    ) {
      if (this.state.isPlay) {
        this.podcastPlayer.current?.audio.current.pause();
        this.setState({ isPlay: false });
      } else {
        this.podcastPlayer.current?.audio.current.play();
        this.setState({ isPlay: true });
      }
    }
  };

  playPause = (episodeId, index) => {
    if (
      Object.keys(this.state.episodeData).length > 0 &&
      this.state.episodeData.podCastEpisodeId === episodeId &&
      (this.state.episodeData.contentUrl.includes(".mp3") ||
        this.state.episodeData.contentUrl.includes(
          "https://open.spotify.com"
        )) &&
      this.state.episodeData.contentType === "Audio File"
    ) {
      if (this.state.isPlay) {
        this.podcastPlayer.current?.audio.current.pause();
        this.setState({ isPlay: false });
      } else {
        this.podcastPlayer.current?.audio.current.play();
        this.setState({ isPlay: true });
      }
    } else {
      const podcastData = this.state.podcastMap;
      if (
        (podcastData.podCastContents[index].contentUrl.includes(".mp3") ||
          podcastData.podCastContents[index].contentUrl.includes(
            "https://open.spotify.com"
          )) &&
        podcastData.podCastContents[index].contentType === "Audio File"
      ) {
        this.setState({
          episodeData: podcastData.podCastContents[index],
          isPlay: true,
        });
      }
    }
  };

  render() {
    return (
      <>
        {this.state.isLoading === 1 ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: window.innerHeight,
                width: "100%",
              }}
            >
              <img
                src={require("../../assets/images/Veuit-Spinner.gif")}
                style={{
                  textAlign: "center",
                  display: "block",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
                className="c-logo"
                alt="streamit"
              />
            </div>
          </>
        ) : (
          <>
            {/* <iframe style={{borderRadius:'12px'}} src="https://open.spotify.com/embed/0ofXAdFIQQRsCYj9754UFx?utm_source=generator" width="100%" height="352" frameBorder="0"  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="laz\"></iframe>
                  <iframe style={{borderRadius:'12px'}} src="https://open.spotify.com/embed/10oGipQYmFtR8tC0TMm4m5?utm_source=generator" width="100%" height="352" frameBorder="0"  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe> */}
            <div className="show-podcast-details">
              <div className="show-podcast-tabs">
                <div className="podcast-sec">
                  <div className="podcast-sec-left">
                    <h4 className="podcast-text">
                      {this.state.podcastMap.podCastName}
                    </h4>
                    <p className="podcast-para">
                      {this.state.podcastMap.podCastDescription}
                    </p>
                    <div className="set-btns-now">
                      <div className="set-btns-now1">
                        {" "}
                        <button className="btn show-Listen-btn">
                          Listen Now
                        </button>
                      </div>
                      <div className="set-btns-now1">
                        {" "}
                        <button className="btn show-Subscribe-btn">
                          Subscribe
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="podcast-sec-right">
                    <img
                      src={this.state.podcastMap.logo}
                      alt="images"
                      className="podcast-sec-right1"
                    ></img>
                    <button
                      className="btn set-play-img1"
                      onClick={this.playPausePodcast}
                    >
                      {this.state.isPlay ? (
                        <Pause
                          height={104}
                          width={104}
                          className="set-play-img"
                        />
                      ) : (
                        <Play
                          height={104}
                          width={104}
                          className="set-play-img"
                        />
                      )}
                    </button>
                  </div>
                </div>

                {this.state.podcastMap.podCastContents.map((item, key) => {
                  return (
                    <div key={key}>
                      <div className="podcast-center">
                        <div className="podcast-center-left">
                          <img
                            src={require("../../assets/images/podcast-3.png")}
                            alt="images"
                            className="podcast-center-left1"
                          ></img>
                          {item.contentUrl.includes(".mp3") &&
                            item.contentType === "Audio File" && (
                              <button
                                className="btn podcast-center-left2"
                                onClick={() =>
                                  this.playPause(item.podCastEpisodeId, key)
                                }
                              >
                                {this.state.episodeData.podCastEpisodeId ===
                                  item.podCastEpisodeId && this.state.isPlay ? (
                                  <Pause
                                    height={40}
                                    width={40}
                                    className="podcast-center-left2"
                                  />
                                ) : (
                                  <Play
                                    height={40}
                                    width={40}
                                    className="podcast-center-left2"
                                  />
                                )}
                              </button>
                            )}
                        </div>
                        <div className="podcast-center-right">
                          <div className="podcast-timingsec">
                            {item.podcastEpisodePublishDate !== null &&
                            item.podcastEpisodePublishDate !== "" ? (
                              <div className="podcast-calender-sec">
                                <Calender />
                                {/* <img src={require("../../assets/images/podcast-calender.png")} alt="images"></img> */}
                                <p>
                                  {formatDate(
                                    item.podcastEpisodePublishDate,
                                    "MMM dd, yyyy"
                                  )}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                            {/* <div className="podcast-timing-sec">
                                          <img src={require("../../assets/images/podcast-Time.png")} alt="images"></img>
                                          <p>00:36:10</p>
                                       </div> */}
                          </div>
                          <div className="podcast-episodes">
                            <h4 className="podcast-episode-text">
                              {item.podCastEpisodeName}
                            </h4>
                            <p className="podcast-episode-para">
                              {item.podcastEpisodeDescription}
                            </p>
                          </div>
                        </div>
                      </div>
                      <p className="podcast-episode-para podcast-episode-para1">
                        {item.podcastEpisodeDescription}
                      </p>
                    </div>
                  );
                })}
              </div>
              {Object.keys(this.state.episodeData).length > 0 &&
              this.state.episodeData.contentUrl.includes(".mp3") &&
              this.state.episodeData.contentType === "Audio File" ? (
                <div className="podcast-footer">
                  <div className="podcast-footer-list">
                    <div className="podcast-footer-list1">
                      <img
                        src={require("../../assets/images/podcast-2.png")}
                        alt="images"
                      ></img>
                      <p>{this.state.episodeData.podCastEpisodeName}</p>
                    </div>
                    <div className="podcast-footer-list2">
                      <p>{this.state.episodeData.podCastEpisodeName}</p>
                      <AudioPlayer
                        //autoPlay
                        controls
                        id="podcast-player"
                        src={this.state.episodeData.contentUrl}
                        //src="https://hanzluo.s3-us-west-1.amazonaws.com/music/wuyuwuqing.mp3"
                        onPlay={this.onPlay}
                        onPause={this.onPause}
                        ref={this.podcastPlayer}
                        showFilledVolume
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {Object.keys(this.state.episodeData).length > 0 &&
              this.state.episodeData.contentUrl.includes(
                "https://open.spotify.com"
              ) &&
              this.state.episodeData.contentType === "Audio File" ? (
                <div className="podcast-footer">
                  <div className="podcast-footer-list">
                    {/* <div className="podcast-footer-list1">
                                 <img src={require("../../assets/images/podcast-2.png")} alt="images"></img>
                                 <p>{this.state.episodeData.podCastEpisodeName}</p>
                              </div> */}
                    <div className="podcast-footer-list2">
                      {/* <p>{this.state.episodeData.podCastEpisodeName}</p> */}
                      <iframe
                        title="content"
                        style={{ borderRadius: "12px" }}
                        src={
                          this.state.episodeData.contentUrl +
                          "?utm_source=generator"
                        }
                        width="100%"
                        height="100"
                        frameBorder="0"
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                        loading="lazy"
                      ></iframe>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PodcastDetails);
