//router
import LayoutsRoute from './router/layouts-route';

//scss files
import './assets/css/bootstrap.min.css'
import './assets/css/typography.css'
import './assets/css/style.css';
import './assets/css/responsive.css'
import './assets/css/dstyles.css'


// import  './assets/css/custom.css';
import {
  NotificationContainer,
} from "react-notifications";
function App() {
  return (
    <div className="App">
      <LayoutsRoute />
      <NotificationContainer/>
    </div>
  );
}

export default App;
