import React from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Popover,
  OverlayTrigger,
  PopoverContent,
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { secondsToHourse } from "../../helpers/core.helper";
import UserService from "../../services/user/user.service";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import Backdrop from "@material-ui/core/Backdrop";
import Rating from "../../components/rating/star-rating.component";
import AppConfig from "../../config/app.config";
import Background from "../../assets/images/Frame.png";
import "../../assets/fonts/Oswald/Oswald-Bold.ttf";
import {
  newRealeseSyncStart,
  classicSyncStart,
  independentSyncStart,
  setSigninRedirectPath,
} from "../../store/home/home.actions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Dropdown from "react-bootstrap/Dropdown";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { ReactComponent as AddToWatchList } from "../../assets/images/watchlist.svg";
import { ReactComponent as RemoveFromWatchList } from "../../assets/images/right1.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import Video from "../video/video";
import PaymentModal from "../../components/modal/payment-modal.component";
import PaymentOrderConfirmModal from "../../components/modal/payment-order-confirm.component";
import { selectEmployeeData } from "../../store/home/home.selectors";
SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const mapStateToProps = (state) => {
  return { employeeData: selectEmployeeData(state) };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      newRealeseSyncStart,
      classicSyncStart,
      independentSyncStart,
      setSigninRedirectPath,
    },
    dispatch
  ),
});

export class MovieDetailsNew extends React.Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.state = {
      isLoading: 2,
      movieId: "",
      employeeId: "",
      movieObject: null,
      movieWatchListId: -1,
      newReleaseMap: [],
      classicMap: [],
      starring: [],
      director: "",
      producer: "",
      writter: "",
      cinematographer: "",
      activeTab: "pills-home",
      isShowVideo: false,
      isContract: "2",
      isPaymentModalVisible: false,
      isOrderConfirmModalVisible: false,
      transactionId: null,
      orderId: null,
      productData: {},
      buyPayload: {},
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (
      this.props.employeeData !== null &&
      this.props.employeeData?.employeeId !== null
    ) {
      const pathArray = window.location.pathname.split("/");
      if (pathArray[2] !== null && pathArray[2] !== "") {
        this.setState({
          movieId: pathArray[2],
          employeeId: this.props.employeeData?.employeeId,
          isContract: localStorage.getItem("isContract"),
        });
        this.getMovieDetailById(
          pathArray[2],
          this.props.employeeData?.employeeId
        );
      } else {
        this.props.history.goBack();
      }
    } else {
      this.props.setSigninRedirectPath({
        signinRedirectPath: window.location.pathname,
      });
      const reqUrl = window.btoa(
        AppConfig.API.DOMAIN_URL + window.location.pathname
      );
      const loginDomainUrl = AppConfig.API.LOGIN_DOMAIN_URL;
      window.location.href = `${loginDomainUrl}/sign-in?reqUrl=${reqUrl}`;
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  getMovieDetailById = (movieId, employeeId) => {
    const numberRegex = new RegExp("^[0-9]*$");
    if (!numberRegex.test(movieId)) {
      NotificationManager.warning("Could not get Movie Details", "", 2000);
      this.props.history.push("/");
    } else {
      const payload = {
        movieId: movieId,
        employeeId: employeeId,
        subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
        verticalId: AppConfig.API.VERTICAL_ID,
      };

      UserService.getMovieDetailById(payload)
        .then((res) => {
          if (res.error != null) {
            this.setState({ movieObject: null, isLoading: 0 });
            NotificationManager.warning(
              res.error.response.data.message,
              "",
              2000
            );
          } else {
            if (res["status"]) {
              if (Object.keys(res["data"]).length === 0) {
                NotificationManager.warning(
                  "Could not get Movie Details",
                  "",
                  2000
                );
                this.props.history.push("/");
              } else {
                if (res["data"].casts != null && res["data"].casts.length > 0) {
                  for (let i = 0; i < res["data"].casts.length; i++) {
                    const row = res["data"].casts[i];
                    if (row.starring != null && row.starring.length > 0) {
                      this.setState({ starring: row.starring });
                    } else if (row.directedById != null) {
                      this.setState({ director: row.director });
                    } else if (row.producedById != null) {
                      this.setState({ producer: row.producer });
                    } else if (row.writtenById != null) {
                      this.setState({ writter: row.writter });
                    } else if (row.cinematographyId != null) {
                      this.setState({ cinematographer: row.cinematographer });
                    }
                  }
                }
                this.setState({
                  movieObject: res["data"],
                  movieWatchListId: res["data"].movieWatchListId,
                  isLoading: 0,
                });
              }
            } else {
              this.setState({ movieObject: null, isLoading: 0 });
              NotificationManager.error(res["message"], "", 2000);
            }
          }
        })
        .catch((err) => {
          this.setState({ movieObject: null, isLoading: 0 });
          NotificationManager.error(err, "", 2000);
        });
    }
  };

  addToWatchList = (event) => {
    event.target.blur();
    this.setState({ isLoading: 1 });
    const payload = {
      movieId: this.state.movieId,
      employeeId: this.props.employeeData?.employeeId,
      movieWatchListId: this.state.movieWatchListId,
    };
    UserService.addToWatchList(payload)
      .then((res) => {
        if (res.error != null) {
          this.setState({ isLoading: 0 });
          NotificationManager.warning(
            res.error.response.data.message,
            "",
            2000
          );
        } else {
          if (res["status"]) {
            const payloadData = {
              subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
              verticalId: AppConfig.API.VERTICAL_ID,
              employeeId: this.props.employeeData?.employeeId,
              loginEmployeeId: this.props.employeeData?.employeeId,
            };
            this.props.classicSyncStart(payloadData);
            this.props.newRealeseSyncStart(payloadData);
            this.props.independentSyncStart(payloadData);
            if (this.state.movieWatchListId !== -1) {
              NotificationManager.success(
                "Movie Removed from My Watchlist.",
                "",
                2000
              );
              let newMovieObject = this.state.movieObject;
              newMovieObject.movieWatchListId = -1;
              this.setState({
                movieWatchListId: -1,
                isLoading: 0,
                movieObject: newMovieObject,
              });
            } else {
              NotificationManager.success(
                "Movie Added to My Watchlist.",
                "",
                2000
              );
              let newMovieObject = this.state.movieObject;
              newMovieObject.movieWatchListId = res["data"].movieWatchListId;
              this.setState({
                movieWatchListId: res["data"].movieWatchListId,
                isLoading: 0,
                movieObject: newMovieObject,
              });
            }
          } else {
            this.setState({ isLoading: 0 });
            NotificationManager.error(res["message"], "", 2000);
          }
        }
      })
      .catch((err) => {
        this.setState({ isLoading: 0 });
        NotificationManager.error(err, "", 2000);
      });
  };

  updateTab = (tabName) => {
    this.setState({ activeTab: tabName });
  };

  handleClose = (startTime, endTime, projectLogId) => {
    this.setState({
      isShowVideo: false,
      movieObject: {
        ...this.state.movieObject,
        startTime: startTime.toString(),
        endTime: endTime.toString(),
        videoLogId: projectLogId,
      },
    });
  };

  handleShow = () => {
    if (this.state.movieObject.movieUrl !== "") {
      if (
        (this.state.isContract === "1" || this.state.isContract === "0") &&
        this.state.movieObject.contractId === -1
      ) {
        this.setState({ isLoading: 1 });
        const payload = {
          loginEmployeeId: parseInt(this.state.employeeId),
          subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
          verticalId: AppConfig.API.VERTICAL_ID,
          appId: AppConfig.API.APP_ID,
          isMovie: 1,
          movieId: parseInt(this.state.movieId),
          movieName: this.state.movieObject.movieName,
          moviePrice: this.state.movieObject.price
            ? this.state.movieObject.price
            : 0,
          isContract: this.state.isContract,
          paymentMode: "1",
        };
        UserService.buyMovieOrSeries(payload)
          .then((res) => {
            if (res.error != null) {
              this.setState({ isLoading: 0 });
              NotificationManager.warning(
                res.error.response.data.message,
                "",
                2000
              );
            } else {
              if (res["status"]) {
                this.setState({
                  isShowVideo: true,
                  isLoading: 0,
                  movieObject: {
                    ...this.state.movieObject,
                    contractId: res["data"].orderId,
                  },
                });
              } else {
                this.setState({ isLoading: 0 });
                NotificationManager.error(res["message"], "", 2000);
              }
            }
          })
          .catch((err) => {
            this.setState({ isLoading: 0 });
            NotificationManager.error(err, "", 2000);
          });
      } else if (
        (this.state.isContract === "1" || this.state.isContract === "0") &&
        this.state.movieObject.contractId !== -1
      ) {
        this.setState({ isShowVideo: true });
      }
    } else {
      NotificationManager.error("There is no Movie link.", "", 2000);
    }
  };

  buyMovie = (cardId) => {
    this.setState({ isLoading: 1 });
    const payload = {
      loginEmployeeId: parseInt(this.state.employeeId),
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.APP_ID,
      isMovie: 1,
      movieId: parseInt(this.state.movieId),
      movieName: this.state.movieObject.movieName,
      moviePrice: this.state.movieObject.price
        ? this.state.movieObject.price
        : 0,
      isContract: this.state.isContract,
      paymentMode: "2",
      cardId: cardId,
      isExistingCard: 1,
    };
    UserService.buyMovieOrSeries(payload)
      .then((res) => {
        if (res.error != null) {
          this.setState({ isLoading: 0 });
          NotificationManager.warning(
            res.error.response.data.message,
            "",
            2000
          );
        } else {
          if (res["status"]) {
            const data = res["data"];
            if (
              data.paymentGatewayInfo &&
              data.paymentGatewayInfo.url !== null &&
              data.paymentGatewayInfo.url !== undefined &&
              data.paymentGatewayInfo.requestParams != null
            ) {
              var paymentUrl = data.paymentGatewayInfo.url + "?";
              const paymentParameters = [];
              const params = data.paymentGatewayInfo.requestParams;
              if (params.alipay !== null && params.alipay !== undefined) {
                paymentParameters.push(`alipay=${params.alipay}`);
              }
              if (params.appId !== null && params.appId !== undefined) {
                paymentParameters.push(`appId=${params.appId}`);
              }
              if (params.applepay !== null && params.applepay !== undefined) {
                paymentParameters.push(`applepay=${params.applepay}`);
              }
              if (
                params.banktransfer !== null &&
                params.banktransfer !== undefined
              ) {
                paymentParameters.push(`banktransfer=${params.banktransfer}`);
              }
              if (
                params.boletoBancario !== null &&
                params.boletoBancario !== undefined
              ) {
                paymentParameters.push(
                  `boletoBancario=${params.boletoBancario}`
                );
              }
              if (
                params.callBackUrl !== null &&
                params.callBackUrl !== undefined
              ) {
                paymentParameters.push(
                  `thankyou.backtosellerurl=${params.callBackUrl}`
                );
              }
              if (params.cc !== null && params.cc !== undefined) {
                paymentParameters.push(`cc=${params.cc}`);
              }
              if (params.country !== null && params.country !== undefined) {
                paymentParameters.push(`country=${params.country}`);
              }
              if (
                params.countrydisable !== null &&
                params.countrydisable !== undefined
              ) {
                paymentParameters.push(
                  `countrydisable=${params.countrydisable}`
                );
              }
              if (params.currency !== null && params.currency !== undefined) {
                paymentParameters.push(`currency=${params.currency}`);
              }
              if (
                params.currencydisable !== null &&
                params.currencydisable !== undefined
              ) {
                paymentParameters.push(
                  `currencydisable=${params.currencydisable}`
                );
              }
              if (params.ecp !== null && params.ecp !== undefined) {
                paymentParameters.push(`ecp=${params.ecp}`);
              }
              if (params.enc !== null && params.enc !== undefined) {
                paymentParameters.push(`enc=${params.enc}`);
              }
              if (params.googlepay !== null && params.googlepay !== undefined) {
                paymentParameters.push(`googlepay=${params.googlepay}`);
              }
              if (
                params.merchantTransactionId !== null &&
                params.merchantTransactionId !== undefined
              ) {
                paymentParameters.push(
                  `merchantTransactionId=${params.merchantTransactionId}`
                );
              }
              if (
                params.merchantid !== null &&
                params.merchantid !== undefined
              ) {
                paymentParameters.push(`merchantid=${params.merchantid}`);
              }
              if (
                params.moneybookers !== null &&
                params.moneybookers !== undefined
              ) {
                paymentParameters.push(`moneybookers=${params.moneybookers}`);
              }
              if (
                params.paymentServiceProvider !== null &&
                params.paymentServiceProvider !== undefined
              ) {
                paymentParameters.push(
                  `paymentServiceProvider=${params.paymentServiceProvider}`
                );
              }
              if (params.paypal !== null && params.paypal !== undefined) {
                paymentParameters.push(`paypal=${params.paypal}`);
              }
              if (
                params.paysafecard !== null &&
                params.paysafecard !== undefined
              ) {
                paymentParameters.push(`paysafecard=${params.paysafecard}`);
              }
              if (
                params.realtimebank1 !== null &&
                params.realtimebank1 !== undefined
              ) {
                paymentParameters.push(`realtimebank1=${params.realtimebank1}`);
              }
              if (params.sepadd !== null && params.sepadd !== undefined) {
                paymentParameters.push(`sepadd=${params.sepadd}`);
              }
              if (params.skrill !== null && params.skrill !== undefined) {
                paymentParameters.push(`skrill=${params.skrill}`);
              }
              if (
                params.subscriptionId !== null &&
                params.subscriptionId !== undefined
              ) {
                paymentParameters.push(
                  `subscriptionId=${params.subscriptionId}`
                );
              }
              if (params.webmoney !== null && params.webmoney !== undefined) {
                paymentParameters.push(`webmoney=${params.webmoney}`);
              }
              if (params.wire !== null && params.wire !== undefined) {
                paymentParameters.push(`wire=${params.wire}`);
              }
              if (params.products !== null && params.products !== undefined) {
                var index = 1;
                params.products.forEach((product) => {
                  paymentParameters.push(`name${index}=${product.product}`);
                  paymentParameters.push(`value${index}=${product.salesPrice}`);
                  index += 1;
                });
              }
              paymentUrl = paymentUrl + paymentParameters.join("&");
              window.location.href = paymentUrl;
            } else if (
              data.transactionId &&
              data.transactionId !== null &&
              data.transactionId !== undefined
            ) {
              this.setState({
                isPaymentModalVisible: false,
                isOrderConfirmModalVisible: true,
                transactionId: data.transactionId,
                orderId: data.orderId,
              });

              this.setState({ isLoading: 0 });
            } else if (
              data.orderId &&
              data.orderId != null &&
              data.paymentGatewayInfo &&
              data.paymentGatewayInfo.paymentIntentId !== null &&
              data.paymentGatewayInfo.paymentIntentId !== undefined &&
              data.paymentGatewayInfo.paymentIntentId !== ""
            ) {
              this.setState({ isLoading: 0 });
              const paymentUrl =
                window.location.origin +
                "/authentication/payment-status/" +
                data.orderId;
              window.location.href = paymentUrl;
            } else {
              this.setState({ isLoading: 0 });
            }
          } else {
            this.setState({ isLoading: 0 });
            NotificationManager.error(res["message"], "", 2000);
          }
        }
      })
      .catch((err) => {
        this.setState({ isLoading: 0 });
        NotificationManager.error(err, "", 2000);
      });
  };

  openPaymentPage = () => {
    const payload = {
      loginEmployeeId: parseInt(this.state.employeeId),
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.APP_ID,
      isMovie: 1,
      movieId: parseInt(this.state.movieId),
      movieName: this.state.movieObject.movieName,
      moviePrice: this.state.movieObject.price
        ? this.state.movieObject.price
        : 0,
      isContract: this.state.isContract,
      paymentMode: "2",
    };

    const productData = {
      id: this.state.movieId,
      name: this.state.movieObject.movieName,
      price: this.state.movieObject.price ? this.state.movieObject.price : 0,
    };
    this.setState({
      productData: productData,
      isPaymentModalVisible: true,
      buyPayload: payload,
    });
  };

  placeYourOrder = (cardId) => {
    this.buyMovie(cardId);
  };

  hidePaymentModel = () => {
    this.setState({
      isPaymentModalVisible: false,
    });
  };

  hideConfirmModel = () => {
    this.setState({
      isOrderConfirmModalVisible: false,
    });
    window.location.reload();
  };

  render() {
    return (
      <div ref={this.wrapper}>
        {this.state.isLoading === 1 ? (
          <Backdrop style={{ zIndex: 100, color: "#003699" }} open>
            <img
              src={require("../../assets/images/Veuit-Spinner.gif")}
              style={{
                textAlign: "center",
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
              className="c-logo"
              alt="streamit"
            />
          </Backdrop>
        ) : (
          ""
        )}

        {this.state.isLoading === 2 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: window.innerHeight,
              width: "100%",
            }}
          >
            <img
              src={require("../../assets/images/Veuit-Spinner.gif")}
              style={{
                textAlign: "center",
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
              className="c-logo"
              alt="streamit"
            />
          </div>
        ) : (
          <>
            {this.state.movieObject != null &&
              (this.state.isShowVideo ? (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    height: "100vh",
                    width: "100vw",
                    zIndex: 999999,
                  }}
                >
                  <Video
                    videoObject={this.state.movieObject}
                    videoTitle={this.state.movieObject.movieName}
                    isMovie={1}
                    goBack={this.handleClose}
                  />
                </div>
              ) : (
                <>
                  {this.state.isPaymentModalVisible ? (
                    <PaymentModal
                      show={this.state.isPaymentModalVisible}
                      hideModal={this.hidePaymentModel}
                      productData={this.state.productData}
                      isModelLoading={this.state.isLoading}
                      placeYourOrder={this.placeYourOrder}
                      buyPayload={this.state.buyPayload}
                    />
                  ) : (
                    ""
                  )}

                  {this.state.isOrderConfirmModalVisible ? (
                    <PaymentOrderConfirmModal
                      show={this.state.isOrderConfirmModalVisible}
                      transactionId={this.state.transactionId}
                      orderId={this.state.orderId}
                      hideModal={this.hideConfirmModel}
                    />
                  ) : (
                    ""
                  )}

                  <div className="show-movie-details">
                    <div
                      className={`${
                        this.state.movieObject.bannerType === "Video"
                          ? "show-movie-video"
                          : "show-movie-img"
                      }`}
                    >
                      {this.state.movieObject.bannerType === "Video" &&
                      this.state.movieObject.bannerUrl != null &&
                      this.state.movieObject.bannerUrl !== "" ? (
                        <video
                          playsInline
                          autoPlay
                          loop
                          muted
                          className="movie-bgvideo"
                        >
                          <source
                            src={this.state.movieObject.bannerUrl}
                            type="video/mp4"
                          />
                        </video>
                      ) : (
                        <img
                          src={
                            this.state.movieObject.bannerUrl != null &&
                            this.state.movieObject.bannerUrl !== ""
                              ? this.state.movieObject.bannerUrl
                              : Background
                          }
                          className="movie-bgimg"
                          alt="images"
                        ></img>
                      )}

                      <div className="view-movie-details">
                        <div className="movie-name d-movie-name">
                          <p>{this.state.movieObject.movieName}</p>
                        </div>
                        <div className="show-star-content">
                          {/* <div className='show-ratings'>
                                 {this.state.movieObject.imdbRating !== null && this.state.movieObject.imdbRating !== '' ?
                                    <Rating ratingCount={this.state.movieObject.imdbRating} />
                                    : ''}
                              </div> */}
                          <div className="show-rating-details">
                            {/* <p>(230)</p> */}
                            {this.state.movieObject.movieLength != null &&
                            this.state.movieObject.movieLength !== "" ? (
                              <h3 style={{ paddingLeft: 0 }}>
                                {" "}
                                {secondsToHourse(
                                  this.state.movieObject.movieLength
                                )}{" "}
                              </h3>
                            ) : (
                              ""
                            )}
                            {this.state.movieObject.releasedYear != null &&
                            this.state.movieObject.releasedYear !== "" ? (
                              <h4>{this.state.movieObject.releasedYear}</h4>
                            ) : (
                              ""
                            )}
                            {this.state.movieObject.rating != null &&
                            this.state.movieObject.rating !== "" ? (
                              <button className="btn show-tv-btn">
                                {this.state.movieObject.rating}{" "}
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="movie-description">
                          {this.state.movieObject.description != null &&
                          this.state.movieObject.description !== "" ? (
                            <p>
                              {" "}
                              {this.state.movieObject.description.length > 176
                                ? this.state.movieObject.description.substring(
                                    0,
                                    175
                                  ) + "...."
                                : this.state.movieObject.description}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="movies-reviews">
                          {this.state.starring !== null &&
                          this.state.starring.length > 0 ? (
                            <div className="show-movie-reviews">
                              <div className="movie-starring">
                                <p>Starring</p>
                              </div>
                              <div className="movie-starring-content">
                                <p>
                                  {this.state.starring.map((item, key) => {
                                    let comma = ",";
                                    if (
                                      this.state.starring.length - 1 ===
                                      key
                                    ) {
                                      comma = "";
                                    }
                                    return `${item.cast}${comma} `;
                                  })}
                                </p>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {this.state.movieObject.genere != null &&
                          this.state.movieObject.genere.length > 0 ? (
                            <div className="show-movie-reviews">
                              <div className="movie-starring">
                                <p>Genres</p>
                              </div>
                              <div className="movie-starrings movie-starring1">
                                <p>
                                  {this.state.movieObject.genere.map(
                                    (item, key) => {
                                      let comma = ",";
                                      if (
                                        this.state.movieObject.genere.length -
                                          1 ===
                                        key
                                      ) {
                                        comma = "";
                                      }
                                      return `${item.genere}${comma} `;
                                    }
                                  )}
                                </p>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {/* <div className='show-movie-reviews'>
                                 <div className='movie-starring'>
                                    <p>Subtitles</p>
                                 </div>
                                 <div className='movie-starrings movie-starring2'>
                                    <p>English [CC]</p>
                                 </div>
                              </div> */}
                          {this.state.movieObject.language != null &&
                          this.state.movieObject.language !== "" ? (
                            <div className="show-movie-reviews">
                              <div className="movie-starring">
                                <p>Audio languages</p>
                              </div>
                              <div className="movie-starrings movie-starring3">
                                <p>
                                  {" "}
                                  {this.state.movieObject.language}
                                  {/* English, English (Audio Description) */}
                                </p>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* <div className='show-like-details'>
                              <img src={require("../../assets/images/Ellipse 83.png")} className="show-like-img" alt="images" ></img>
                              <p>Liked by nathanmedia and 324,710 others</p>
                           </div> */}
                        <div className="show-episodes-btn">
                          {/* {this.state.movieUrl !== null && this.state.movieUrl !== '' ?
                                 <button className='btn show-episode' onClick={this.handleShow}>Watch <br></br> <span>Free</span> with Ads</button>
                                 : ""} */}
                          {this.state.isContract === "1" ? (
                            <button
                              className="btn show-rent"
                              style={{ marginLeft: 0 }}
                              onClick={(event) => {
                                event.target.blur();
                                this.handleShow();
                              }}
                            >
                              {"Watch"}
                            </button>
                          ) : this.state.isContract === "0" ? (
                            <button
                              className="btn show-rent"
                              style={{ marginLeft: 0 }}
                              onClick={(event) => {
                                event.target.blur();
                                if (
                                  this.state.movieObject.contractId === -1 &&
                                  this.state.movieObject.price != null &&
                                  this.state.movieObject.price !== "" &&
                                  this.state.movieObject.price !== 0
                                ) {
                                  this.openPaymentPage();
                                } else {
                                  this.handleShow();
                                  //this.openPaymentPage();
                                }
                              }}
                            >
                              {this.state.movieObject.contractId === -1 &&
                              this.state.movieObject.price != null &&
                              this.state.movieObject.price !== "" &&
                              this.state.movieObject.price !== 0 ? (
                                <>
                                  {"Buy"}
                                  <br></br> <span>HD</span>{" "}
                                  {`$${this.state.movieObject.price}`}
                                </>
                              ) : (
                                "Watch"
                              )}
                            </button>
                          ) : (
                            ""
                          )}
                          {/* <button className='btn show-like-btn'>
                                 <FormControlLabel
                                    control={<Checkbox icon={<FavoriteBorder />}
                                       checkedIcon={<Favorite />}
                                       name="checkedH" />} className="show-like-img1"
                                 /> </button> */}
                          {/* <button className='btn show-watchlist-icon' onClick={this.addToWatchList}><img src={require("../../assets/images/watchlist.png")} className="show-watchlist-img" alt="images" ></img></button> */}
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Popover id="popover-basic">
                                <PopoverContent>
                                  {this.state.movieWatchListId === -1
                                    ? "Add to Watchlist"
                                    : "Remove from Watchlist"}
                                </PopoverContent>
                              </Popover>
                            }
                          >
                            <button
                              className="btn show-watchlist-icon"
                              onClick={this.addToWatchList}
                            >
                              {this.state.movieWatchListId === -1 ? (
                                <AddToWatchList className="show-watchlist-img" />
                              ) : (
                                <RemoveFromWatchList className="show-watchlist-img" />
                              )}
                              {/* <img src={this.state.movieWatchListId === -1 ? require("../../assets/images/watchlist.png") : require("../../assets/images/right1.png")} className="show-watchlist-img" alt="images" ></img> */}
                            </button>
                          </OverlayTrigger>

                          {/* <button className='btn show-mob-btn'><img src={require("../../assets/images/Recycle Bin.png")} alt="images" ></img></button> */}
                          {/* <button className='btn show-dinner-btn'>Dinner & Movie</button> */}
                        </div>
                        <div className="show-terms">
                          <p>
                            By viewing, you agree to our{" "}
                            <Link target="_blank" to="/terms-of-use">
                              <span>Terms</span>
                            </Link>{" "}
                            of VEUIT.com{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="show-movie-tabs">
                      <ul
                        className="nav nav-pills show-movie-pills"
                        id="pills-tab"
                        role="tablist"
                      >
                        <div className="show-nav-items">
                          <li className="nav-item">
                            <Link
                              to="#"
                              className={`nav-link ${
                                this.state.activeTab === "pills-home"
                                  ? "active "
                                  : ""
                              } movie-tab-item ${
                                this.state.activeTab === "pills-home "
                                  ? "active "
                                  : ""
                              }`}
                              id="pills-home-tab"
                              data-toggle="pill"
                              onClick={this.updateTab.bind(this, "pills-home")}
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              Details
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                                 <Link to="#" className={`nav-link ${this.state.activeTab === 'pills-Extra' ? 'active' : ''}  movie-tab-item`} id="pills-Extra-tab" data-toggle="pill"
                                    //onClick={this.updateTab.bind(this, 'pills-Extra')}
                                    role="tab" aria-controls="pills-Extra" aria-selected="false">Extra</Link>
                              </li>
                              <li className="nav-item">
                                 <Link to="#" className={`nav-link ${this.state.activeTab === 'pills-Related' ? 'active' : ''}  movie-tab-item`} id="pills-Related-tab" data-toggle="pill"
                                    //onClick={this.updateTab.bind(this, 'pills-Related')}
                                    role="tab" aria-controls="pills-Related" aria-selected="false">Related</Link>
                              </li>
                              <li className="nav-item">
                                 <Link to="#" className={`nav-link ${this.state.activeTab === 'pills-Reviews' ? 'active' : ''}  movie-tab-item`} id="pills-Reviews-tab" data-toggle="pill"
                                    //onClick={this.updateTab.bind(this, 'pills-Reviews')}
                                    role="tab" aria-controls="pills-Reviews" aria-selected="false">Reviews</Link>
                              </li>
                              <li className="nav-item">
                                 <Link to="#" className={`nav-link ${this.state.activeTab === 'pills-Comments' ? 'active' : ''}  movie-tab-item`} id="pills-Comments-tab" data-toggle="pill"
                                    //onClick={this.updateTab.bind(this, 'pills-Comments')}
                                    role="tab" aria-controls="pills-Comments" aria-selected="false">Comments</Link>
                              </li> */}
                          {/* <li className="nav-item">
                                 <a className={`nav-link ${this.state.activeTab === 'pills-CinemaStore' ? 'active' : ''}  movie-tab-item`} id="pills-CinemaStore-tab" data-toggle="pill"
                                    onClick={this.updateTab.bind(this, 'pills-CinemaStore')} to="#" role="tab" aria-controls="pills-CinemaStore" aria-selected="false">Cinema Store</a>
                              </li> */}
                        </div>
                        {/* <div>
                              <button className='btn show-tabs-icon'><img src={require("../../assets/images/tabs.png")} className="show-tabs-img" alt="images" ></img></button>
                           </div> */}
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className={`tab-pane fade ${
                            this.state.activeTab === "pills-home"
                              ? "show active"
                              : ""
                          } show-home-tabs`}
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <div className="show-tab-details">
                            <div className="show-detail-tab">
                              <img
                                src={
                                  AppConfig.API.MANU_IMAGE +
                                  this.state.movieId +
                                  "&random=" +
                                  Date.now()
                                }
                                className="show-detailtab-img"
                                alt={this.state.movieObject.movieName}
                              />
                              {/* <div className='details-share-mob'>
                                       <div className='share-mob-view'>
                                          <p>Share</p>
                                          <img src={require("../../assets/images/Share Icon.png")} className="show-share-img" alt="images" ></img>
                                       </div>
                                    </div> */}
                              <div
                                className="details-subtitle"
                                id="details-subtitle-mob"
                              >
                                {this.state.movieObject.publishDate !== null &&
                                this.state.movieObject.publishDate !== "" ? (
                                  <p>
                                    {this.state.movieObject.publishDate}
                                    {/* May 21st, 1994 */}
                                  </p>
                                ) : (
                                  ""
                                )}

                                {this.state.movieObject.movieLength != null &&
                                this.state.movieObject.movieLength !== "" ? (
                                  <p>
                                    {" "}
                                    {secondsToHourse(
                                      this.state.movieObject.movieLength
                                    )}{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                                <h4>Subtitles</h4>
                                {this.state.movieObject.language !== null &&
                                this.state.movieObject.language !== "" ? (
                                  <h4>{this.state.movieObject.language}</h4>
                                ) : (
                                  ""
                                )}
                              </div>

                              {this.state.movieObject.genere != null &&
                              this.state.movieObject.genere.length > 0 ? (
                                <div className="details-contents-button-mob">
                                  {this.state.movieObject.genere.map(
                                    (item, key) => {
                                      return (
                                        <button
                                          className="btn details-moive-btn"
                                          key={key}
                                        >
                                          {item.genere}
                                        </button>
                                      );
                                    }
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              {/* <div className='details-contents-button-mob'>
                                       <button className='btn crime-btn'>Crime</button>
                                       <button className='btn drama-btn'>Drama</button>
                                       <button className='btn comedy-btn'>Comedy</button>
                                    </div> */}
                            </div>
                            <div className="detail-tab-content">
                              <div className="detail-actions">
                                <p>{this.state.movieObject.movieName}</p>
                                {this.state.movieObject.rating != null &&
                                this.state.movieObject.rating !== "" ? (
                                  <button className="btn detail-action-btn">
                                    {this.state.movieObject.rating}
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="details-action-content">
                                {this.state.movieObject.description != null &&
                                this.state.movieObject.description !== "" ? (
                                  <p>{this.state.movieObject.description}</p>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="details-show-movie">
                                {this.state.director !== null &&
                                this.state.director !== "" ? (
                                  <div className="details-of-movie">
                                    <h4>Directors</h4>
                                    <p>{this.state.director}</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {this.state.writter !== null &&
                                this.state.writter !== "" ? (
                                  <div className="details-of-movie">
                                    <h4>Writers</h4>
                                    <p>{this.state.writter}</p>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {this.state.starring !== null &&
                                this.state.starring.length > 0 ? (
                                  <div className="details-of-movie">
                                    <h4>Stars</h4>
                                    <p>
                                      {this.state.starring.map((item, key) => {
                                        let comma = ",";
                                        if (
                                          this.state.starring.length - 1 ===
                                          key
                                        ) {
                                          comma = "";
                                        }
                                        return `${item.cast}${comma} `;
                                      })}{" "}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div
                              className="details-subtitle"
                              id="details-subtitle"
                            >
                              {this.state.movieObject.publishDate !== null &&
                              this.state.movieObject.publishDate !== "" ? (
                                <p>
                                  {this.state.movieObject.publishDate}
                                  {/* May 21st, 1994 */}
                                </p>
                              ) : (
                                ""
                              )}

                              {this.state.movieObject.movieLength != null &&
                              this.state.movieObject.movieLength !== "" ? (
                                <p>
                                  {" "}
                                  {secondsToHourse(
                                    this.state.movieObject.movieLength
                                  )}{" "}
                                </p>
                              ) : (
                                ""
                              )}
                              <h4>Subtitles</h4>
                              {this.state.movieObject.language != null &&
                              this.state.movieObject.language !== "" ? (
                                <h4>{this.state.movieObject.language}</h4>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          {this.state.movieObject.genere != null &&
                          this.state.movieObject.genere.length > 0 ? (
                            <div className="details-contents-button">
                              {this.state.movieObject.genere.map(
                                (item, key) => {
                                  return (
                                    <button
                                      className="btn details-moive-btn"
                                      key={key}
                                    >
                                      {item.genere}
                                    </button>
                                  );
                                }
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.starring &&
                            this.state.starring.length > 0 && (
                              <div className="top-cast">
                                <p>Top Cast</p>

                                <div className="main-content">
                                  <section id="iq-favorites">
                                    <Row id="d-space-show">
                                      <Col
                                        sm="12"
                                        className="overflow-hidden d-set-overflow p-0"
                                      >
                                        {/* <div className="d-flex align-items-center justify-content-between set-view-all-text">
                                          <h3 className="main-title text-uppercase d-movie-title">Top View</h3>
                                       </div> */}
                                        <div id="favorites-contens">
                                          <div
                                            id="topViewPrev"
                                            className="swiper-button swiper-button-prev m-set-prev"
                                          >
                                            <i className="fa fa-chevron-left d-icon"></i>
                                          </div>
                                          <div
                                            id="topViewNext"
                                            className="swiper-button swiper-button-next m-set-next"
                                          >
                                            <i className="fa fa-chevron-right d-icon"></i>
                                          </div>
                                          <Swiper
                                            navigation={{
                                              prevEl: "#topViewPrev",
                                              nextEl: "#topViewNext",
                                            }}
                                            breakpoints={{
                                              2560: {
                                                slidesPerView: 11,
                                                spaceBetween: 20,
                                              },
                                              2304: {
                                                slidesPerView: 10,
                                                spaceBetween: 15,
                                              },
                                              2150: {
                                                slidesPerView: 9,
                                                spaceBetween: 18,
                                              },
                                              2050: {
                                                slidesPerView: 9,
                                                spaceBetween: 18,
                                              },
                                              1920: {
                                                slidesPerView: 10,
                                                spaceBetween: 20,
                                              },
                                              1800: {
                                                slidesPerView: 8,
                                                spaceBetween: 10,
                                              },
                                              1740: {
                                                slidesPerView: 8,
                                                spaceBetween: 10,
                                              },

                                              1680: {
                                                slidesPerView: 7,
                                                spaceBetween: 20,
                                              },

                                              1536: {
                                                slidesPerView: 7,
                                                spaceBetween: 10,
                                              },
                                              1440: {
                                                slidesPerView: 6,
                                                spaceBetween: 15,
                                              },
                                              1366: {
                                                slidesPerView: 6,
                                                spaceBetween: 10,
                                              },
                                              1280: {
                                                slidesPerView: 5,
                                                spaceBetween: 22,
                                              },
                                              1140: {
                                                slidesPerView: 5,
                                                spaceBetween: 10,
                                              },
                                              1054: {
                                                slidesPerView: 4,
                                                spaceBetween: 19,
                                              },
                                              992: {
                                                slidesPerView: 4,
                                                spaceBetween: 10,
                                              },
                                              991: {
                                                slidesPerView: 5,
                                                spaceBetween: 10,
                                              },
                                              767: {
                                                slidesPerView: 4,
                                                spaceBetween: 10,
                                              },
                                              676: {
                                                slidesPerView: 4,
                                                spaceBetween: 10,
                                              },
                                              577: {
                                                slidesPerView: 3,
                                                spaceBetween: 12,
                                              },
                                              576: {
                                                slidesPerView: 3,
                                                spaceBetween: 22,
                                              },
                                              494: {
                                                slidesPerView: 3,
                                                spaceBetween: 10,
                                              },
                                              430: {
                                                slidesPerView: 3,
                                                spaceBetween: 5,
                                              },
                                              414: {
                                                slidesPerView: 3,
                                                spaceBetween: 5,
                                              },
                                              376: {
                                                slidesPerView: 2.8,
                                                spaceBetween: 10,
                                              },
                                              375: {
                                                slidesPerView: 2.5,
                                                spaceBetween: 10,
                                              },

                                              320: {
                                                slidesPerView: 2.4,
                                                spaceBetween: 5,
                                              },
                                            }}
                                            loop={false}
                                            // slidesPerView={16}
                                            // spaceBetween={20}
                                            as="ul"
                                            className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                                          >
                                            {this.state.starring.map(
                                              (item, key) => {
                                                return (
                                                  <SwiperSlide
                                                    as="li"
                                                    id="d-slider"
                                                    key={key}
                                                  >
                                                    <div className="position-relative">
                                                      <div
                                                        className="img-box show-img-boxes"
                                                        id="show-img-boxes"
                                                      >
                                                        <img
                                                          src={
                                                            item.castPhoto !=
                                                              null &&
                                                            item.castPhoto !==
                                                              ""
                                                              ? item.castPhoto
                                                              : require("../../assets/images/user/user-profile.png")
                                                          }
                                                          className="img-fluid show-profile-img"
                                                          alt=""
                                                        />
                                                        <h4>{item.cast}</h4>
                                                        <p>
                                                          {item.characterName}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </SwiperSlide>
                                                );
                                              }
                                            )}
                                          </Swiper>
                                        </div>
                                      </Col>
                                    </Row>

                                    {/* <div className='show-cast-agency'>
                                          <button className='btn show-casting'>Join Casting Agency</button>
                                       </div> */}
                                  </section>
                                </div>

                                {/* <p>Related</p>
                                 <div className="main-content">
                                    <section id="iq-favorites">
                                       <Row id="d-space-show">
                                          <Col sm="12" className="overflow-hidden d-set-overflow p-0">
                                             <div className="d-flex align-items-center justify-content-between set-view-all-text">
                                       <h3 className="main-title text-uppercase d-movie-title">Related</h3>
                                    </div>
                                             <div id="favorites-contens">
                                                <div id="relatedPrev" className="swiper-button swiper-button-prev sm-set-prev"><i className="fa fa-chevron-left d-icon"></i></div>
                                                <div id="relatedNext" className="swiper-button swiper-button-next sm-set-next"><i className="fa fa-chevron-right d-icon"></i></div>
                                                <Swiper
                                                   navigation={{
                                                      prevEl: '#relatedPrev',
                                                      nextEl: '#relatedNext'
                                                   }}
                                                   breakpoints={{
                                                      2560: {
                                                         slidesPerView: 11,
                                                         spaceBetween: 20,
                                                      },
                                                      2304: {
                                                         slidesPerView: 10,
                                                         spaceBetween: 15,
                                                      },
                                                      2150: {
                                                         slidesPerView: 9,
                                                         spaceBetween: 18,
                                                      },
                                                      2050: {
                                                         slidesPerView: 9,
                                                         spaceBetween: 18,
                                                      },
                                                      1920: {
                                                         slidesPerView: 8,
                                                         spaceBetween: 20,
                                                      },
                                                      1800: {
                                                         slidesPerView: 8,
                                                         spaceBetween: 10,
                                                      },
                                                      1740: {
                                                         slidesPerView: 8,
                                                         spaceBetween: 10,
                                                      },

                                                      1680: {
                                                         slidesPerView: 7,
                                                         spaceBetween: 20,
                                                      },

                                                      1536: {
                                                         slidesPerView: 7,
                                                         spaceBetween: 10,
                                                      },
                                                      1440: {
                                                         slidesPerView: 6,
                                                         spaceBetween: 15,
                                                      },
                                                      1366: {
                                                         slidesPerView: 6,
                                                         spaceBetween: 10,
                                                      },
                                                      1280: {
                                                         slidesPerView: 5,
                                                         spaceBetween: 22,
                                                      },
                                                      1140: {
                                                         slidesPerView: 5,
                                                         spaceBetween: 10,
                                                      },
                                                      1054: {
                                                         slidesPerView: 4,
                                                         spaceBetween: 19,
                                                      },
                                                      992: {
                                                         slidesPerView: 4,
                                                         spaceBetween: 10,
                                                      },
                                                      991: {
                                                         slidesPerView: 5,
                                                         spaceBetween: 10,
                                                      },
                                                      767: {
                                                         slidesPerView: 4,
                                                         spaceBetween: 10,
                                                      },
                                                      676: {
                                                         slidesPerView: 4,
                                                         spaceBetween: 10,
                                                      },
                                                      577: {
                                                         slidesPerView: 3,
                                                         spaceBetween: 12,
                                                      },
                                                      576: {
                                                         slidesPerView: 3,
                                                         spaceBetween: 22,
                                                      },
                                                      494: {
                                                         slidesPerView: 3,
                                                         spaceBetween: 10,
                                                      },
                                                      430: {
                                                         slidesPerView: 3,
                                                         spaceBetween: 5,
                                                      },
                                                      414: {
                                                         slidesPerView: 2.5,
                                                         spaceBetween: 5,
                                                      },
                                                      376: {
                                                         slidesPerView: 2.5,
                                                         spaceBetween: 8,
                                                      },
                                                      375: {
                                                         slidesPerView: 2.5,
                                                         spaceBetween: 10,
                                                      },

                                                      320: {
                                                         slidesPerView: 2.4,
                                                         spaceBetween: 5,
                                                      },

                                                   }}
                                                   loop={false}
                                                   // slidesPerView={16}
                                                   // spaceBetween={20}
                                                   as="ul"
                                                   className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction">


                                                   <SwiperSlide as="li" id="d-slider">
                                                      <div className="position-relative">
                                                         <div className="img-box">
                                                            <img src="https://stage.myciright.com/Ciright/ajaxCall-photo.htm?flag=manufactLogo&compress=0&id=19133" className="img-fluid movie-veuit-img" alt="" />
                                                         </div>
                                                      </div>
                                                   </SwiperSlide>

                                                   <SwiperSlide as="li" id="d-slider">
                                                      <div className="position-relative">
                                                         <div className="img-box">
                                                            <img src="https://stage.myciright.com/Ciright/ajaxCall-photo.htm?flag=manufactLogo&compress=0&id=19133" className="img-fluid movie-veuit-img" alt="" />
                                                         </div>
                                                      </div>
                                                   </SwiperSlide>

                                                   <SwiperSlide as="li" id="d-slider">
                                                      <div className="position-relative">
                                                         <div className="img-box">
                                                            <img src="https://stage.myciright.com/Ciright/ajaxCall-photo.htm?flag=manufactLogo&compress=0&id=19133" className="img-fluid movie-veuit-img" alt="" />
                                                         </div>
                                                      </div>
                                                   </SwiperSlide>

                                                   <SwiperSlide as="li" id="d-slider">
                                                      <div className="position-relative">
                                                         <div className="img-box">
                                                            <img src="https://stage.myciright.com/Ciright/ajaxCall-photo.htm?flag=manufactLogo&compress=0&id=19133" className="img-fluid movie-veuit-img" alt="" />
                                                         </div>
                                                      </div>
                                                   </SwiperSlide>

                                                </Swiper>
                                             </div>
                                          </Col>
                                       </Row>
                                    </section>
                                 </div> */}
                              </div>
                            )}
                        </div>

                        <div
                          className={`tab-pane fade ${
                            this.state.activeTab === "pills-Extra"
                              ? "show active"
                              : ""
                          }`}
                          id="pills-Extra"
                          role="tabpanel"
                          aria-labelledby="pills-Extra-tab"
                        >
                          <div className="mobile-season">
                            <div className="movie-extra">
                              <div className="show-extra-tab">
                                <img
                                  src={require("../../assets/images/extra-tab.png")}
                                  className="show-extratab-img"
                                  alt="images"
                                ></img>
                              </div>
                              <div className="show-movie-tab">
                                <h4>Season 1 Trailer: Title For Episode</h4>
                                <span className="show-time-details-mobile">
                                  <span className="show-minute-mobile">
                                    63min
                                  </span>
                                  <span className="show-date-mobile">
                                    06/10/22
                                  </span>
                                  <span className="show-subtitle-mobile">
                                    Subtitles
                                  </span>
                                </span>
                                <p className="show-movie-description">
                                  “The Count” is a classic retelling of “The
                                  Count of Monte Cristo” set against the
                                  backdrop of Las Vegas, Macau, South America,
                                  and a global gaming empire.
                                </p>
                              </div>
                              <div className="show-subtitle">
                                <p>June 10th, 2022</p>
                                <p>63min</p>
                                <h4>Subtitles</h4>
                                <h4>Audio Languages</h4>
                              </div>
                            </div>
                            <p className="show-season-description">
                              “The Count” is a classic retelling of “The Count
                              of Monte Cristo” set against the backdrop of Las
                              Vegas, Macau, South America, and a global gaming
                              empire.
                            </p>
                          </div>
                          <div className="mobile-season">
                            <div className="movie-extra">
                              <div className="show-extra-tab">
                                <img
                                  src={require("../../assets/images/extra-tab.png")}
                                  className="show-extratab-img"
                                  alt="images"
                                ></img>
                              </div>
                              <div className="show-movie-tab">
                                <h4>BTS: Title For Episode</h4>
                                <span className="show-time-details-mobile">
                                  <span className="show-minute-mobile">
                                    63min
                                  </span>
                                  <span className="show-date-mobile">
                                    06/10/22
                                  </span>
                                  <span className="show-subtitle-mobile">
                                    Subtitles
                                  </span>
                                </span>
                                <p className="show-movie-description">
                                  “The Count” is a classic retelling of “The
                                  Count of Monte Cristo” set against the
                                  backdrop of Las Vegas, Macau, South America,
                                  and a global gaming empire.
                                </p>
                              </div>
                              <div className="show-subtitle">
                                <p>June 10th, 2022</p>
                                <p>63min</p>
                                <h4>Subtitles</h4>
                                <h4>Audio Languages</h4>
                              </div>
                            </div>
                            <p className="show-season-description">
                              “The Count” is a classic retelling of “The Count
                              of Monte Cristo” set against the backdrop of Las
                              Vegas, Macau, South America, and a global gaming
                              empire.
                            </p>
                          </div>
                          <div className="mobile-season">
                            <div className="movie-extra">
                              <div className="show-extra-tab">
                                <img
                                  src={require("../../assets/images/extra-tab.png")}
                                  className="show-extratab-img"
                                  alt="images"
                                ></img>
                              </div>
                              <div className="show-movie-tab">
                                <h4>BTS: Title For Episode</h4>
                                <span className="show-time-details-mobile">
                                  <span className="show-minute-mobile">
                                    63min
                                  </span>
                                  <span className="show-date-mobile">
                                    06/10/22
                                  </span>
                                  <span className="show-subtitle-mobile">
                                    Subtitles
                                  </span>
                                </span>
                                <p className="show-movie-description">
                                  “The Count” is a classic retelling of “The
                                  Count of Monte Cristo” set against the
                                  backdrop of Las Vegas, Macau, South America,
                                  and a global gaming empire.
                                </p>
                              </div>
                              <div className="show-subtitle">
                                <p>June 10th, 2022</p>
                                <p>63min</p>
                                <h4>Subtitles</h4>
                                <h4>Audio Languages</h4>
                              </div>
                            </div>
                            <p className="show-season-description">
                              “The Count” is a classic retelling of “The Count
                              of Monte Cristo” set against the backdrop of Las
                              Vegas, Macau, South America, and a global gaming
                              empire.
                            </p>
                          </div>
                          <p className="extra-photo">Photos</p>
                          <div className="extra-photo-sec">
                            <div className="main-content extra-tabs-set">
                              <section id="iq-favorites">
                                <Row id="d-space-show">
                                  <Col
                                    sm="12"
                                    className="overflow-hidden d-set-overflow p-0"
                                  >
                                    {/* <div className="d-flex align-items-center justify-content-between set-view-all-text">
                                       <h3 className="main-title text-uppercase d-movie-title">Ralated</h3>
                                    </div> */}
                                    <div id="favorites-contens">
                                      <div
                                        id="photosPrev"
                                        className="swiper-button swiper-button-prev p-set-prev"
                                      >
                                        <i className="fa fa-chevron-left d-icon"></i>
                                      </div>
                                      <div
                                        id="photosNext"
                                        className="swiper-button swiper-button-next p-set-next"
                                      >
                                        <i className="fa fa-chevron-right d-icon"></i>
                                      </div>
                                      <Swiper
                                        observer={true}
                                        observeParents={true}
                                        navigation={{
                                          prevEl: "#photosPrev",
                                          nextEl: "#photosNext",
                                        }}
                                        breakpoints={{
                                          2560: {
                                            slidesPerView: 9,
                                            spaceBetween: 20,
                                          },
                                          2304: {
                                            slidesPerView: 8,
                                            spaceBetween: 15,
                                          },
                                          // 2150: {
                                          //    slidesPerView: 9,
                                          //    spaceBetween: 18,
                                          // },
                                          2050: {
                                            slidesPerView: 8,
                                            spaceBetween: 18,
                                          },
                                          1920: {
                                            slidesPerView: 7,
                                            spaceBetween: 25,
                                            // slidesPerGroup:7,
                                          },
                                          // 1800: {
                                          //    slidesPerView: 6,
                                          //    spaceBetween: 10,
                                          // },
                                          // 1740: {
                                          //    slidesPerView: 6,
                                          //    spaceBetween: 10,
                                          // },

                                          1680: {
                                            slidesPerView: 6,
                                            spaceBetween: 10,
                                          },

                                          // 1536: {
                                          //    slidesPerView: 6,
                                          //    spaceBetween: 10,
                                          // },
                                          // 1440: {
                                          //    slidesPerView: 6,
                                          //    spaceBetween: 15,
                                          // },
                                          1360: {
                                            slidesPerView: 5,
                                            spaceBetween: 10,
                                          },
                                          // 1280: {
                                          //    slidesPerView: 5,
                                          //    spaceBetween: 22,
                                          // },
                                          // 1140: {
                                          //    slidesPerView: 5,
                                          //    spaceBetween: 10,
                                          // },
                                          // 1054: {
                                          //    slidesPerView: 4,
                                          //    spaceBetween: 10,
                                          // },
                                          992: {
                                            slidesPerView: 4,
                                            spaceBetween: 10,
                                          },
                                          // 991: {
                                          //    slidesPerView: 5,
                                          //    spaceBetween: 10,
                                          // },
                                          768: {
                                            slidesPerView: 3,
                                            spaceBetween: 10,
                                          },
                                          // 676: {
                                          //    slidesPerView: 4,
                                          //    spaceBetween: 10,
                                          // },
                                          // 577: {
                                          //    slidesPerView: 3,
                                          //    spaceBetween: 12,
                                          // },
                                          576: {
                                            slidesPerView: 4,
                                            spaceBetween: 10,
                                          },
                                          481: {
                                            slidesPerView: 3,
                                            spaceBetween: 10,
                                          },
                                          // 430: {
                                          //    slidesPerView: 3,
                                          //    spaceBetween: 5,
                                          // },
                                          // 414: {
                                          //    slidesPerView: 3,
                                          //    spaceBetween: 5,
                                          // },
                                          // 376: {
                                          //    slidesPerView: 2.8,
                                          //    spaceBetween: 10,
                                          // },
                                          375: {
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                          },

                                          320: {
                                            slidesPerView: 1.5,
                                            spaceBetween: 5,
                                          },
                                        }}
                                        loop={false}
                                        // slidesPerView={1}
                                        // spaceBetween={20}

                                        as="ul"
                                        className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                                      >
                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src={require("../../assets/images/photos.png")}
                                                className="img-fluid photos-extra-img"
                                                alt="images"
                                              ></img>
                                            </div>
                                          </div>
                                        </SwiperSlide>

                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src={require("../../assets/images/IMAGE3.png")}
                                                className="img-fluid photos-extra-img"
                                                alt="images"
                                              ></img>
                                            </div>
                                          </div>
                                        </SwiperSlide>

                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src={require("../../assets/images/IMAGE3.png")}
                                                className="img-fluid photos-extra-img"
                                                alt="images"
                                              ></img>
                                            </div>
                                          </div>
                                        </SwiperSlide>

                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src={require("../../assets/images/IMAGE4.png")}
                                                className="img-fluid photos-extra-img"
                                                alt="images"
                                              ></img>
                                            </div>
                                          </div>
                                        </SwiperSlide>

                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src={require("../../assets/images/photos.png")}
                                                className="img-fluid photos-extra-img"
                                                alt="images"
                                              ></img>
                                            </div>
                                          </div>
                                        </SwiperSlide>

                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src={require("../../assets/images/IMAGE3.png")}
                                                className="img-fluid photos-extra-img"
                                                alt="images"
                                              ></img>
                                            </div>
                                          </div>
                                        </SwiperSlide>

                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src={require("../../assets/images/IMAGE3.png")}
                                                className="img-fluid photos-extra-img"
                                                alt="images"
                                              ></img>
                                            </div>
                                          </div>
                                        </SwiperSlide>

                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src={require("../../assets/images/extra-tab.png")}
                                                className="show-extratab-img"
                                                alt="images"
                                              ></img>
                                            </div>
                                          </div>
                                        </SwiperSlide>

                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src={require("../../assets/images/extra-tab.png")}
                                                className="show-extratab-img"
                                                alt="images"
                                              ></img>
                                            </div>
                                          </div>
                                        </SwiperSlide>

                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src={require("../../assets/images/extra-tab.png")}
                                                className="show-extratab-img"
                                                alt="images"
                                              ></img>
                                            </div>
                                          </div>
                                        </SwiperSlide>

                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src={require("../../assets/images/extra-tab.png")}
                                                className="show-extratab-img"
                                                alt="images"
                                              ></img>
                                            </div>
                                          </div>
                                        </SwiperSlide>

                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src={require("../../assets/images/extra-tab.png")}
                                                className="show-extratab-img"
                                                alt="images"
                                              ></img>
                                            </div>
                                          </div>
                                        </SwiperSlide>

                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src={require("../../assets/images/extra-tab.png")}
                                                className="show-extratab-img"
                                                alt="images"
                                              ></img>
                                            </div>
                                          </div>
                                        </SwiperSlide>

                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src={require("../../assets/images/extra-tab.png")}
                                                className="show-extratab-img"
                                                alt="images"
                                              ></img>
                                            </div>
                                          </div>
                                        </SwiperSlide>
                                      </Swiper>
                                    </div>
                                  </Col>
                                </Row>
                              </section>
                            </div>
                            <div className="remaining-photo">
                              <p>
                                View 462 <br></br>
                                more Photos
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`tab-pane fade ${
                            this.state.activeTab === "pills-Related"
                              ? "show active"
                              : ""
                          }`}
                          id="pills-Related"
                          role="tabpanel"
                          aria-labelledby="pills-Related-tab"
                        >
                          <div className="movies-content">
                            <p>Movies</p>
                            <div className="main-content">
                              <section id="iq-favorites">
                                <Row id="d-space-show">
                                  <Col
                                    sm="12"
                                    className="overflow-hidden d-set-overflow p-0"
                                  >
                                    {/* <div className="d-flex align-items-center justify-content-between set-view-all-text">
                                       <h3 className="main-title text-uppercase d-movie-title">Ralated</h3>
                                    </div> */}
                                    <div id="favorites-contens">
                                      <div
                                        id="moviePrev"
                                        className="swiper-button swiper-button-prev sm-set-prev"
                                      >
                                        <i className="fa fa-chevron-left d-icon"></i>
                                      </div>
                                      <div
                                        id="movieNext"
                                        className="swiper-button swiper-button-next sm-set-next"
                                      >
                                        <i className="fa fa-chevron-right d-icon"></i>
                                      </div>
                                      <Swiper
                                        observer={true}
                                        observeParents={true}
                                        navigation={{
                                          prevEl: "#moviePrev",
                                          nextEl: "#movieNext",
                                        }}
                                        breakpoints={{
                                          2560: {
                                            slidesPerView: 11,
                                            spaceBetween: 20,
                                          },
                                          2304: {
                                            slidesPerView: 10,
                                            spaceBetween: 15,
                                          },
                                          2150: {
                                            slidesPerView: 9,
                                            spaceBetween: 18,
                                          },
                                          2050: {
                                            slidesPerView: 9,
                                            spaceBetween: 18,
                                          },
                                          1920: {
                                            slidesPerView: 8,
                                            spaceBetween: 20,
                                          },
                                          1800: {
                                            slidesPerView: 8,
                                            spaceBetween: 10,
                                          },
                                          1740: {
                                            slidesPerView: 8,
                                            spaceBetween: 10,
                                          },

                                          1680: {
                                            slidesPerView: 7,
                                            spaceBetween: 20,
                                          },

                                          1536: {
                                            slidesPerView: 7,
                                            spaceBetween: 10,
                                          },
                                          1440: {
                                            slidesPerView: 6,
                                            spaceBetween: 15,
                                          },
                                          1366: {
                                            slidesPerView: 6,
                                            spaceBetween: 10,
                                          },
                                          1280: {
                                            slidesPerView: 5,
                                            spaceBetween: 22,
                                          },
                                          1140: {
                                            slidesPerView: 5,
                                            spaceBetween: 10,
                                          },
                                          1054: {
                                            slidesPerView: 4,
                                            spaceBetween: 19,
                                          },
                                          992: {
                                            slidesPerView: 4,
                                            spaceBetween: 10,
                                          },
                                          991: {
                                            slidesPerView: 5,
                                            spaceBetween: 10,
                                          },
                                          768: {
                                            slidesPerView: 3.7,
                                            spaceBetween: 10,
                                          },
                                          676: {
                                            slidesPerView: 4.5,
                                            spaceBetween: 10,
                                          },
                                          577: {
                                            slidesPerView: 3.5,
                                            spaceBetween: 12,
                                          },
                                          576: {
                                            slidesPerView: 3,
                                            spaceBetween: 22,
                                          },
                                          494: {
                                            slidesPerView: 3,
                                            spaceBetween: 10,
                                          },
                                          430: {
                                            slidesPerView: 3,
                                            spaceBetween: 5,
                                          },
                                          414: {
                                            slidesPerView: 3,
                                            spaceBetween: 5,
                                          },
                                          376: {
                                            slidesPerView: 2.8,
                                            spaceBetween: 10,
                                          },
                                          375: {
                                            slidesPerView: 2.5,
                                            spaceBetween: 10,
                                          },

                                          320: {
                                            slidesPerView: 2.2,
                                            spaceBetween: 5,
                                          },
                                        }}
                                        loop={false}
                                        // slidesPerView={16}
                                        // spaceBetween={20}
                                        as="ul"
                                        className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                                      >
                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src="https://stage.myciright.com/Ciright/ajaxCall-photo.htm?flag=manufactLogo&compress=0&id=19133"
                                                className="img-fluid movie-veuit-img"
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </SwiperSlide>

                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src="https://stage.myciright.com/Ciright/ajaxCall-photo.htm?flag=manufactLogo&compress=0&id=19133"
                                                className="img-fluid movie-veuit-img"
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </SwiperSlide>

                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src="https://stage.myciright.com/Ciright/ajaxCall-photo.htm?flag=manufactLogo&compress=0&id=19133"
                                                className="img-fluid movie-veuit-img"
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </SwiperSlide>

                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src="https://stage.myciright.com/Ciright/ajaxCall-photo.htm?flag=manufactLogo&compress=0&id=19133"
                                                className="img-fluid movie-veuit-img"
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </SwiperSlide>
                                      </Swiper>
                                    </div>
                                  </Col>
                                </Row>
                              </section>
                            </div>
                          </div>

                          <div className="movies-content">
                            <p>TV Shows</p>
                            <div className="main-content">
                              <section id="iq-favorites">
                                <Row id="d-space-show">
                                  <Col
                                    sm="12"
                                    className="overflow-hidden d-set-overflow p-0"
                                  >
                                    {/* <div className="d-flex align-items-center justify-content-between set-view-all-text">
                                       <h3 className="main-title text-uppercase d-movie-title">Ralated</h3>
                                    </div> */}
                                    <div id="favorites-contens">
                                      <div
                                        id="tvshowsPrev"
                                        className="swiper-button swiper-button-prev sm-set-prev"
                                      >
                                        <i className="fa fa-chevron-left d-icon"></i>
                                      </div>
                                      <div
                                        id="tvshowsNext"
                                        className="swiper-button swiper-button-next sm-set-next"
                                      >
                                        <i className="fa fa-chevron-right d-icon"></i>
                                      </div>
                                      <Swiper
                                        observer={true}
                                        observeParents={true}
                                        navigation={{
                                          prevEl: "#tvshowsPrev",
                                          nextEl: "#tvshowsNext",
                                        }}
                                        breakpoints={{
                                          2560: {
                                            slidesPerView: 11,
                                            spaceBetween: 20,
                                          },
                                          2304: {
                                            slidesPerView: 10,
                                            spaceBetween: 15,
                                          },
                                          2150: {
                                            slidesPerView: 9,
                                            spaceBetween: 18,
                                          },
                                          2050: {
                                            slidesPerView: 9,
                                            spaceBetween: 18,
                                          },
                                          1920: {
                                            slidesPerView: 8,
                                            spaceBetween: 20,
                                          },
                                          1800: {
                                            slidesPerView: 8,
                                            spaceBetween: 10,
                                          },
                                          1740: {
                                            slidesPerView: 8,
                                            spaceBetween: 10,
                                          },

                                          1680: {
                                            slidesPerView: 7,
                                            spaceBetween: 20,
                                          },

                                          1536: {
                                            slidesPerView: 7,
                                            spaceBetween: 10,
                                          },
                                          1440: {
                                            slidesPerView: 6,
                                            spaceBetween: 15,
                                          },
                                          1366: {
                                            slidesPerView: 6,
                                            spaceBetween: 10,
                                          },
                                          1280: {
                                            slidesPerView: 5,
                                            spaceBetween: 22,
                                          },
                                          1140: {
                                            slidesPerView: 5,
                                            spaceBetween: 10,
                                          },
                                          1054: {
                                            slidesPerView: 4,
                                            spaceBetween: 19,
                                          },
                                          992: {
                                            slidesPerView: 4,
                                            spaceBetween: 10,
                                          },
                                          991: {
                                            slidesPerView: 5,
                                            spaceBetween: 10,
                                          },
                                          768: {
                                            slidesPerView: 3.7,
                                            spaceBetween: 10,
                                          },
                                          676: {
                                            slidesPerView: 4.5,
                                            spaceBetween: 10,
                                          },
                                          577: {
                                            slidesPerView: 3.5,
                                            spaceBetween: 12,
                                          },
                                          576: {
                                            slidesPerView: 3,
                                            spaceBetween: 22,
                                          },
                                          494: {
                                            slidesPerView: 3,
                                            spaceBetween: 10,
                                          },
                                          430: {
                                            slidesPerView: 3,
                                            spaceBetween: 5,
                                          },
                                          414: {
                                            slidesPerView: 3,
                                            spaceBetween: 5,
                                          },
                                          376: {
                                            slidesPerView: 2.8,
                                            spaceBetween: 10,
                                          },
                                          375: {
                                            slidesPerView: 2.5,
                                            spaceBetween: 10,
                                          },

                                          320: {
                                            slidesPerView: 2.2,
                                            spaceBetween: 5,
                                          },
                                        }}
                                        loop={false}
                                        // slidesPerView={16}
                                        // spaceBetween={20}
                                        as="ul"
                                        className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction"
                                      >
                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src="https://stage.myciright.com/Ciright/ajaxCall-photo.htm?flag=manufactLogo&compress=0&id=19133"
                                                className="img-fluid movie-veuit-img"
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </SwiperSlide>

                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src="https://stage.myciright.com/Ciright/ajaxCall-photo.htm?flag=manufactLogo&compress=0&id=19133"
                                                className="img-fluid movie-veuit-img"
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </SwiperSlide>

                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src="https://stage.myciright.com/Ciright/ajaxCall-photo.htm?flag=manufactLogo&compress=0&id=19133"
                                                className="img-fluid movie-veuit-img"
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </SwiperSlide>

                                        <SwiperSlide as="li" id="d-slider">
                                          <div className="position-relative">
                                            <div className="img-box">
                                              <img
                                                src="https://stage.myciright.com/Ciright/ajaxCall-photo.htm?flag=manufactLogo&compress=0&id=19133"
                                                className="img-fluid movie-veuit-img"
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </SwiperSlide>
                                      </Swiper>
                                    </div>
                                  </Col>
                                </Row>
                              </section>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`tab-pane fade ${
                            this.state.activeTab === "pills-Reviews"
                              ? "show active"
                              : ""
                          } show-home-tabs`}
                          id="pills-Reviews"
                          role="tabpanel"
                          aria-labelledby="pills-Reviews-tab"
                        >
                          <div className="ratings-review">
                            <div className="show-ratings-review">
                              <div className="show-count-data">
                                <Rating ratingCount={10} />
                                <p>22516 global ratings</p>
                              </div>
                              <div className="rating-progressbar">
                                <p>5 star</p>
                                <div className="progress set-progress-bg"></div>
                                <span>87%</span>
                              </div>
                              <div className="rating-progressbar">
                                <p>4 star</p>
                                <div className="progress set-progress-bg"></div>
                                <span>8%</span>
                              </div>
                              <div className="rating-progressbar">
                                <p>3 star</p>
                                <div className="progress set-progress-bg"></div>
                                <span>3%</span>
                              </div>
                              <div className="rating-progressbar">
                                <p>2 star</p>
                                <div className="progress set-progress-bg"></div>
                                <span>1%</span>
                              </div>
                              <div className="rating-progressbar">
                                <p>1 star</p>
                                <div className="progress set-progress-bg"></div>
                                <span>2%</span>
                              </div>
                              <div className="our-ratings">
                                <Accordion className="set-accordion-ratings">
                                  <AccordionSummary
                                    className="review-rating-calc"
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                  >
                                    <p>How are our ratings calculated?</p>
                                  </AccordionSummary>
                                  <AccordionDetails className="review-rating-calc1">
                                    <p>Lorem ipsum</p>
                                  </AccordionDetails>
                                </Accordion>
                              </div>
                              <div className="review-btn">
                                <button className="btn write-review-btn">
                                  Write a Review
                                </button>
                              </div>
                            </div>

                            <div className="review-description">
                              <div className="filter-review">
                                <p>Sorted by</p>
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <div className="top-reviews">
                                      <h6>Top reviews</h6>
                                      <img
                                        src={require("../../assets/images/topreview-vector.png")}
                                        className="topreview-vector"
                                        alt="images"
                                      ></img>
                                    </div>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">
                                      Action
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                      Another action
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                      Something else
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <div className="sorted-top-review">
                                <p>
                                  Sorted by top reviews from the United States
                                </p>
                              </div>
                              <div className="review-tab-description">
                                <div className="view-tab">
                                  <img
                                    src={require("../../assets/images/Ellipse 83.png")}
                                    className="review-tab-img"
                                    alt="images"
                                  ></img>
                                  <span>@nathanmedia</span>
                                  <p>
                                    Reviewed in the United States on January 12,
                                    2021
                                  </p>
                                </div>
                                <div className="verified-ratings">
                                  <Rating ratingCount={8} />
                                  <p>
                                    This show is awesome! The editing needs work
                                    though..
                                  </p>
                                </div>
                                <div className="review-purchase">
                                  <h5>Verified Purchase</h5>
                                  <p>
                                    “The Count” is a classic retelling of “The
                                    Count of Monte Cristo” set against the
                                    backdrop of Las Vegas, Macau, South America,
                                    and a global gaming empire.“The Count” is a
                                    classic retelling of “The Count of Monte
                                    Cristo” set against the backdrop of Las
                                    Vegas, Macau, South America, and a global
                                    gaming empire.“The Count” is a classic
                                    retelling of “The Count of Monte ...
                                  </p>
                                  <h6>40 people liked this comment</h6>
                                </div>
                                <div className="review-report">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={<FavoriteBorder />}
                                        checkedIcon={<Favorite />}
                                        name="checkedH"
                                      />
                                    }
                                    className="review-blue-img"
                                  />
                                  <p>Report Abuse</p>
                                </div>
                              </div>
                              <div className="review-tab-description">
                                <div className="view-tab">
                                  <img
                                    src={require("../../assets/images/Ellipse 83.png")}
                                    className="review-tab-img"
                                    alt="images"
                                  ></img>
                                  <span>@nathanmedia</span>
                                  <p>
                                    Reviewed in the United States on January 12,
                                    2021
                                  </p>
                                </div>
                                <div className="verified-ratings">
                                  <Rating ratingCount={8} />
                                  <p>
                                    This show is awesome! The editing needs work
                                    though..
                                  </p>
                                </div>
                                <div className="review-purchase">
                                  <h5>Verified Purchase</h5>
                                  <p>
                                    “The Count” is a classic retelling of “The
                                    Count of Monte Cristo” set against the
                                    backdrop of Las Vegas, Macau, South America,
                                    and a global gaming empire.“The Count” is a
                                    classic retelling of “The Count of Monte
                                    Cristo” set against the backdrop of Las
                                    Vegas, Macau, South America, and a global
                                    gaming empire.“The Count” is a classic
                                    retelling of “The Count of Monte ...
                                  </p>
                                  <h6>40 people liked this comment</h6>
                                </div>
                                <div className="review-report">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={<FavoriteBorder />}
                                        checkedIcon={<Favorite />}
                                        name="checkedH"
                                      />
                                    }
                                    className="review-blue-img"
                                  />
                                  <p>Report Abuse</p>
                                </div>
                              </div>
                              <div className="review-tab-description">
                                <div className="view-tab">
                                  <img
                                    src={require("../../assets/images/Ellipse 83.png")}
                                    className="review-tab-img"
                                    alt="images"
                                  ></img>
                                  <span>@nathanmedia</span>
                                  <p>
                                    Reviewed in the United States on January 12,
                                    2021
                                  </p>
                                </div>
                                <div className="verified-ratings">
                                  <Rating ratingCount={8} />
                                  <p>
                                    This show is awesome! The editing needs work
                                    though..
                                  </p>
                                </div>
                                <div className="review-purchase">
                                  <h5>Verified Purchase</h5>
                                  <p>
                                    “The Count” is a classic retelling of “The
                                    Count of Monte Cristo” set against the
                                    backdrop of Las Vegas, Macau, South America,
                                    and a global gaming empire.“The Count” is a
                                    classic retelling of “The Count of Monte
                                    Cristo” set against the backdrop of Las
                                    Vegas, Macau, South America, and a global
                                    gaming empire.“The Count” is a classic
                                    retelling of “The Count of Monte ...
                                  </p>
                                  <h6>40 people liked this comment</h6>
                                </div>
                                <div className="review-report">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={<FavoriteBorder />}
                                        checkedIcon={<Favorite />}
                                        name="checkedH"
                                      />
                                    }
                                    className="review-blue-img"
                                  />
                                  <p>Report Abuse</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`tab-pane fade ${
                            this.state.activeTab === "pills-Comments"
                              ? "show active"
                              : ""
                          } show-home-tabs`}
                          id="pills-Comments"
                          role="tabpanel"
                          aria-labelledby="pills-Comments-tab"
                        >
                          <div className="show-comments">
                            <div className="comment-review-left">
                              <div className="comments-show">
                                <div className="comment-sections">
                                  <div className="filter-comment">
                                    <p>Sorted by</p>
                                    <Dropdown>
                                      <Dropdown.Toggle id="dropdown-basic">
                                        <div className="top-comment">
                                          <h6>Top comment</h6>
                                          <img
                                            src={require("../../assets/images/topreview-vector.png")}
                                            className="topreview-vector"
                                            alt="images"
                                          ></img>
                                        </div>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">
                                          Action
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">
                                          Another action
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">
                                          Something else
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                  <div className="sorted-top-comment">
                                    <p>
                                      Sorted by top reviews from the United
                                      States
                                    </p>
                                  </div>
                                </div>

                                <div className="comment-title1">
                                  <textarea
                                    type="text"
                                    placeholder="What did you think of this title?"
                                    className="set-comment-title"
                                  ></textarea>
                                  <img
                                    src={require("../../assets/images/comment-vector.png")}
                                    className="comment-vector-img"
                                    alt="images"
                                  ></img>
                                </div>
                                <div className="create-comment1">
                                  <p>Create New Comment</p>
                                </div>
                                <div className="set-comment-btn">
                                  <button className="btn comment-button-visit">
                                    VISIT COMMUNITY
                                  </button>
                                </div>
                              </div>

                              <div className="create-comment">
                                <p>Create New Comment</p>
                              </div>
                              <div className="comment-title">
                                <textarea
                                  type="text"
                                  placeholder="What did you think of this title?"
                                  className="set-comment-title"
                                ></textarea>
                                <img
                                  src={require("../../assets/images/comment-vector.png")}
                                  className="comment-vector-img"
                                  alt="images"
                                ></img>
                              </div>
                              <div className="show-comment-sec">
                                <div className="view-tab1">
                                  <img
                                    src={require("../../assets/images/Ellipse 83.png")}
                                    className="review-tab-img"
                                    alt="images"
                                  ></img>
                                  <span>@nathanmedia</span>
                                  <p>2 hrs</p>
                                </div>
                                <div className="review-purchase1">
                                  <p>
                                    “The Count” is a classic retelling of “The
                                    Count of Monte Cristo” set against the
                                    backdrop of Las Vegas, Macau, South America,
                                    and a global gaming empire.“The Count” is a
                                    classic retelling of “The Count of Monte
                                    Cristo” set against the backdrop of Las
                                    Vegas, Macau, South America, and a global
                                    gaming empire.“The Count” is a classic
                                    retelling of “The Count of Monte ...
                                  </p>
                                  <h6>40 people liked this comment</h6>
                                </div>
                                <div className="review-report1">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={<FavoriteBorder />}
                                        checkedIcon={<Favorite />}
                                        name="checkedH"
                                      />
                                    }
                                    className="review-like-img"
                                  />
                                  {/* <img src={require("../../assets/images/grey-vector.png")} className="review-like-img" alt="images" ></img> */}
                                  <img
                                    src={require("../../assets/images/grey-comment.png")}
                                    className="review-comment-img"
                                    alt="images"
                                  ></img>
                                  <h6>32</h6>
                                  <img
                                    src={require("../../assets/images/Share-comment.png")}
                                    className="review-share-img"
                                    alt="images"
                                  ></img>
                                  <p>Report Abuse</p>
                                </div>
                              </div>

                              <div className="show-comment-sec">
                                <div className="view-tab1">
                                  <img
                                    src={require("../../assets/images/Ellipse 83.png")}
                                    className="review-tab-img"
                                    alt="images"
                                  ></img>
                                  <span>@nathanmedia</span>
                                  <p>2 hrs</p>
                                </div>
                                <div className="review-purchase1">
                                  <p>
                                    “The Count” is a classic retelling of “The
                                    Count of Monte Cristo” set against the
                                    backdrop of Las Vegas, Macau, South America,
                                    and a global gaming empire.“The Count” is a
                                    classic retelling of “The Count of Monte
                                    Cristo” set against the backdrop of Las
                                    Vegas, Macau, South America, and a global
                                    gaming empire.“The Count” is a classic
                                    retelling of “The Count of Monte ...
                                  </p>
                                  <h6>40 people liked this comment</h6>
                                </div>
                                <div
                                  className="review-report1"
                                  id="set-comment-border"
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={<FavoriteBorder />}
                                        checkedIcon={<Favorite />}
                                        name="checkedH"
                                      />
                                    }
                                    className="review-like-img"
                                  />
                                  <img
                                    src={require("../../assets/images/blue-cooment.png")}
                                    className="review-comment-img"
                                    alt="images"
                                  ></img>
                                  <h6>2</h6>
                                  <img
                                    src={require("../../assets/images/Share-comment.png")}
                                    className="review-share-img"
                                    alt="images"
                                  ></img>
                                  <p>Report Abuse</p>
                                </div>
                                <div className="comment-new-sec">
                                  <div className="comment-sec-new">
                                    <div className="view-tab1">
                                      <img
                                        src={require("../../assets/images/Ellipse 83.png")}
                                        className="review-tab-img"
                                        alt="images"
                                      ></img>
                                      <span>@kbensonmedia</span>
                                      <p>1 hr</p>
                                    </div>
                                    <div className="comment-para">
                                      <p>
                                        “The Count” is a classic retelling of
                                        “The Count of Monte Cristo” set against
                                        the backdrop of Las Vegas, Macau, South
                                        America, and a global gaming empire.“The
                                        Count” is a classic retelling of “The
                                        Count of Monte Cristo” set against the
                                        backdrop of Las Vegas, Macau, South{" "}
                                      </p>
                                      <div className="like-comment-icon">
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              icon={<FavoriteBorder />}
                                              checkedIcon={<Favorite />}
                                              name="checkedH"
                                            />
                                          }
                                          className="review-like-img"
                                        />
                                        <img
                                          src={require("../../assets/images/grey-comment.png")}
                                          className="review-comment-img"
                                          alt="images"
                                        ></img>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="comment-sec-new">
                                    <div className="view-tab1" id="view-tab1">
                                      <img
                                        src={require("../../assets/images/Ellipse 83.png")}
                                        className="review-tab-img"
                                        alt="images"
                                      ></img>
                                      <span>@kbensonmedia</span>
                                      <p>1 hr</p>
                                    </div>
                                    <div
                                      className="comment-para"
                                      id="comment-para"
                                    >
                                      <p>
                                        “The Count” is a classic retelling of
                                        “The Count of Monte Cristo” set against
                                        the backdrop of Las Vegas{" "}
                                      </p>
                                      <div className="like-comment-icon">
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              icon={<FavoriteBorder />}
                                              checkedIcon={<Favorite />}
                                              name="checkedH"
                                            />
                                          }
                                          className="review-like-img"
                                        />
                                        <img
                                          src={require("../../assets/images/grey-comment.png")}
                                          className="review-comment-img"
                                          alt="images"
                                        ></img>
                                      </div>
                                    </div>
                                    <div className="comment-reply-sec">
                                      <img
                                        src={require("../../assets/images/Ellipse 83.png")}
                                        className="review-tab-img"
                                        alt="images"
                                      ></img>
                                      <textarea
                                        type="text"
                                        placeholder="Write a reply"
                                        className="set-reply-title"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="comment-review-right">
                              <div className="comment-people">
                                <p>Comments by People You Follow</p>
                              </div>
                              <div className="comment-scrolbar">
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                                <div className="set-follow-info">
                                  <img
                                    src={require("../../assets/images/Ellipse (1).png")}
                                    className="comments-img"
                                    alt="images"
                                  ></img>
                                  <h4>@nathanmedia</h4>
                                  <p>2hrs ago</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* 
                           <div className={`tab-pane fade ${this.state.activeTab === 'pills-CinemaStore' ? 'show active' : ''} show-home-tabs`} id="pills-CinemaStore" role="tabpanel" aria-labelledby="pills-CinemaStore-tab">
                           </div> */}
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MovieDetailsNew);
